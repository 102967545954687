// import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import TabControlNavItem from "../../../Components/Common/TabControlNavItem";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function SettingsTabControl({ activeTabSetter, activeTab }) {
  const history = useNavigate();
  const params = useParams();
  const site_id = params.id;
  function clickHandler(navObject) {
    console.log(navObject);
    if (navObject.displayText == "General") {
      history(`/editsite/general/${site_id}`);
    } else if (navObject.displayText == "Zone") {
      history(`/editsite/zone/${site_id}`);
    }
    activeTabSetter(navObject);
  }

  return (
    <Card>
      <CardBody>
        <Nav pills vertical>
          {/* {console.log(Object.keys(SettingsTabPages), "helo")} */}
          {Object.keys(SettingsTabPages).map((tabKey) => (
            <TabControlNavItem
              navObject={SettingsTabPages[tabKey]}
              onClickCallback={clickHandler}
              key={tabKey.id}
              activeTab={activeTab}
            />
          ))}
        </Nav>
      </CardBody>
    </Card>
  );
}
