import { Card, CardBody, Col, Row, Form, CardTitle, Button } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Input,
  Table,
} from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from 'react-router-dom';
//PagePersonalisation side tab
import SettingsTabControl from '../TabComponents/SettingsTabControl';
import SettingsTabContent from '../TabComponents/SettingsTabContent';
import { SettingsTabPages } from '../Constants/SettingsConstants';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from "../../../Components/Common/Loader";


const PersonalisationDetail = (props) => {
  const { id } = useParams();
  const [pagePer, setPagePer] = useState([]);
  const [pageColumns, setPageColumns] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const loadPagePersonalisation = () => {
    setisLoading(true);
    axios.get(`/api/pagepersonalisation/create/${id}`)
      .then(response => {
        // Handle the response
        setPagePer(response.pages);
        setPageColumns(response.columns);
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  useEffect(() => {
    // Use the id in your Axios request
    loadPagePersonalisation();
  }, []);

  const defaultToggle = (event, id, default_display) => {
    event.preventDefault();
    var formData = {
      "default_display": default_display,
      "id": id
    }
    axios.post(`/api/moving-settings/page-personalisation/updateDefaultStatus`, formData)
      .then(response => {
        // Handle the response
        loadPagePersonalisation();
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  }

  const tenantToggle = (event, id) => {
    event.preventDefault();
    var formData = {
      "column_id": id
    }
    axios.post(`/api/moving-settings/page-personalisation/updateTenantStatus`, formData)
      .then(response => {
        // Handle the response
        loadPagePersonalisation();
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });

  }
//PagePersonalisation side Bar code
  const [activeTab, setActiveTab] = useState(SettingsTabPages.PagePersonalisation);
  const params = useParams();
  const tab = params.activeTab;
  useEffect(() => {
     if(tab == 'pagepersonalisation'){
      setActiveTab(SettingsTabPages.PagePersonalisation);
    }
  }, [tab])

//Pagepersonalisation heading names
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.OrganisationSetting:
        return 'Organisation Setting';
      case SettingsTabPages.Companies:
        return 'Companies';
      case SettingsTabPages.ServiceCities:
        return 'Servicing Cities';
      case SettingsTabPages.ListTypeAndOptions:
        return 'List Type and Options';
      case SettingsTabPages.ProfileSetting:
        return 'Profile Settings';
      case SettingsTabPages.PaymentCredentials:
        return 'Payment Credentials';
      case SettingsTabPages.BuySmsCredits:
        return 'Buy SMS Credits';
      case SettingsTabPages.ConnectStripe:
        return 'Connect Stripe';
      case SettingsTabPages.ConnectXero:
        return 'Connect Xero';
      case SettingsTabPages.ConnectMyob:
        return 'Connect MYOB';
      case SettingsTabPages.ConfigureEmail:
        return 'Configure Email';
      case SettingsTabPages.ConnectCoverfright:
        return 'Connect Coverfright';
      case SettingsTabPages.PagePersonalisation:
        return 'Page Personalisation';
      default:
        return '';
    }
  };
  return (
    <div className="page-content">
      <BreadCrumb title={getPageTitle()} pageTitle="" />
      <Row>
        <Col md={3}>
          <SettingsTabControl activeTabSetter={setActiveTab} activeTab={activeTab} />
        </Col>

        <Col md={9} xs={9}>
          <Card>
            <CardHeader>
              <CardTitle>
                {pagePer.map((item, index) => (
                  <h4 className="mb-0 text-primary" key={item.id}> {item.page_name}</h4>
                ))}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="form-check form-check-switchery">
                <div className="table-responsive">
                  <Table className="table table-bordered table-hover toggle-circle default footable-loaded footable customize-table" id="listing-table">
                    <thead>
                      <tr>
                        <th>
                          <h5 className="font-weight-semibold">Columns</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageColumns.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span style={{ alignSelf: 'flex-start' }}>{item.column_display_name}</span>
                              {item && item.editable == 1 && (
                                <span className="form-check form-switch form-switch-lg" style={{ alignSelf: 'flex-end' }}>
                                  <Input type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" checked={item.permission ? item.colsettings.tenant_display === 1 : item.default_display === 1} onClick={item.permission ? (e) => tenantToggle(e, item.id) : (e) => defaultToggle(e, item.id, item.default_display)} />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* reloading */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default PersonalisationDetail