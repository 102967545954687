import React from 'react'
import { SettingsTabPages } from '../Constants/SettingsConstants';
import { ResetPassword } from '../TabPages/ResetPassword';
import { DeleteAccount } from '../TabPages/DeleteAccount';
import TermsOfUse from '../TabPages/TermsOfUse';
import PrivacyPolicy from "../TabPages/PrivacyPolicy";

export default function SettingsTabContent({ activeTab }) {

    const TabOutlet = () => {
        switch (activeTab) {
            case SettingsTabPages.ResetPassword:
                return <ResetPassword />
            case SettingsTabPages.DeleteAccount:
                return <DeleteAccount />
            case SettingsTabPages.TermsOfUse:
                return <TermsOfUse />
            case SettingsTabPages.PrivacyPolicy:
                return <PrivacyPolicy />
            default:
                return <ResetPassword />
        }
    }

    return (
        //Fragment just in case
        <>
            {TabOutlet()}
        </>

    )
}
