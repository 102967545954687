// import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import TabControlNavItem from "../../../Components/Common/TabControlNavItem";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function SettingsTabControl({
  activeTabSetter,
  activeTab,
  showResetPassword,
}) {
  const history = useNavigate();

  function clickHandler(navObject) {
    console.log(navObject);
    if (navObject.displayText == "Reset Password") {
      history("/worker_settings/reset-password");
    } else if (navObject.displayText == "Delete Account") {
      history("/worker_settings/delete-account");
    } else if (navObject.displayText == "Terms & Conditions") {
      history("/worker_settings/terms-conditions");
    } else if (navObject.displayText == "Privacy Policy") {
      history("/worker_settings/privacy-policy");
    }
    activeTabSetter(navObject);
  }

  return (
    <Card>
      <CardBody>
        <Nav pills vertical>
          {Object.values(SettingsTabPages).map(
            (tabObject) =>
              // Check permission to show the tab based on its name
              (tabObject.name !== "reset_password" || showResetPassword) && (
                <TabControlNavItem
                  navObject={tabObject}
                  onClickCallback={clickHandler}
                  key={tabObject.id}
                  activeTab={activeTab}
                />
              )
          )}
        </Nav>
      </CardBody>
    </Card>
  );
}
