import { Card, CardBody, CardHeader, Col, Container, Row, Accordion, AccordionItem, Collapse, Label, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';

const TextConnect = () => {

    document.title = "Text Connect | Get Roster";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Text Connect" pageTitle="Text Connect" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TextConnect;