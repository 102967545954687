// import React from 'react'
import { Card, CardBody, Nav } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import TabControlNavItem from "../../../Components/Common/TabControlNavItem";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function SettingsTabControl({
  activeTabSetter,
  activeTab,
  showResetPassword,
}) {
  const history = useNavigate();

  console.log(activeTab, "activeTab");
  function clickHandler(navObject) {
    console.log(navObject, "navObject");
    if (navObject.displayText == "Reset Password") {
      history("/settings/organisation-settings");
    } else if (navObject.displayText == "Delete Account") {
      history("/settings/delete-account");
    } else if (navObject.displayText == "Terms & Conditions") {
      history("/settings/terms-conditions");
    } else if (navObject.displayText == "Privacy Policy") {
      history("/settings/privacy-policy");
    }
    activeTabSetter(navObject);
  }

  // Filter the menu items based on showResetPassword flag
  const filteredMenuItems = Object.keys(SettingsTabPages).filter((tabKey) => {
    // Exclude ResetPassword tab if showResetPassword is false
    if (tabKey === "ResetPassword" && !showResetPassword) {
      return false;
    }
    return true;
  });

  console.log(SettingsTabPages,'SettingsTabPages')
  console.log(filteredMenuItems,'filteredMenuItems')
  const filteredMenuItemsGeneral = SettingsTabPages;

  return (
    <Card>
      <CardBody>
        <Nav pills vertical>
        {filteredMenuItems.map((tabKey) => (
            <TabControlNavItem
              navObject={SettingsTabPages[tabKey]} // Use the key to access the object
              onClickCallback={clickHandler}
              key={SettingsTabPages[tabKey].id}
              activeTab={activeTab}
            />
          ))}
        </Nav>
        {console.log(filteredMenuItemsGeneral, showResetPassword)}
      </CardBody>
    </Card>
  );
}
