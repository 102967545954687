import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../Components/Common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from '../Components/Common/RightSidebar';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from "reactstrap";
import { get_cookie } from "../helpers/get_cookie";
import { beampusher } from "../config";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    changeSidebarVisibility
} from "../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";


const Layout = (props) => {
    const [headerClass, setHeaderClass] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        sidebarVisibilitytype
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
        sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
    }));

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType || 
            sidebarVisibilitytype
        ) {
            window.dispatchEvent(new Event('resize')); 
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        sidebarVisibilitytype,
        dispatch]);

    useEffect(() => {
        if (sidebarVisibilitytype === 'show' || layoutType === "vertical" || layoutType === "twocolumn") {
            document.querySelector(".hamburger-icon").classList.remove('open');
        } else {
            document.querySelector(".hamburger-icon").classList.add('open');
        }

        if (Notification.permission === 'granted') {
            // If permission is already granted, initialize Pusher Beams and authenticate the user
            console.log(Notification.permission, "NotificationPermission")
            // startBeamsClient();
        }else{
            setModalOpen(true);
        }

        const broadcast = new BroadcastChannel("notification-channel");
        broadcast.onmessage = (event) => {
        console.log("Message received from service worker:", event.data);
        if (event.data.unread_count !== undefined) {
          // setUnreadCount(event.data.unread_count);
        //   setNotificationCount(event.data.unread_count);
        }
      };

        return () => {
            broadcast.close();
          };
    }, [sidebarVisibilitytype, layoutType]);

    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    const timezone = JSON.parse(get_cookie("workauthUser")).timezone;
    const access_token = JSON.parse(get_cookie("workauthUser")).access_token;
    const user_ids = String(user_id);

    const beamsClient = new PusherPushNotifications.Client({
        instanceId: beampusher.INSTANCE_ID,
    });

    const handleNotificationClick = () => {
        // Check if the browser supports notifications
        console.log(Notification.permission, "notificationclick");
    
        if (Notification.permission === 'granted') {
            // If permission is already granted, initialize Pusher Beams and authenticate the user
            console.log('Notification permission granted');
            setModalOpen(false);  // Close the modal if permission is already granted
            const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                url: beampusher.WEB_URL + "server/public/api/beams-auth",
            });
    
            beamsClient.start()
                .then(() => beamsClient.setUserId(user_ids, beamsTokenProvider))
                .then((response) => {
                    console.log(response, "Successfully authenticated with Pusher Beams");
                })
                .catch(console.error);
        } else if (Notification.permission !== 'denied') {
            // Request permission from the user
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    // If the user grants permission, initialize Pusher Beams and authenticate the user
                    console.log('Notification permission granted by user');
                    setModalOpen(false);  // Close the modal after user grants permission
                    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                        url: beampusher.WEB_URL + "server/public/api/beams-auth",
                    });
    
                    beamsClient.start()
                        .then(() => beamsClient.setUserId(user_ids, beamsTokenProvider))
                        .then((response) => {
                            console.log(response, "Successfully authenticated with Pusher Beams");
                        })
                        .catch(console.error);
                } else {
                    console.log('Notification permission denied by user.');
                }
            }).catch(console.error);
        } else {
            console.log("Notification permissions have been denied previously.");
        }
    };
        
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    const onCloseClick = () =>{
        setModalOpen(!isModalOpen);
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    <Footer />
                <Modal className="modal-dialog-bottom-right" id="bottom-rightModal" isOpen={isModalOpen} size="sm" >
                    <ModalHeader toggle={onCloseClick}>
                    </ModalHeader>
                    <ModalBody>
                        <Button onClick={() => {handleNotificationClick()}}>
                            <span className="d-flex align-items-center">Get Notifications <i className="bx bx-bell fs-16 ps-2"></i></span></Button>
                    </ModalBody>
                </Modal>
                </div>
            </div>
          
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);