import { Card, CardBody, Col, Row, TabContent } from "reactstrap";
import React, { useState, useCallback } from "react";
import { Button } from "reactstrap";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Input } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { get_cookie } from "../../../helpers/get_cookie";

import "react-toastify/dist/ReactToastify.css";

export const ResetPassword = () => {
  document.title = "Organisation Setting | Get Roster";
  const [modal, setModal] = useState(false);
  const [passwordoldshow, setPasswordOldShow] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [oldpassword, setOldPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

   // Handle old password change and validation
   const handleOldPassword = (e) => {
    const value = e.target.value;
    setOldPassword(value);
  };
  
  const toggleoldpasswordVisibility = () => {
    setPasswordOldShow(!passwordoldshow);
  };

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };


  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  const validatePassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value || !isValid) {
      setPasswordError(
        "Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value) {
      setConfirmPasswordError("Please Re-enter your password.");
      return false;
    } else if (value != password || !isValid) {
      setConfirmPasswordError("Passwords do not match!");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: (contact && contact.company) || "",
      designation: (contact && contact.designation) || "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleResetPassword = (event) => {
    event.preventDefault();
    /* validation */
    if (
      !validatePassword(password) ||
      !validateConfirmPassword(confirmPassword)
    ) {
      // Validation failed, don't proceed with submission
      // setIsLoading(false);
      return;
    }

    var formData = new FormData();
    formData.append("password", oldpassword);
    formData.append("newpassword", confirmPassword);
    formData.append("user_id", authUser_id);

    axios
      .post("/reset-password", formData)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.password_status);

          toast.success(response.message, { theme: "light" });
        } else {
          toast.error("Password change failed : Incorrect old password");
        }
        setPassword("");
        setConfirmPassword("");
        setOldPassword("");
      })
      .catch((error) => {
        console.error(error);
        toast.error("New password cannot be the same as the old password");
      });
  };

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  //*tenantid*
  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;

  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Reset Password</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <label className="form-label" htmlFor="oldpassword-input">
                  Old Password
                </label>
                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    type={passwordoldshow ? "text" : "password"}
                    className={`form-control pe-5 oldpassword-input ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Old password"
                    id="oldpassword-input"
                    name="oldpassword"
                    value={oldpassword}
                    onChange={handleOldPassword}
                    onPaste={(e) => e.preventDefault()}
                  />
                  <Button
                    color="link"
                    onClick={toggleoldpasswordVisibility}
                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                  >
                    <i
                      className={
                        passwordoldshow ? "ri-eye-off-fill" : "ri-eye-fill"
                      }
                    ></i>
                  </Button>
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <label className="form-label" htmlFor="password-input">
                  New Password
                </label>
                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    type={passwordShow ? "text" : "password"}
                    className={`form-control pe-5 password-input ${
                      newPasswordError ? "is-invalid" : ""
                    }`}
                    placeholder="Enter password"
                    id="password-input"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    onPaste={(e) => e.preventDefault()}
                  />
                  <Button
                    color="link"
                    onClick={togglePasswordVisibility}
                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                  >
                    <i className="ri-eye-fill align-middle"></i>
                  </Button>
                  {newPasswordError && (
                    <div className="invalid-feedback">{newPasswordError}</div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <label className="form-label" htmlFor="password-input">
                  Confirm Password
                </label>
                <div className="position-relative auth-pass-inputgroup">
                  <Input
                    type={confirmPasswordShow ? "text" : "password"}
                    className={`form-control pe-5 password-input ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    placeholder="Re-enter password"
                    id="confirm-password-input"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onPaste={(e) => e.preventDefault()}
                  />
                  <Button
                    color="link"
                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    id="password-addon"
                  >
                    <i className="ri-eye-fill align-middle"></i>
                  </Button>
                  {confirmPasswordError && (
                    <div className="invalid-feedback">
                      {confirmPasswordError}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-soft-success"
                  onClick={handleResetPassword}
                >
                  Update
                </button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer />
    </div>
  );
};
