import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import findwork from "../../assets/images/find_work_icon.png";

const BreadCrumb = ({ title, pageTitle, img }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            {img? (<div className="hstack gap-2">
              <div style={{width:'2rem'}}>
                <img
                  src={img}
                  className="profile-wid-img w-100 h-100"
                  alt="bread_icon"
                />
              </div>
              <h4 className="mb-sm-0">{title}</h4>
            </div>) : (<h4 className="mb-sm-0">{title}</h4>)}
            

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                {/* <li className="breadcrumb-item active">{title}</li> */}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
