import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import TableContainer from '../../Components/Common/TableContainer';
const Directory = () => {

    document.title = "Directory | Get Rostered";
    const [activeTab, setActiveTab] = useState("1");

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const columns = useMemo(
        () => [
            {
                Header: "First name",
                accessor: "firstName",
                filterable: false,

            },
            {
                Header: "Last name",
                accessor: "lastName",
                filterable: false,

            },
            {
                Header: "Mobile phone",
                accessor: "number",
                filterable: false,

            },
            {
                Header: "Available info",
                accessor: "information",
                filterable: false,

            }
        ])

    const data = [{
        "firstName": 'Jenny',
        "lastName": 'Joseph',
        "number": '098744357',
        "information": <select className="form-select " value={1}>
            <option value={1}>All Fields</option>
            <option value={2}>Option 2</option>
            <option value={3}>Option 3</option>
        </select>,
    },
    {
        "firstName": 'Kani',
        "lastName": 'Mozhi',
        "number": '05647899',
        "information": <select className="form-select " value={1}>
            <option value={1}>All Fields</option>
            <option value={2}>Option 2</option>
            <option value={3}>Option 3</option>
        </select>,
    }]
    return (

        < React.Fragment >


            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Directory" pageTitle="Directory" />

                </Container>

                <Container fluid>

                    <Card>
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" }, "text-body")}
                                        onClick={() => {
                                            tabChange("1");
                                        }}>
                                        <i className="fas fa-home"></i>
                                        Users
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#"
                                        className={classnames({ active: activeTab === "2" }, "text-body")}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button">
                                        <i className="far fa-user"></i>
                                        Work Contacts
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <TableContainer
                                        columns={columns}
                                        data={(data || [])}
                                        isGlobalFilter={true}
                                        isAddUserList={false}
                                        customPageSize={100}
                                        className="custom-header-css"
                                        divClass="table-responsive table-card mb-3"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light"
                                        // handleContactClick={handleContactClicks}
                                        isContactsFilter={true}
                                        SearchPlaceholder="Search for contact..."
                                    />

                                </TabPane>

                                <TabPane tabId="2">
                                    <div>Tab 2</div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>


                </Container>
            </div>
        </React.Fragment >
    );
};

export default Directory;