import { Card, CardBody, CardHeader, Col, Container, Row, Accordion, AccordionItem, Collapse, Label, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

const JobRoster = () => {

    document.title = "Job Roster | Get Rostered";
    const events = [
        {
            id: 'event1',
            title: "Mardialloc Cafe > Open shift",
            extendedProps: {
                description: 'Description for Event 1', // Additional description
            },
            start: '2023-12-07T10:00:00',
            end: '2023-12-07T13:00:00',
            resourceId: 'resource1',
        },
        // Add more events as needed
    ];

    const eventContenst = ({ event }) => (
        <>
            <div className="event-title">{event.title}</div>
            <div className="event-description">{event?.extendedProps?.description}</div>
        </>
    );
    const eventContent = (arg) => {
        return (
            <>{console.log(arg)}
                {(arg?.event?._context?.viewApi?.type === "resourceTimelineDay") ? (
                    <>

                        <div className="event-title">{arg?.event?.title}</div>
                        <div className="event-description">{arg?.event?.extendedProps?.description}</div>
                    </>
                ) : (
                    <div className="event_background" dangerouslySetInnerHTML={{ __html: arg.event.title }}>
                    </div>
                )}
            </>
        )

    }


    const resources = [
        { id: 'resource1', title: 'Resource 1' },
        { id: 'resource2', title: 'Resource 2' },
        // Add more resources as needed
    ];
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Job Roster" pageTitle="Job Roster" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <FullCalendar
                                        schedulerLicenseKey="0324696433-fcs-1690265247"
                                        plugins={[resourceTimelinePlugin, dayGridPlugin, interactionPlugin, resourceTimeGridPlugin, timeGridPlugin]}
                                        initialView="resourceTimelineDay" // or 'resourceTimelineWeek', 'resourceTimelineMonth', etc.
                                        events={events}
                                        resources={resources}
                                        eventContent={eventContent}
                                        headerToolbar={{
                                            left: 'view spacerButton prev today next',
                                            center: 'title',
                                            right: 'resourceTimelineDay,timeGridWeek,dayGridMonth',
                                        }}
                                        customButtons={{
                                            view: {

                                                text: (
                                                    <button className="btn btn-outline-info" >
                                                        View options
                                                    </button>
                                                ),
                                               
                                            },
                                            spacerButton: { // Define a spacer button
                                                text: '',
                                              
                                                click: function () { }, // Empty click function
                                            },
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default JobRoster;