import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  NavLink,
  Table,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { format } from "date-fns";

import Select from "react-select";
import Loader from "../../Components/Common/Loader";

// Import Table Data
// import { BaseExample } from '../GridTablesData';
import axios from "axios";
import { get_cookie } from "../../helpers/get_cookie";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import * as moment from "moment";
import TableContainer from "../../Components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import AppliedJobDetail from "./AppliedJobDetail";

const AppliedJobs = () => {
  const [userId, setUserId] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isFilter, setIsFilter] = useState(true);
  const [openJobId, setOpenJobId] = useState();
  const [openJobDetail, setOpenJobDetail] = useState(false);
  const [data, setData] = useState([]);

  console.log(openJobId, "openJobId");
  //data table
  const [jobStatusList, setJobStatusList] = useState(null);

  const loadTable = async () => {
    if (get_cookie("workauthUser") !== false) {
      const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
      setUserId(user_id);

      const postData = {
        user_id: user_id,
      };
      setisLoading(true);
      axios
        .post("/get-job-applications", postData)
        .then((res) => {
          // setOrg_Date(res.data[0]);
          setData(res?.job_applications);
          console.log(res?.job_applications, "res1234");
          // setCreatedDateEnd(moment().format(res?.data[0]?.organisation_date_picker));
          // setCreatedDateStart(moment().subtract(1, 'months').format(res?.data[0]?.organisation_date_picker));
        })
        .catch((error) => {
          console.error(error);
        });
      axios
        .get("/get-job-status")
        .then((res) => {
          setJobStatusList(res.job_offer_status);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setisLoading(false);
        });
    } else {
      setisLoading(false);
      console.error("Error fetching user profile");
    }
  };

  useEffect(() => {
    loadTable();
  }, []);

  // Function to handle search input changes
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to filter data based on search query
  const filterData = () => {
    const filtered = data.filter((item) => {
      const businessNameMatch = item.business_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const titleMatch = item.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      // Return true if either business_name or title matches the search query
      return businessNameMatch || titleMatch;
    });

    setFilteredData(filtered);
  };

  // useEffect to trigger filtering when searchQuery changes
  useEffect(() => {
    filterData();
  }, [searchQuery, data]);

  const toggleJobDetail = () => {
    setOpenJobDetail(!openJobDetail);
  };

  //css stylings for random bg color and default profile pic
  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];

  // Format date to MM/DD/YYYY
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getDate().toString().padStart(2, "0") +
      "/" +
      date.getFullYear()
    );
  };

  // Get status badge class based on status name
  const getStatusBadgeClass = (statusName) => {
    switch (statusName.toLowerCase()) {
      case "new":
        return "badge-soft-success";
      case "under review":
        return "badge-soft-info";
      case "shortlisted":
        return "badge-soft-warning";
      case "rejected":
        return "badge-soft-danger";
      default:
        return "badge-soft-dark";
    }
  };

  const jobOfferColumn = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "business_name",
        filterable: false,
      },
      {
        Header: "Job Vacancy Title",
        accessor: "title",
        Cell: ({ row }) => (
          <div
            onClick={() => {
              setOpenJobId(row.original.job_id);
              toggleJobDetail();
            }}
            style={{ color: "#f672a7", cursor: "pointer" }}
          >
            {row.original.title}
            {console.log(row.original.job_id)}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Applied Date",
        accessor: "applied_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }

          // Format the date using date-fns
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return formattedDate;
        },
      },

      {
        Header: "Job Close Date",
        accessor: "close_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }

          // Format the date using date-fns or any other date formatting library you prefer
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return <div className='text-danger'>{formattedDate}</div>
        },
      },
      {
        Header: "Status",
        accessor: "status_name",
        filterable: false,
        Cell: ({ value }) => (
          <div className={`badge ${getStatusBadgeClass(value)}`}>{value}</div>
        ),
      },
    ],
    []
  );

  // // JobStatus Multi select
  // function handleJobStatusMulti(selectedMulti) {
  //   setSelectedJobStatusMulti(selectedMulti);
  // }

  // // Map the response to options with 'value' and 'label'
  // const JobStatusOptions = jobStatusList?.map((status) => ({
  //   value: status.id,
  //   label: status.status_name,
  // }));

  document.title = "Applied Jobs | Get Rostered";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Applied Jobs" pageTitle="Get Rostered" />
          <Row>
            <Col lg={12}>
              <Card id="appliedJobList">
                <CardBody className="pt-0">
                  <div>
                    {data.length  ? (
                      <TableContainer
                        columns={jobOfferColumn}
                        data={data || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search for Applied Jobs..."
                      />
                    ) : (
                      data.length === 0 && (
                        <>
                          <CardBody className="border border-dashed border-end-0 border-start-0">
                            <form>
                              <Row className="g-3">
                                <Col>
                                  <div
                                    className={
                                      "search-box me-2 mb-2 d-inline-block"
                                    }
                                  >
                                    <input
                                      id="search-bar-0"
                                      type="text"
                                      className="form-control search /"
                                      placeholder={"Search for Applied Jobs..."}
                                      value={""}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                  </div>
                                </Col>
                              </Row>
                            </form>
                          </CardBody>
                          <div className={"table-responsive table-card mb-3"}>
                            <Table
                              hover
                              className={"align-middle table-nowrap"}
                            >
                              <thead className={"table-light"}>
                                <tr>
                                  {jobOfferColumn.map((column) => (
                                    <th key={column.jobId}>{column.Header}</th>
                                  ))}
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td className="py-4 text-center" colSpan="5">
                                    <div>
                                      <lord-icon
                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                        trigger="loop"
                                        delay="1000"
                                        colors="primary:#405189,secondary:#0ab39c"
                                        style={{
                                          width: "72px",
                                          height: "72px",
                                        }}
                                      ></lord-icon>
                                    </div>

                                    <div className="mt-4">
                                      <h5>Sorry! No Applied Jobs Found</h5>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={openJobDetail}
            toggle={toggleJobDetail}
            style={{ minWidth: "65%", borderLeft: 0 }}
            centered
            scrollable={true}
            size="md"
            modalClassName="zoomIn"
            id="offcanvasExample"
          >
            <ModalBody
              className="grey-overall-bg p-0"
              style={{ overflowX: "hidden" }}
            >
              <AppliedJobDetail jobId={openJobId} toggle={toggleJobDetail} />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AppliedJobs;
