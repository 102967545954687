import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { SettingsTabPages } from "./Constants/SettingsConstants";
import SettingsTabControl from "./TabComponents/SettingsTabControl";
import SettingsTabContent from "./TabComponents/SettingsTabContent";
import { get_cookie } from "../../helpers/get_cookie";

const WorkerSettings = () => {
  document.title = "Worker Settings | Get Rostered";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState(SettingsTabPages.DeleteAccount);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAuthData = JSON.parse(get_cookie("workauthUser"));
        const is_resetPassword =
          userAuthData?.is_google_login || userAuthData?.is_apple_login;
        console.log(is_resetPassword, "is_resetPassword");
        setShowResetPassword(is_resetPassword === true ? true : false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false); // Once data is fetched, stop loading
      }
    };

    fetchData();
  }, [tab]);

  console.log(tab, "tab");
  console.log(showResetPassword, "showResetPassword");
  useEffect(() => {
    // if (!loading) {
    // Only set activeTab after loading is finished
    if (tab === "delete_account") {
      setActiveTab(SettingsTabPages.DeleteAccount);
    } else if (tab === "reset_password" && showResetPassword) {
      setActiveTab(SettingsTabPages.ResetPassword);
    } else if (tab === "terms_conditions") {
      setActiveTab(SettingsTabPages.TermsOfUse);
    } else if (tab === "privacy_policy") {
      setActiveTab(SettingsTabPages.PrivacyPolicy);
    } else {
      setActiveTab(SettingsTabPages.DeleteAccount);
    }
    // }
  }, [showResetPassword]);

  console.log(activeTab, "activeTab");
  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.ResetPassword:
        return "Reset Password";
      case SettingsTabPages.DeleteAccount:
        return "Delete Account";
      case SettingsTabPages.TermsOfUse:
        return "Terms & Conditions";
      case SettingsTabPages.PrivacyPolicy:
        return "Privacy policy";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={getPageTitle()} pageTitle="Worker Settings" />
          <Row>
            <Col md="3">
              <SettingsTabControl
                activeTabSetter={setActiveTab}
                activeTab={activeTab}
                showResetPassword={showResetPassword}
              />
            </Col>
            {activeTab ? (
              <Col md="9">
                <SettingsTabContent activeTab={activeTab} />
              </Col>
            ) : (
              <Col md="9">
                <Card className="p-3 text-bold"> Permission Not Granted.</Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WorkerSettings;
