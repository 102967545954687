module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  beampusher: {
    INSTANCE_ID: "a2403fd5-e2d9-45f4-9958-fc9962dc08e9",
    WEB_URL: "https://devbusiness.getrostered.app/",
  },
  tipalti: {
    MASTER_KEY: "7HFVbxPdMHKq1nnzkCFU8h+zlgLK1KJ7sqSPxT4yg720yjYEMG7KYsj0QxfANkIr",
    BASE_URL: "https://ui2.sandbox.tipalti.com/",
  },
  api: {
    // API_URL: "http://127.0.0.1:8001/api/",
    // WEB_URL: "http://127.0.0.1:8001/api/",
    API_URL: "https://devwork.getrostered.app/server/public/api/",
    WEB_URL: "https://devwork.getrostered.app/server/public/api/",
  }
};
