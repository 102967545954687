import React, { useEffect, useState, useCallback, useMemo,useRef} from "react";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FeatherIcon from 'feather-icons-react';
import { ClipLoader } from 'react-spinners';
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";

import Select from "react-select";
import { jsx } from '@emotion/react'
import classnames from "classnames";
import { orderSummary } from "../../common/data/ecommerce";
import { Link } from "react-router-dom";
import GoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const AddSite = () => {
  const [isLoading, setisLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  // Assign People
  const [nameOne, setnameOne] = useState('');
  const [nameTwo, setnameTwo] = useState('');
  const [nameThree, setnameThree] = useState('');
  // Zones
  const [fieldSetsZone, setFieldSetsZone] = useState(1); // Initialize fieldSetsZone
  const [zones, setZones] = useState([...Array(fieldSetsZone)].map(() => ({
    zone_info: {
      zones: [
        { zone_name: '', zone_description: '' },
        { zone_name: '', zone_description: '' },
        { zone_name: '', zone_description: '' },
      ],
    },
  })));
  // Assign People
  const [people, setPeople] = useState([{
    name_one: '',
    name_two: '',
    name_three: '',
  }]);
  //site
  const [site_name, setSiteName] = useState('');
  const [address, setAddress] = useState('');
  const [week_starts, setWeekStarts] = useState('');
  const [time_zone, setTimeZone] = useState('');
  const [time_option, setTimeOption] = useState('');
  const [talent_pool, setTalentPool] = useState('');
  const [addressError, setAddressError] = useState('');
  const [siteNameError, setSiteNameError] = useState('');
  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };
      const [selectedCity, setSelectedcity] = useState();
      const [selectedSuburb, setselectedSuburb] = useState();
      const [selectedPostcode, setselectedPostcode] = useState();
      const [country, setCountry] = useState([]);
  const residentialplace = (place) => {
      const addressComponents = place?.address_components;
        console.log(place);
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(`${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `);
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);

  };
  const loadSite = () => {
    setisLoading(true);
    axios.get('/api/get-dropdown-and-timezones')
      .then(response => {
        setTalentPool(response.dropdownOptions);
        if (Array.isArray(response.timezones)) {
        setTimeOption(response.timezones);
        console.log(response.timezones);
      }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadSite();
  }, []);

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectDay(selectedDay) {
    setWeekStarts(selectedDay);
  }
    const timeZoneOptions = Array.isArray(time_option)
  ? time_option.map(timeZone => ({ value: timeZone, label: timeZone }))
  : [];
    const handleSelectTimeZone = (selectedOption) => {
      if (selectedOption) {
        setTimeZone({ value: selectedOption.value, label: selectedOption.label });
      } else {
        // Handle the case when no option is selected, e.g., setTimeZone to some default value or clear it.
        setTimeZone(null); // Assuming time_zone is initially set to null
      }
    };


  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

const handleNameChangeOne = (selectedOption, index) => {
  updatePeople(index, 'name_one', selectedOption);
};

const handleNameChangeTwo = (selectedOption, index) => {
  updatePeople(index, 'name_two', selectedOption);
};

const handleNameChangeThree = (selectedOption, index) => {
  updatePeople(index, 'name_three', selectedOption);
};

  const [fieldSets, setFieldSets] = useState(1);

     //Assign People
    const addMoreFields = () => {
    setPeople((prevPeople) => [
      ...prevPeople,
      {
        name_one: '',
        name_two: '',
        name_three: '',
      },
    ]);
    setFieldSets(fieldSets + 1);
  };
    const updatePeople = (index, field, selectedOption) => {
      setPeople((prevPeople) => {
        const updatedPeople = [...prevPeople];
        updatedPeople[index][field] = selectedOption;
        return updatedPeople;
      });
    };
    const removeFields = (index) => {
      setPeople((prevPeople) => {
        const updatedPeople = [...prevPeople];
        updatedPeople.splice(index, 1);
        return updatedPeople;
      });
      setFieldSets(fieldSets - 1);
    };
    //zones
    const updateZone = (index, subIndex, field, value) => {
    setZones((prevZones) => {
      const updatedZones = [...prevZones];
      updatedZones[index].zone_info.zones[subIndex][field] = value;
      return updatedZones;
    });
  };

  const addMoreZone = () => {
    setZones((prevZones) => [
      ...prevZones,
      {
        zone_info: {
          zones: [
            { zone_name: '', zone_description: '' },
            { zone_name: '', zone_description: '' },
            { zone_name: '', zone_description: '' },
          ],
        },
      },
    ]);
    setFieldSetsZone((prev) => prev + 1); // Update fieldSetsZone
  };

  const removeZone = (index) => {
    setZones((prevZones) => {
      const updatedZones = [...prevZones];
      updatedZones.splice(index, 1);
      return updatedZones;
    });
    setFieldSetsZone((prev) => prev - 1); // Update fieldSetsZone
  };

  const productCountry = [
    {
      options: [
        { label: "Select Day...", value: "Select Day" },
        { label: "Monday", value: "Mon" },
        { label: "Tuesday", value: "Tue" },
        { label: "Wednesday", value: "Wed" },
        { label: "Thursday", value: "Thu" },
        { label: "Friday", value: "Fri" },
        { label: "Saturday", value: "Sat" },
        { label: "Sunday", value: "Sun" },
      ],
    },
  ];

  // save site
  const handleSubmit = (event) => {
    //event.preventDefault();
      setisLoading(true);
    /* validation */
    if (!site_name.trim()) {
            setSiteNameError('Please enter site name.');
        } else {
            setSiteNameError('');
        }
    if (!address.trim()) {
            setAddressError('Please enter your address.');
        } else {
            setAddressError('');
        }
    var formData = {
      "site_name": site_name,
      "site_address": selectedCity,
      "suburb": selectedSuburb,
      "post_code": selectedPostcode,
      "country_name": country,
      "week_starts": week_starts.value,
      "time_zone": time_zone.value,
      "zones": zones,
      "people": people,
    }
    console.log(formData);
    axios.post('/api/add-site', formData)
      .then(response => {
        toast.success(response.success, { theme: "light" });
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  }
  document.title="New Site | Get Roster";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="New Site" pageTitle="New Site" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody className="checkout-tab">
                  <Form action="#">
                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                      <Nav
                        className="nav-pills nav-justified custom-nav"
                        role="tablist"
                      >
                        <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 1, done: (activeTab <= 4 && activeTab >= 0) }, "fs-15 p-3")}
                            onClick={() => { toggleTab(1); }}
                          >
                            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Add New Site
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "fs-15 p-3")}
                            onClick={() => { toggleTab(2); }}
                          >
                            <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Add Zones
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 3, done: activeTab <= 4 && activeTab > 2 }, "fs-15 p-3")}
                            onClick={() => { toggleTab(3); }}
                          >
                            <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Assign People
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1} id="pills-bill-info">
                        <div>
                          <p className="text-muted mb-4">
                            A Site is a physical location, event, or temporary job site where your workforce is stationed.
                          </p>
                        </div>

                        <div>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-firstName"
                                  className="form-label"
                                >
                                  Site Name<span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text" value={site_name} onChange={(e) => setSiteName(e.target.value)}
                                  className={`form-control ${siteNameError ? 'is-invalid' : ''}`}
                                  id="billinginfo-firstName"
                                  placeholder="Enter first name"
                                />
                                {siteNameError && <div className="invalid-feedback">{siteNameError}</div>}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-lastName"
                                  className="form-label"
                                >
                                  Site Address<span className="text-danger">*</span>
                                </Label>
                                <GoogleAutocomplete className={`${addressError ? 'is-invalid' : ''}`}
                                apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                onPlaceSelected={(place) => residentialplace(place)}
                                options={{
                                  types: ["address"],
                                  componentRestrictions: {
                                    country: "au",
                                  },
                                }}
                                style={{
                                  width:"100%",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  zIndex: 9999,
                                }}
                              />
                              {addressError && <div className="invalid-feedback">{addressError}</div>}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="day" className="form-label">
                                  At this site, the week starts on
                                </Label>
                                <Select
                                value={week_starts}
                                onChange={(selectedOption) => {
                                  handleSelectDay(selectedOption); // Pass the selected value to the function
                                }}
                                options={productCountry} // Extract the options array from the nested structure
                                id="day"
                              ></Select>
                              </div>
                            </Col>
                            </Row>
                            <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="state" className="form-label">
                                  TimeZone
                                </Label>
                                <Select
                                id="state"
                                value={time_zone}
                                onChange={(selectedOption) => {
                                  handleSelectTimeZone(selectedOption);
                                }}
                                options={timeZoneOptions}
                              ></Select>

                              </div>
                            </Col>
                          </Row>

                          <div className="d-flex align-items-start gap-3 mt-3">
                            <button
                              type="button"
                              className="btn btn-primary btn-label right ms-auto nexttab"
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId={2}>
                        <div>
                          {/*<h5 className="mb-1">Shipping Information</h5>*/}
                          <p className="text-muted mb-4">
                            Each Site is subdivided into one or more Zones, which are distinct areas designated for particular roles or activities.
                          </p>
                        </div>

                        <div className="mt-4">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-grow-1">
                              <h5 className="fs-14 mb-0">Zones Of Work</h5>
                            </div>
                          </div>
                          <Row className="gy-3">
                                 <Col sm={3}>
                                    <Label
                                        htmlFor="billinginfo-firstName"
                                        className="form-label"
                                      >
                                        Zone Name
                                      </Label>
                                  </Col>
                                  <Col sm={6}>
                                      <Label
                                        htmlFor="billinginfo-firstName"
                                        className="form-label"
                                      >
                                        Description
                                      </Label>
                                    </Col>
                              </Row>
                              {[...Array(fieldSetsZone)].map((_, index) => (
                                <div key={index}>
                                  <Row className="gy-3">
                                    {zones[index].zone_info.zones.map((zone, subIndex) => (
                                      <React.Fragment key={subIndex}>
                                        <Col sm={3}>
                                          <div className="mb-3">
                                            <Input
                                              value={zone.zone_name}
                                              onChange={(e) => updateZone(index, subIndex, 'zone_name', e.target.value)}
                                              type="text"
                                              className="form-control"
                                              id={`billinginfo-zone-name-${index}-${subIndex}`}
                                              placeholder={`eg. ${subIndex + 1}`}
                                            />
                                          </div>
                                        </Col>
                                        <Col sm={8}>
                                          <div className="mb-3">
                                            <Input
                                              value={zone.zone_description}
                                              onChange={(e) => updateZone(index, subIndex, 'zone_description', e.target.value)}
                                              type="text"
                                              className="form-control"
                                              id={`billinginfo-zone-description-${index}-${subIndex}`}
                                            />
                                          </div>
                                        </Col>
                                      </React.Fragment>
                                    ))}
                                    <Col sm={1}>
                                      <div>
                                        <i className="ri-close-line text-primary" onClick={() => removeZone(index)}></i>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                          <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-info mb-3"
                                onClick={addMoreZone}
                              >
                                Add More
                              </button>
                            </div>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary btn-label right ms-auto nexttab"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                            Next
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={3} id="pills-finish">
                        <div>
                          {/*<h5 className="mb-1">Payment Selection</h5>*/}
                          <p className="text-muted mb-4">
                           Team members from your Talent Pool can be assigned to the sites
                          </p>
                        </div>

                        {/*<Row className="g-4">
                          
                        </Row>*/}
                        <Row className="gy-3">
                                 <Col sm={6}>
                                    <Label
                                        htmlFor="billinginfo-firstName"
                                        className="form-label"
                                      >
                                        Name
                                      </Label>
                                  </Col>
                              </Row>
                              {[...Array(fieldSets)].map((_, index) => (
                                  <div key={index}>
                                    <Row className="gy-3 mb-4">
                                      <Col sm={6}>
                                        <div>
                                        <Select
                                          value={people[index].name_one}
                                          onChange={(selectedOption) => handleNameChangeOne(selectedOption, index)}
                                          options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                          isSearchable={true}
                                        />
                                        </div>
                                      </Col>
                                      <Col sm={1}>
                                        <div>
                                        <i className="ri-close-line text-primary" onClick={removeFields}></i>
                                        </div>
                                        </Col>
                                    </Row>
                                    <Row className="gy-3 mb-4">
                                      <Col sm={6}>
                                        <div>
                                        <Select
                                          value={people[index].name_two}
                                          onChange={(selectedOption) => handleNameChangeTwo(selectedOption, index)}
                                          options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                          isSearchable={true}
                                        />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="gy-3 mb-4">
                                      <Col sm={6}>
                                        <div>
                                        <Select
                                          value={people[index].name_three}
                                          onChange={(selectedOption) => handleNameChangeThree(selectedOption, index)}
                                          options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                          isSearchable={true}
                                        />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}

                          <div className="flex-shrink-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-info mb-3"
                                onClick={addMoreFields}
                              >
                                Add More
                              </button>
                            </div>
                        

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Back
                          </button>
                          {/* reloading the page */}
                           {isUpdating && (
                                <div className="spinner-overlay">
                                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                                </div>
                              )}
                          <button
                            type="button"
                            className="btn btn-primary btn-label right ms-auto nexttab"
                            onClick={handleSubmit} disabled={isUpdating}
                          >
                            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                            Add Site
                          </button>
                        </div>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSite;
