import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  PopoverBody,
  UncontrolledPopover,
  Popover,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { get_cookie, add_cookie } from "../../helpers/get_cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect, useCallback, useId } from "react";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import clockImg from "../../assets/images/clock.png";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";

const Overview = () => {
  document.title = "Overview | Get Rostered";
  const navigate = useNavigate();
  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const [overviewData, setOverviewData] = useState([]);
  const [currentShift, setCurrentShift] = useState([]);
  const [lateTime, setLateTime] = useState([]);
  const [shiftId, setShiftId] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [fullname, setfullname] = useState("");
  const [userimageurl, setuserimageurl] = useState([]);
  const [shiftsthisweek, setshiftthisweek] = useState();
  const [hourthisweek, sethourthisweek] = useState();
  const [shiftToCancel, setShiftToCancel] = useState(null);
  const [upcomingShiftsCount, setUpcomingShiftsCount] = useState();
  const [availableshifts, setavailableshifts] = useState([]);
  const [totalhours, settotalhoursworked] = useState();
  const [assignedshifts, setassignedshifts] = useState();
  const [openedshift, setopenedshift] = useState([]);
  const [availableshiftdetails, setavailableshiftdetails] = useState();
  const [workerName, setWorkerName] = useState(null);
  const [isCancelEnabled, setIsCancelEnabled] = useState(false);
  const [totalShiftWeek, setTotalShiftWeek] = useState(null);
  const [totalHourWeek, setTotalHourWeek] = useState(null);
  const [showStartShift, setshowStartShift] = useState(false);
  const [showEndShift, setshowEndShift] = useState(false);
  const [showStartBreak, setshowStartBreak] = useState(false);
  const [showEndBreak, setshowEndBreak] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [available_Modal, setavailable_Modal] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [upcoming_Modal, setUpcoming_Modal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false); // State variable to track modal open/close
  const [selectedOpenShift, setSelectedOpenShift] = useState(null); // State variable to store selected shift data
  const [selectedDate, setSelectedDate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [upcomingShifts, setUpcomingShifts] = useState();
  const [showBreakDetails, setTogBreakDetails] = useState(false);
  const [selectedBreak, setSelectedBreak] = useState();
  const [showShiftEnded, setshowShiftEnded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [isModalLoading, setModalLoader] = useState(false);

  // -----------End Shift Modal-----------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [breaks, setBreaks] = useState([]);
  const [breakType, setBreakType] = useState("");
  const [duration, setDuration] = useState("");
  const [workerComments, setWorkerComments] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState(null);
  const [breakEndTime, setBreakEndTime] = useState(null);
  const [isShiftStarted, setIsShiftStarted] = useState(false);
  const [isBreakStarted, setIsBreakStarted] = useState(false);
  const [isShiftEnded, setIsShiftEnded] = useState(false);
  const [isBreakEnded, setIsBreakEnded] = useState(false);
  const [todayShifts, setTodayShifts] = useState([]);
  const [isShiftOngoing, setIsShiftOngoing] = useState(false);
  const [ongoingShiftId, setOngoingShiftId] = useState(null);

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleDeleteBreak = (index) => {
    const updatedBreaks = breaks.filter((_, i) => i !== index);
    setBreaks(updatedBreaks);
  };

  const totalDuration = breaks.reduce((total, breakItem) => {
    const breakStart = breakItem.start
      ? moment(new Date(breakItem.start * 1000))
      : moment().startOf("day");
    const breakFinish = breakItem.finish
      ? moment(new Date(breakItem.finish * 1000))
      : moment().startOf("day");

    const breakDuration = moment
      .duration(breakFinish.diff(breakStart))
      .asMinutes();

    return total + breakDuration;
  }, 0);

  const handleEndShift = (event) => {
    setIsLoadings(true);
    // Implement logic to end shift
    // if (shiftData.end_time <= shiftData.start_time) {
    //   toast.error("Shift end time should be later than start time");
    //   setIsLoadings(false);
    //   return; // Exit the function early if validation fails
    // }
    let total_duration;
    if (totalDuration >= 0) {
      total_duration = Math.round(totalDuration);
    } else {
      toast.error("Duration should not be negative minutes");
      setIsLoadings(false);
      return; // Exit the function early if validation fails
    }
    var formData = {
      user_id: authUser_id,
      shift_id: shiftData.shift_id,
      start_time: shiftData.start_time,
      end_time: shiftData.end_time,
      break_duration: total_duration,
      worker_comments: workerComments,
    };
    console.log(formData, "formData");
    axios
      .post("/end-shift", formData)
      .then((response) => {
        setIsEditModalOpen(false);
        setShiftDuration(0);
        localStorage.removeItem("breakStartTime"); // Reset shift start time
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setIsLoadings(false);
      });
  };

  const handleAddBreak = () => {
    // Get today's date
    const today = new Date();
    // Set the time components to "12:00 AM"
    today.setHours(0, 0, 0, 0);

    // Create a new break object with the default start and finish times
    const newBreak = {
      start: today.getTime() / 1000, // Set default start time to today's "12:00 AM" timestamp
      finish: today.getTime() / 1000, // Set default finish time to today's "12:00 AM" timestamp
    };

    // console.log(newBreak, " newBreak12334");

    // Add the new break to the breaks array
    setBreaks([...breaks, newBreak]);
  };

  // -----------End Shift Modal--------------------

  const [startWeek, setStartWeek] = useState(
    moment(currentDate).startOf("week").format("YYYY-MM-DD")
  );
  const [endWeek, setEndWeek] = useState(
    moment(currentDate).endOf("week").format("YYYY-MM-DD")
  );

  // shift and break Timings
  const [shiftStartTime, setShiftStartTime] = useState(null);
  const [breakStartTime, setBreakStartTime] = useState(null);

  const [shiftDuration, setShiftDuration] = useState(0);
  const [breakDuration, setBreakDuration] = useState(0);

  // You can remove the second argument from the useEffect hook if it's not needed
  useEffect(() => {
    getOverviewData(startWeek, endWeek);

    upcomingShiftsData();
    AvailableShiftsData(authUser_id);
  }, []);
  useEffect(() => {
    manageShiftAndBreak(null, ongoingShiftId);
  }, [ongoingShiftId]);

  // useEffect(() => {
  //   let shiftInterval, breakInterval;
  //   if (shiftStartTime) {
  //     shiftInterval = setInterval(() => {
  //       const currentTime = new Date().getTime();
  //       const elapsedTime = currentTime - shiftStartTime;
  //       setShiftDuration(elapsedTime);
  //     }, 1000); // Update every second
  //   }

  //   if (breakStartTime) {
  //     breakInterval = setInterval(() => {
  //       const currentTime = new Date().getTime();
  //       const elapsedTime = currentTime - breakStartTime;
  //       setBreakDuration(elapsedTime);
  //     }, 1000); // Update every second
  //   }

  //   return () => {
  //     clearInterval(shiftInterval);
  //     clearInterval(breakInterval);
  //   };
  // }, [shiftStartTime, breakStartTime]);

  // Add a useEffect hook to handle side effects after state updates
  useEffect(() => {
    const weekStartDate = moment(currentDate)
      .startOf("week")
      .format("YYYY-MM-DD");
    const weekEndDate = moment(currentDate).endOf("week").format("YYYY-MM-DD");
    setStartWeek(weekStartDate);
    setEndWeek(weekEndDate);
  }, [currentDate]);

  useEffect(() => {
    const shiftOngoing = todayShifts.some(
      (shift) => shift.showEndShift === true
    );
    if (shiftOngoing) {
      setIsShiftOngoing(true);
    }
  }, [todayShifts]);

  useEffect(() => {
    getOverviewData(startWeek, endWeek);
  }, [startWeek, endWeek]);

  const getOverviewData = (startWeek, endWeek) => {
    console.log("startWeek", startWeek?._d, "endWeek", endWeek?._d);
    setIsLoading(true);
    const formData = {
      user_id: authUser_id,
      startWeek: startWeek,
      endWeek: endWeek,
    };
    axios
      .post("/get-overview-data", formData)
      .then((res) => {
        console.log(res);
        setTodayShifts(res.data.today_shifts);
        const shift = res.data.today_shifts?.find(
          (shift) => shift.showEndShift == true
        );
        if (shift) {
          console.log("ongoing shift trigger")
          setOngoingShiftId(shift?.shift_id);
        }
        else {
          console.log("ongoing else")
        }

        setOverviewData(res.data);
        setfullname(res.data.workforce_profile_fullname);
        setuserimageurl(res.data.workforce_profile_picture);
        setshiftthisweek(res.data.shifts_worked_this_week);
        sethourthisweek(res.data.hours_worked_this_week);
        // setUpcomingShiftsCount(res.data.upcoming_shifts_this_week);
        setavailableshifts(res.data.available_shifts);
        settotalhoursworked(res.data.total_hours_worked);
        setassignedshifts(res.data.assigned_shifts);
        setopenedshift(res.data.open_shifts);
        setTotalShiftWeek(res.data.shifts_worked_this_week);
        setTotalHourWeek(res.data.total_hours_worked);
        setWorkerName(res.data.workforce_profile_fullname);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // const shift = overviewData?.assigned_shifts?.filter(
    //   (item) => item.shift_id == shiftId
    // );

    const shift = todayShifts?.find((shift) => shift.showEndShift == true);
    console.log(shift, "today ");
    setCurrentShift(shift);

    const updateLateTime = () => {
      const shift_start_time = shift?.length > 0 && shift[0]?.shift_start_time;
      const lateTime = shift_start_time
        ? calculateLateTime(shift_start_time)
        : null;
      setLateTime(lateTime);
    };

    updateLateTime();

    // Update every minute
    const interval = setInterval(updateLateTime, 60000); // 60000ms = 1 minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [overviewData, shiftId, todayShifts]);

  const calculateLateTime = (shiftStartTime) => {
    // Parse the shift start time to a Date object
    const now = new Date();
    const [hour, minute, second] = shiftStartTime.split(":").map(Number);

    // Create a new Date object for the shift start time with today's date
    const shiftStart = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute,
      second
    );

    // Calculate the difference in milliseconds
    const diff = now - shiftStart;

    // If the shift hasn't started yet, return null
    if (diff <= 0) {
      return null;
    }

    // Convert the difference to minutes and hours
    const diffInMinutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return { hours, minutes };
  };
  const formatTime = (timeString) => {
    const time = new Date(`2022-01-01T${timeString}`);
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Calculate duration
  useEffect(() => {
    const calculateDuration = () => {
      const currentTime = new Date().getTime();

      if (shiftStartTime) {
        setShiftDuration(currentTime - shiftStartTime);
      }

      if (breakStartTime) {
        setBreakDuration(currentTime - breakStartTime);
      }
    };

    const interval = setInterval(calculateDuration, 1000);

    if (shiftStartTime === null) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [shiftStartTime, breakStartTime]);

  const getBorderColor = (status) => {
    if (status === "Accepted") return "#339900";
    if (status === "Pending") return "#F7D4AA";
    if (status === "Declined") return "#ff0000"; // Red color for Declined
    return "#000"; // Default color if no match
  };

  const upcomingShiftsData = () => {
    //setIsLoading(true);
    setModalLoader(true);
    const payloadData = {
      user_id: authUser_id,
      currentDate: moment().format("YYYY-MM-DD"),
    };

    axios
      .post("/get-upcoming-shifts-data", payloadData)
      .then((res) => {
        setUpcomingShiftsCount(res.upcomingcount);
        setUpcomingShifts(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setModalLoader(false));
  };

  // const [modal_positionBottom, setmodal_positionBottom] = useState(true);
  // // function tog_positionBottom() {
  // //   setmodal_positionBottom(!modal_positionBottom);
  // // }

  const manageShiftAndBreak = useCallback(
    (action, shift_id) => {
      setIsLoading(true);
      const currentTime = new Date().getTime();
      console.log(shiftId, "Shift id");
      const payloadData = {
        user_id: authUser_id,
        currentDate: moment().format("YYYY-MM-DD"),
        shiftid: shift_id ? shift_id : currentShift?.shiftId,
      };

      switch (action) {
        case "start_shift":
          localStorage.setItem("shiftStartTime", currentTime.toString());
          payloadData.start_shift = true;
          setShiftStartTime(currentTime);
          setShiftEndTime(null);
          //setBreakStartTime(null); // Reset break timer
          setBreakEndTime(null);
          break;

        case "end_shift":
          payloadData.end_shift = true;
          if (shiftStartTime) {
            const elapsedShiftTime = currentTime - shiftStartTime;
            setShiftDuration(elapsedShiftTime);
            localStorage.removeItem("shiftStartTime");
            setShiftStartTime(null);
          }
          break;

        case "start_break":
          localStorage.setItem("breakStartTime", currentTime.toString());
          setBreakStartTime(currentTime);
          payloadData.start_break = true;
          break;

        case "end_break":
          payloadData.end_break = true;
          if (breakStartTime) {
            const elapsedBreakTime = currentTime - breakStartTime;
            setBreakDuration(elapsedBreakTime);
            localStorage.removeItem("breakStartTime");
            setBreakStartTime(null);
          }
          break;

        default:
          break;
      }

      setBreaks([]);

      axios
        .post("/manage-shift-and-break", payloadData)
        .then((res) => {
          setshowStartShift(res.showStartShift);
          setshowEndShift(res.showEndShift);
          setshowStartBreak(res.showStartBreak);
          setshowShiftEnded(res.showShiftEnded);
          setshowEndBreak(res.showEndBreak);
          setShiftId(res.shift_id);
          setShiftData(res.shift_data);
     
          if (
            !isShiftStarted &&
            res.alreadyStartedMessage === "Shift already started"
          ) {
            setIsShiftStarted(true);
            manageShiftAndBreak(null, res.shift_id);
            return;
          }
          if (
            !isBreakStarted &&
            res.breakInProgressMessage === "Break already in progress"
          ) {
            setIsBreakStarted(true);
            manageShiftAndBreak(null, res.shift_id);
            return;
          }
          if (
            !isBreakEnded &&
            res.breakInProgressMessage === "No ongoing break found"
          ) {
            setIsBreakEnded(true);
            manageShiftAndBreak(null, res.shift_id);
            return;
          }
          if (
            !isShiftEnded &&
            res.shiftAlreadyEndedMessage === "Shift already ended"
          ) {
            setIsShiftEnded(true);
            manageShiftAndBreak(null, res.shift_id);
            return;
          }
          if (res?.shift_data) {
            // Update shift start time
            const shiftStartTimeStr = res.shift_data.start_time
              ? res.shift_data.start_time * 1000
              : null;
            const shiftEndTimeStr = res.shift_data.end_time
              ? res.shift_data.end_time * 1000
              : null;

            console.log(shiftStartTimeStr, "shiftStartTime1");

            console.log(
              shiftStartTimeStr.toString() !==
                localStorage.getItem("shiftStartTime"),
              "shiftsSame"
            );
            console.log(
              localStorage.getItem("shiftStartTime"),
              "getShiftStartTime"
            );
            console.log(shiftStartTimeStr.toString(), "OgShiftStartTime");

            if (
              shiftStartTimeStr &&
              shiftStartTimeStr.toString() !==
                localStorage.getItem("shiftStartTime") &&
              shiftEndTimeStr == null
            ) {
              localStorage.setItem(
                "shiftStartTime",
                shiftStartTimeStr.toString()
              );
            }
            if (shiftStartTimeStr && shiftEndTimeStr == null) {
              setShiftStartTime(shiftStartTimeStr);
            }

            // Get the last entry in the shift_break_log array
            const lastBreak =
              res.shift_break_log[res.shift_break_log.length - 1];

            // Check if the break_end_time is null
            if (lastBreak) {
              const breakStartTimeStr = lastBreak?.break_start_time
                ? lastBreak?.break_start_time * 1000
                : null;
              const breakEndTimeStr = lastBreak?.break_end_time
                ? lastBreak?.break_end_time * 1000
                : null;

              console.log(breakStartTimeStr, "breakStartTimeStr");
              console.log(breakEndTimeStr, "breakEndTimeStr");

              const storedBreakStartTime =
                localStorage.getItem("breakStartTime");

              if (breakStartTimeStr && breakEndTimeStr == null) {
                setBreakStartTime(breakStartTimeStr);
              }
              if (
                breakStartTimeStr.toString() !== storedBreakStartTime &&
                lastBreak?.break_end_time === null
              ) {
                localStorage.setItem(
                  "breakStartTime",
                  breakStartTimeStr.toString()
                );
                setBreakStartTime(breakStartTimeStr);
              } else {
                setBreakEndTime(breakEndTimeStr);
              }
            } else {
              setBreakEndTime(null);
              localStorage.setItem("breakStartTime", ""); // Remove breakStartTime from cookies
            }
          }
          if (res?.shift_data === null) {
            localStorage.setItem("shiftStartTime", "");
          }

          if (res.showShiftEnded) {
            // setShiftData(res.shift_data);
            if (res.shift_break_log && res.shift_break_log.length > 0) {
              res.shift_break_log.forEach((data) => {
                const newBreak = {
                  start: data.break_start_time,
                  finish: data.break_end_time,
                };
                setBreaks((prevBreaks) => [...prevBreaks, newBreak]);
              });
            } else {
              const newBreak = { break_type: breakType, duration: duration };
              setBreaks((prevBreaks) => [...prevBreaks, newBreak]);
            }

            if (action === "end_shift") {
              toggleEditModal();
            } else {
              setIsEditModalOpen(false);
            }
          }
          if (action == "end_shift") {
            setIsShiftOngoing(false);
          }
          getOverviewData(startWeek, endWeek);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [shiftStartTime, breakStartTime]
  );
  const AvailableShiftsData = () => {
    //setIsLoading(true);
    setModalLoader(true);
    const payloadData = {
      user_id: authUser_id,
      currentDate: moment().format("YYYY-MM-DD"),
    };

    axios
      .post("/get-available-shifts-data", payloadData)
      .then((res) => {
        setavailableshiftdetails(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setModalLoader(false));
  };

  const getWeekDays = (date) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date.setDate(date.getDate() - date.getDay() + i));
      days.push(day);
    }
    return days;
  };

  const handlePreviousWeek = () => {
    const newDate = new Date(currentDate);
    console.log(newDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };
  const weekStartDate = moment(currentDate).startOf("week").format("MMM DD");
  const weekEndDate = moment(currentDate).endOf("week").format("MMM DD");

  const days = getWeekDays(currentDate);

  const tog_availableModal = () => {
    setavailable_Modal(!available_Modal);
  };

  const tog_upcomingModal = () => {
    setUpcoming_Modal(!upcoming_Modal);
  };

  const toggleModal = (shiftData) => {
    setIsOpenModal(!isOpenModal); // Toggle modal state
    setSelectedOpenShift(shiftData); // Set selected shift data
  };

  const handleCardClick = (shift) => {
    setShowModal(!showModal); // Toggle modal state
    setSelectedShift(shift); // Set selected shift data
  };

  const toggleBreakDetails = (index) => {
    setTogBreakDetails(!showBreakDetails);
    setSelectedBreak(index);
  };

  const confirmCancelShift = (shift) => {
    setShiftToCancel(shift);
    setShowConfirmationModal(true);
  };

  const cancelshift = (shift) => {
    console.log(shift, "shift");
    let publish = "";
    let auto_publish = shift?.auto_publish_open_shift_on_worker_cancel;

    if (auto_publish === "Yes") {
      publish = "Y";
    } else {
      publish = "N";
    }

    let formData = {
      shift_id: shift.shift_id,
      Workforce_id: null,
      shift_acceptance_status: null,
      published: publish,
      shift_status: shift.cancellation_policy,
    };

    axios
      .post("/cancel-shift", formData)
      .then((response) => {
        if (response.status == "success") {
          toast.success(response.message, { theme: "light" });
          handleCardClick(false);
          setShowConfirmationModal(false);
          getOverviewData(startWeek, endWeek);
        } else {
          toast.error(response.message);
          handleCardClick(true);
          setShowConfirmationModal(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };

  const claimShift = (id) => {
    var formData = {
      user_id: authUser_id,
      shift_id: id,
    };
    axios
      .post("/claim-shift-button", formData)
      .then((response) => {
        if (response.success === 1) {
          toast.success(response.message, { theme: "light" });
          tog_availableModal(false);
          AvailableShiftsData(authUser_id);
          getOverviewData(startWeek, endWeek);
        } 
        else {
          // Display error message if IP setup is required
          if (response.error_code === "IP_SETUP_REQUIRED") {
            toast.error("This is an Instant Payout shift. To claim, please complete the Instant Payout setup process.");
          } else {
            toast.error(response.message);
          }
          tog_availableModal(true);
        }
        // else {
        //   toast.error(response.message);
        //   tog_availableModal(true);
        // }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  const claimOpenShift = (id) => {
    console.log(id, "dddddddddddd");
    //setIsLoading(true);
    setModalLoader(true);
    var formData = {
      user_id: authUser_id,
      shift_id: id,
    };
    axios
      .post("/claim-shift-button", formData)
      .then((response) => {
        if (response.success === 1) {
          toast.success(response.message, { theme: "light" });
          setIsOpenModal(!isOpenModal);
          AvailableShiftsData();
          getOverviewData(startWeek, endWeek);
        } 
        else {
          // Display error message if IP setup is required
          if (response.error_code === "IP_SETUP_REQUIRED") {
            toast.error("This is an Instant Payout shift. To claim, please complete the Instant Payout setup process.");
          } else {
            toast.error(response.message);
          }
          setIsOpenModal(!isOpenModal);
        }
        // else {
        //   toast.error(response.message);
        //   setIsOpenModal(!isOpenModal);
        // }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setModalLoader(false);
      });
  };

  const handleDateChange = (selectedDate) => {
    setCurrentDate(new Date(selectedDate));
    const dateOnlySelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    setSelectedDate(dateOnlySelectedDate);
  };

  useEffect(() => {
    const calculateCancelEnableState = () => {
      if (selectedShift) {
        const currentTime = moment();
        const shiftStartTime = moment(
          `${selectedShift.shift_date} ${selectedShift.shift_start_time}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const cancelShiftNoticeHours = selectedShift.cancel_shift_notice_hours;

        const isWithinNoticePeriod =
          currentTime.isBefore(shiftStartTime) &&
          shiftStartTime.diff(currentTime, "hours") > cancelShiftNoticeHours;
        // const isCancellationPolicyOpen = selectedShift.cancellation_policy === 'Open';
        // const isAutoPublishOpenShiftOnWorkerCancel = selectedShift.auto_publish_open_shift_on_worker_cancel === 'Yes';
        const isShiftAccepted =
          selectedShift.shift_acceptance_status === "Accepted";

        return isWithinNoticePeriod && isShiftAccepted;
      }
      return false;
    };

    setIsCancelEnabled(calculateCancelEnableState());
  }, [selectedShift]);

  const renderDayCard = (day) => {
    // Check if there are any shifts for the current day
    const shiftsForDay = assignedshifts?.filter((shift) =>
      moment(shift.shift_date).isSame(day, "day")
    );

    // If there are no shifts for the day, show an "Unscheduled" card
    if (!shiftsForDay || shiftsForDay.length === 0) {
      return (
        <div className="w-100" key={`unscheduled-${day}`}>
          <div className="fs-12 badge badge-sm badge-soft-secondary pt-0 mb-3 pb-0 text mb-2">
            {moment(day).format("ddd DD MMM")}
          </div>
          <Card
            className="overview-day-card mb-3 border card-animate cursor-pointer shadow-sm text-center p-2 justify-content-center border-primary"
            style={{
              height: "180px",
              backgroundColor: "#fef1f6",
              transition: "all 0.3s",
              boxShadow: "0 1px 3px rgb(255 169 226)",
            }}
          >
            <span
              style={{ fontSize: "13px" }}
              className="badge badge-outline-primary align-self-center text-primary"
            >
              Unscheduled
            </span>
          </Card>
        </div>
      );
    }

    // If there are shifts for the day, return a card for each shift
    return shiftsForDay.map((shiftForDay, index) => (
      <div className="w-100" key={index}>
        <div className="fs-12 badge badge-sm badge-soft-secondary pt-0 mb-3 pb-0 text mb-2">
          {moment(day).format("ddd DD MMM")}
        </div>
        <Card
          className="overview-day-card mb-3 border card-animate cursor-pointer shadow-sm text-center p-2 border-primary"
          style={{
            height: "180px",
            transition: "all 0.3s",
            backgroundColor: "#fef1f6",
            boxShadow: "0 1px 3px rgb(255 169 226)",
          }}
          onClick={() => handleCardClick(shiftForDay)}
        >
          <div>
            <div
              className="hstack gap-2"
              style={{ fontSize: "14px", color: "deeppink" }}
            >
              <div className="shift-clock-icon border-primary">
                <i className="bx bxs-time"></i>
              </div>
              {shiftForDay.total_shift_hours || "N/A"} Hrs
            </div>
            <h6 className="fs-12 mt-3" style={{ color: "deeppink" }}>
              {shiftForDay.business_name}
            </h6>

            <div className="mt-2" style={{ fontSize: "12px" }}>
              {moment(shiftForDay.shift_start_time, "HH:mm:ss").format("h:mmA")}{" "}
              - {moment(shiftForDay.shift_end_time, "HH:mm:ss").format("h:mmA")}
            </div>

            <div className="mt-2">{shiftForDay.site_name}</div>

            <div
              className="mt-3"
              style={{ fontSize: "13px", color: "#FFC000" }}
            >
              {shiftForDay.shift_acceptance_status === "Pending"
                ? shiftForDay.shift_acceptance_status + " Acceptance"
                : shiftForDay.shift_acceptance_status}
            </div>
          </div>
        </Card>
      </div>
    ));
  };

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const renderopencard = (day) => {
    // Filter shifts for the current day
    const shiftsForDay = openedshift.filter((shift) =>
      moment(shift.shift_date).isSame(day, "day")
    );

    // If there are no shifts for the current day, return an empty "hidden" card
    if (shiftsForDay.length === 0) {
      return (
        <div className="w-100 text-center">
          <div className="fs-12 badge badge-sm badge-soft-secondary pt-0 mb-3 pb-0 text mb-2">
            {moment(day).format("ddd DD MMM")}
          </div>
          <Card
            className="borderd shadow cursor-pointer text-center p-3 align-items-center d-flex justify-content-center"
            style={{
              height: "180px",
              width: "148px",
              transition: "all 0.3s",
              visibility: "hidden",
            }}
          >
            <h6
              style={{ fontSize: "13px" }}
              className="badge badge-soft-danger"
            >
              Unscheduled
            </h6>
          </Card>
        </div>
      );
    }

    // If there are shifts for the current day, render each shift in its own card
    return (
      <>
        {shiftsForDay.map((shiftForDay, index) => (
          <div key={index} className="w-100 mb-3 text-center">
            <div className="fs-12 badge badge-sm badge-soft-secondary pt-0 mb-3 pb-0 text mb-2">
              {moment(day).format("ddd DD MMM")}
            </div>
            <Card
              className="overview-open-card shadow cursor-pointer text-center p-3 align-items-center d-flex justify-content-center"
              style={{
                height: "180px",
                width: "148px",
                border: "2px dashed #e1e0e0",
              }}
              onClick={() => toggleModal(shiftForDay)}
            >
              <div>
                <p
                  style={{ fontSize: "13px" }}
                  className="badge rounded-pill badge-soft-info mt-2"
                >
                  {shiftForDay.shift_status}
                </p>
                <p style={{ fontSize: "12px", margin: "auto" }}>
                  {moment(shiftForDay.shift_start_time, "HH:mm:ss").format(
                    "hA"
                  )}{" "}
                  -{" "}
                  {moment(shiftForDay.shift_end_time, "HH:mm:ss").format("hA")}
                </p>
                <p
                  className="mt-2"
                  style={{
                    color: "rgb(112, 55, 214)",
                    textDecoration: "underline",
                  }}
                >
                  {shiftForDay.site_name} at
                </p>
                <div className="hstack mt-2" style={{ width: "130px" }}>
                  <p
                    style={{
                      width: "130px",
                      overflow: "none",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <i
                      style={{ color: "red" }}
                      className="ri-map-pin-line me-1"
                    ></i>
                    <span>{shiftForDay.business_name}</span>
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </>
    );
  };

  // Convert Unix timestamps to JavaScript Date objects
  const startTime = new Date(shiftData?.start_time * 1000);
  let endTime = new Date(shiftData?.end_time * 1000);

  // If end time is before start time, add a day to end time
  if (endTime < startTime) {
    endTime = moment(endTime).add(1, "day").toDate();
  }

  // Calculate duration
  const durations = moment.duration(moment(endTime).diff(moment(startTime)));

  // Get hours and minutes from the duration
  const hours = Math.floor(durations.asHours()); // Round down to get whole hours
  const minutes = durations.minutes(); // Get remaining minutes

  const item = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Earnings",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: "+16.24",
      counter: "559.25",
      link: "View net earnings",
      bgcolor: "success",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Overview" pageTitle="Overview" />

          <Row>
            <Col lg={4}>
              <Card className="mb-1">
                <CardBody>
                  <div className="text-start d-flex gap-3 align-items-center">
                    <div className="avatar-lg">
                      {isLoading ? (
                        <img
                          src={userDummy}
                          alt="user-img"
                          className="img-thumbnail rounded-circle"
                        />
                      ) : userimageurl ? (
                        <img
                          src={userimageurl}
                          alt="user-img"
                          className="img-thumbnail h-100 w-100 rounded-circle"
                        />
                      ) : (
                        <img
                          src={userDummy}
                          alt="user-img"
                          className="img-thumbnail rounded-circle"
                        />
                      )}
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <h4 className="text-start text-primary">
                        Welcome back!{" "}
                      </h4>
                      <h5>{workerName && workerName !== null && workerName}</h5>

                      <div className="text-start today-shift">
                        {currentShift && (
                          <>
                            {lateTime && currentShift?.showStartShift ? (
                              <div className="shift-status badge p-2 bg-soft-dark text-dark late">
                                <span>
                                  Late by {lateTime.hours}h {lateTime.minutes}m
                                </span>
                              </div>
                            ) : currentShift?.showEndShift &&
                              currentShift?.showEndBreak ? (
                              <div className="shift-status  in-break badge p-2 bg-soft-warning text-warning">
                                <span>In Break</span>
                              </div>
                            ) : currentShift?.showShiftEnded ? (
                              <div className="shift-status completed badge p-2 bg-soft-primary text-primary">
                                <span>Shift Completed</span>
                              </div>
                            ) : (
                              currentShift?.showEndShift && (
                                <div className="shift-status  in-progress badge p-2 bg-soft-primary text-primary">
                                  <span>Shift in Progress</span>
                                </div>
                              )
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {isShiftOngoing ? (
              <Col lg={4}>
                {currentShift.showStartShift ? (
                  <Button
                    className="btn btn-start-shift text-center"
                    onClick={() =>
                      manageShiftAndBreak("start_shift", currentShift.shift_id)
                    }
                  >
                    Start shift
                  </Button>
                ) : (
                  <div className="hstack    justify-content-start gap-3">
                    {currentShift.showStartBreak ? (
                      <button
                        className="btn  btn-primary"
                        onClick={() =>
                          manageShiftAndBreak(
                            "start_break",
                            currentShift.shift_id
                          )
                        }
                      >
                        Start Break
                      </button>
                    ) : currentShift.showEndBreak ? (
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          manageShiftAndBreak(
                            "end_break",
                            currentShift.shift_id
                          )
                        }
                      >
                        End Break
                      </button>
                    ) : null}

                    {currentShift.showEndShift ? (
                      <Button
                        className="btn btn-end-shift "
                        onClick={() => {
                          manageShiftAndBreak(
                            "end_shift",
                            currentShift.shift_id
                          );
                        }}
                      >
                        End Shift
                      </Button>
                    ) : (
                      currentShift.showShiftEnded && null
                    )}
                  </div>
                )}
                <div className="mt-3 text-center">
                  {shiftStartTime ? (
                    // <div>
                    //   Shift Duration:{" "}
                    //   {new Date(shiftDuration).toISOString().substr(11, 8)}
                    // </div>
                    <div
                      className="alert-border-left p-1 mb-2 w-60 alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <i className=" ri-time-line me-1 align-middle fs-16"></i>
                      <strong> Shift Duration - </strong>
                      {new Date(shiftDuration).toISOString().substr(11, 8)}
                    </div>
                  ) : (
                    ""
                  )}
                  {console.log(
                    breakStartTime,
                    shiftStartTime,
                    " break start time"
                  )}
                  {breakStartTime ? (
                    // <div>
                    //   Break Duration:{" "}
                    //   {new Date(breakDuration).toISOString().substr(11, 8)}
                    // </div>
                    <div
                      className="alert-border-left p-1 mb-0 w-60 alert alert-warning alert-dismissible fade show"
                      role="alert"
                    >
                      <i className=" ri-time-line me-1 align-middle fs-16"></i>
                      <strong> Break Duration - </strong>
                      {new Date(breakDuration).toISOString().substr(11, 8)}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            ) : (
              <Col
                lg={4}
                className="overview-scrollbar"
                style={{ overflowY: "scroll", height: "135px" }}
              >
                {todayShifts?.map((shift, index) => (
                  <Card key={index} className="mb-2">
                    <CardBody className="p-2">
                      <div className="mb-0 d-flex justify-content-between align-items-center">
                        <div className="shift-details">
                          <h6 className="">
                            {shift.site_name} - {shift.zone_name}
                          </h6>
                          <div className="hstack gap-2 align-items-center">
                            <p className="mb-0 text-muted">
                              <i className="bx bx-buildings"></i>{" "}
                              {shift.business_name}
                            </p>
                            <p className="mb-0 badge badge-soft-primary">
                              <i className="bx bx-time-five"></i>{" "}
                              {moment(
                                shift.shift_start_time,
                                "HH:mm:ss"
                              ).format("hA")}{" "}
                              -{" "}
                              {moment(shift.shift_end_time, "HH:mm:ss").format(
                                "hA"
                              )}
                            </p>
                          </div>
                        </div>

                        {shift.showStartShift ? (
                          <Button
                            className="btn btn-start-shift text-center"
                            onClick={() => {
                              setShiftId(shift.shift_id);
                              console.log("shift id", shift.shift_id);
                              manageShiftAndBreak(
                                "start_shift",
                                shift.shift_id
                              );
                            }} // Pass the shift ID to manageShiftAndBreak
                          >
                            Start shift
                          </Button>
                        ) : (
                          <span className="badge badge-soft-info">
                            {shift.shift_status}
                          </span>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </Col>
            )}

            <Col xl={2} md={6}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className=" overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        You did
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h4 className="fs-20 fw-semibold ff-secondary ">
                      <span
                        className="counter-value text-primary"
                        data-target="559.25"
                      >
                        <CountUp
                          start={0}
                          end={totalShiftWeek !== null ? totalShiftWeek : 0}
                          duration={4}
                        />
                      </span>
                      <span className="fs-16"> shift(s)</span>
                    </h4>
                    <Link
                      to="#"
                      className="text-decoration-underline text-muted"
                    >
                      {item.link}
                    </Link>

                    <div className="avatar-xs flex-shrink-0">
                      <span
                        className={"avatar-title rounded fs-3 bg-soft-primary"}
                      >
                        <i className={`text-primary  ri-briefcase-4-line`}></i>
                      </span>
                    </div>
                  </div>
                  <div className="text-start overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      this week
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2} md={6}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className=" overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        You worked for
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h4 className="fs-20 fw-semibold ff-secondary ">
                      <span
                        className="counter-value  text-primary"
                        data-target="59.25"
                      >
                        <CountUp
                          start={0}
                          end={totalHourWeek !== null ? totalHourWeek : 0}
                          decimals={2}
                          duration={4}
                        />
                      </span>
                      <span className="fs-16"> hrs</span>
                    </h4>
                    <Link
                      to="#"
                      className="text-decoration-underline text-muted"
                    >
                      {item.link}
                    </Link> 

                    <div className="avatar-xs flex-shrink-0">
                      <span
                        className={
                          "avatar-title rounded fs-3 bg-soft-secondary"
                        }
                      >
                        <i
                          className={`text-secondary  ri-briefcase-4-line`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div className="text-start overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      this week
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <h5 className="text-center">Today's Shift</h5>
            <Card className=" border-25 mt-3">
              <div className="">
                {console.log(todayShift, shiftId, "today shift ")}
                {todayShift?.length > 0 &&
                  todayShift.map((shift, index) => {
                    return (
                      <div
                        key={index}
                        className="  p-3"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/activity", {
                            state: {
                              NotificationTriggerId: shift.shift_id,
                            }, // Correctly wrap state
                          })
                        }
                      >
                        <div className="today-shift">
                          <h6
                            className="shift-site mb-1"
                            title={shift?.site_name}
                          >
                            {shift?.site_name}
                          </h6>

                          <span className="shift-time">
                            {formatTime(shift?.shift_start_time)} -{" "}
                            {formatTime(shift?.shift_end_time)}
                          </span>
                          <div className="today-shift">
                            {lateTime && showStartShift ? (
                              <div className="shift-status badge bg-dark late">
                                <span>
                                  Late by {lateTime.hours}h {lateTime.minutes}m
                                </span>
                              </div>
                            ) : showEndShift && showEndBreak ? (
                              <div className="shift-status in-break badge bg-warning">
                                <span>In Break</span>
                              </div>
                            ) : showShiftEnded ? (
                              <div className="shift-status completed badge bg-primary">
                                <span>Shift Completed</span>
                              </div>
                            ) : (
                              todayShift?.length > 0 &&
                              showEndShift && (
                                <div className="shift-status in-progress badge bg-success">
                                  <span>In Progress</span>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        {shift?.shift_acceptance_status === "Pending" && (
                          <div
                            className="status-badge"
                            style={{
                              backgroundColor: getBorderColor(
                                shift.shift_acceptance_status
                              ),
                            }}
                          >
                            <span className="status-text">
                              {shift.shift_acceptance_status} acceptance
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {todayShift?.length === 0 && (
                  <div className=" text-center p-3 no-shift">
                    <span>No shift has been assigned today</span>
                  </div>
                )}
              </div>
            </Card> */}
          <Row>
            <Col md="12">
              <div className="ps-0 p-3 align-items-start pb-0">
                <div className="week-view-calendar overflow-hidden w-100">
                  <div
                    className="navigation mb-4 d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <button
                        className="btn btn-primary p-2"
                        onClick={handlePreviousWeek}
                      >
                        <i className="ri-arrow-left-s-line align-bottom fw-bold"></i>
                      </button>
                      <span
                        className="btn btn-primary ms-2"
                        id="overviewCalendar"
                      >
                        {weekStartDate} - {weekEndDate}
                        <Popover
                          placement="bottom"
                          isOpen={popoverOpen}
                          target="overviewCalendar"
                          toggle={togglePopover}
                          trigger="legacy"
                        ></Popover>
                        <UncontrolledPopover
                          placement="bottom"
                          target="overviewCalendar"
                          trigger="legacy"
                        >
                          <PopoverBody>
                            <Flatpickr
                              name="unavailabilityDates"
                              className="form-control"
                              id="datepicker-overview-input"
                              placeholder="Select date(s)"
                              options={{
                                mode: "single",
                                enableTime: false,
                                dateFormat: "D M d Y H:i:s \\G\\M\\TO (T)",
                                // dateFormat: "d/m/Y", // Set the date format to dd/mm/yyyy
                                // defaultDate: "2024-02-21",
                                inline: true, // Use inline mode
                                // onChange: handleDateChange,
                              }}
                              onChange={handleDateChange}
                              value={selectedDate || ""}
                            />
                          </PopoverBody>
                        </UncontrolledPopover>
                      </span>
                      <button
                        className="btn btn-primary p-2 me-2 ms-2"
                        onClick={handleNextWeek}
                      >
                        <i className="ri-arrow-right-s-line align-bottom fw-bold"></i>
                      </button>
                      <button
                        className="btn btn-primary ms-3 me-2"
                        onClick={() => {
                          upcomingShiftsData();
                          tog_upcomingModal();
                        }}
                      >
                        <div className="hstack">
                          <i
                            className="ri-calendar-2-line"
                            style={{ marginRight: "5px" }}
                          ></i>
                          Upcoming shift
                        </div>
                      </button>

                      <button
                        className="btn btn-primary p-2 ms-3"
                        onClick={() => {
                          AvailableShiftsData();
                          tog_availableModal();
                        }}
                      >
                        <i className="ri-calendar-event-line align-bottom fw-bold pe-2"></i>
                        Available Shifts
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-secondary p-2 ms-3"
                        onClick={() => getOverviewData(startWeek, endWeek)}
                      >
                        <i className="bx bx-refresh align-inherit fs-16"></i>
                      </button>
                    </div>
                  </div>
                  {/* <p className="mt-4">weekly hours:{totalhours}</p> */}
                </div>
              </div>

              <Card className="pb-2">
                <h5 className="text-center mt-3 mb-0 "> Assigned Shifts</h5>
                <hr className="calendar-hr"></hr>

                <CardBody
                  className="pt-0 pb-0 overview-scrollbar me-1 "
                  style={{ overflowY: "scroll", height: "460px" }}
                >
                  <div className="p-0 day-cards text-center p-3 align-items-start list-unstyled gap-3">
                    {/* Render regular day cards in one row */}
                    <div className="d-flex flex-wrap justify-content-between">
                      {days.map((day, index) => (
                        <div
                          key={index}
                          style={{
                            marginRight: "8px",
                            marginBottom: "8px",
                            width: "13%",
                          }}
                        >
                          {renderDayCard(day)}{" "}
                          {/* Render multiple cards per day if needed */}
                        </div>
                      ))}
                    </div>
                  </div>

                  <Modal
                    size="md"
                    isOpen={showModal}
                    toggle={() => setShowModal(!showModal)}
                    centered
                    scrollable
                    className="custom-modal"
                  >
                    <ModalHeader
                      toggle={() => setShowModal(!showModal)}
                      className="fw-semibold"
                    >
                      {selectedShift && selectedShift.shift_acceptance_status}{" "}
                      Shift
                    </ModalHeader>
                    <ModalBody className="px-4 py-2">
                      {selectedShift && selectedShift.length < 0 ? (
                        <div className="p-5 ps-0">
                          You have no available shifts to Cancel this Week.
                        </div>
                      ) : (
                        selectedShift && (
                          <>
                            <div className="m-1 hstack justify-content-start gap-2">
                              <Col md={2} className=" align-self-start mt-3">
                                <h6 className="m-0 mb-1 text-center">
                                  <span className="text-muted fw-normal mt-2">
                                    {moment(selectedShift.shift_date).format(
                                      "ddd"
                                    )}
                                  </span>{" "}
                                  <br />
                                  {moment(selectedShift.shift_date).format(
                                    "D MMM"
                                  )}
                                </h6>
                              </Col>
                              <Col md={8} className=" fw-normal">
                                <div
                                  className="border p-3 mb-2"
                                  style={{ borderRadius: "15px" }}
                                >
                                  <p className="m-0 fw-semibold">
                                    {moment(
                                      selectedShift.shift_start_time,
                                      "HH:mm:ss"
                                    ).format("h:mm A")}{" "}
                                    -{" "}
                                    {moment(
                                      selectedShift.shift_end_time,
                                      "HH:mm:ss"
                                    ).format("h:mm A")}{" "}
                                  </p>
                                  <p className="m-0">
                                    {selectedShift.meal_breaks}x Meal Break{" "}
                                    {selectedShift.is_paid == 1
                                      ? "(paid)"
                                      : "(unpaid)"}
                                  </p>
                                  <p className="m-0">
                                    at {selectedShift.zone_name}
                                  </p>
                                  <p className="m-0">
                                    <i className="mdi mdi-store-marker-outline me-2"></i>
                                    {selectedShift.business_name}
                                  </p>
                                </div>
                              </Col>
                            </div>
                            <div className="d-flex justify-content-center">
                              <Row className="col-md-8 ms-1">
                                <Button
                                  className="btn btn-primary mt-2 p-1"
                                  style={{
                                    borderRadius: "10px",
                                    border: "none",
                                  }}
                                  onClick={() =>
                                    confirmCancelShift(selectedShift)
                                  }
                                  disabled={!isCancelEnabled}
                                >
                                  Cancel Shift
                                </Button>
                              </Row>
                            </div>
                          </>
                        )
                      )}
                    </ModalBody>
                  </Modal>

                  <Modal
                    size="md"
                    isOpen={showConfirmationModal}
                    toggle={() =>
                      setShowConfirmationModal(!showConfirmationModal)
                    }
                    centered
                    scrollable
                    className="custom-modal"
                  >
                    <ModalHeader
                      toggle={() =>
                        setShowConfirmationModal(!showConfirmationModal)
                      }
                      className="fw-semibold"
                    >
                      Confirm Cancellation
                    </ModalHeader>
                    <ModalBody className="px-4 py-2">
                      <div className="text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/gsqxdxog.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#f06548"
                          style={{ width: "40px", height: "40px" }}
                        ></lord-icon>
                        <div className="mt-3 fs-15 mx-3 mx-sm-4">
                          <h4>Are you sure?</h4>
                          <p className="text-muted mx-3 mb-0">
                            Are you sure you want to cancel this shift?
                          </p>
                        </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2 ">
                        <button
                          className="btn w-sm btn-danger me-5"
                          style={{
                            borderRadius: "10px",
                            border: "none",
                          }}
                          onClick={() => cancelshift(shiftToCancel)}
                        >
                          Cancel Shift
                        </button>
                        <button
                          className="btn w-sm btn-light"
                          style={{
                            borderRadius: "10px",
                            border: "none",
                          }}
                          onClick={() => setShowConfirmationModal(false)}
                        >
                          Close
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
              <h5 className="text-center">Open Shifts</h5>
              <Card className="">
                <CardBody>
                  {/* Render open shift cards in a row below */}
                  <div className="d-flex flex-wrap">
                    {days.map((day, index) => (
                      <div
                        key={index}
                        style={{
                          marginRight: "8px",
                          marginBottom: "8px",
                          width: "13%",
                        }}
                      >
                        {renderopencard(day)}{" "}
                        {/* Render multiple cards for open shifts */}
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
          {/* {isModalLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        //background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )} */}

          {/* Available Modal */}
          <Modal
            size="md"
            isOpen={available_Modal}
            toggle={() => {
              tog_availableModal();
            }}
            centered
            scrollable
          >
            <ModalHeader
              toggle={() => {
                tog_availableModal();
              }}
              className="text-left"
            >
              Available Shifts
            </ModalHeader>
            <ModalBody className="px-4 py-2">
              {availableshiftdetails?.length <= 0 && (
                <>
                  <div className="p-5 ps-0">
                    You have no available shifts to claim in this week.
                  </div>
                </>
              )}
              {availableshiftdetails?.map((res, index) => {
                return (
                  <>
                    <h6 className="mb-2">Open Shifts</h6>
                    <Card className="">
                      <Row className="m-1">
                        <Col md={2}>
                          <div className="avatar-md rounded-circle">
                            <img
                              src={clockImg}
                              alt="user-img"
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </Col>
                        <Col md={8} className="ms-4">
                          <h6 className="m-0 mb-1">
                            {moment(res.shift_date).format("dddd, Do MMMM")}
                          </h6>
                          <p className="m-0">
                            {moment(res.shift_start_time, "HH:mm:ss").format(
                              "h:mm A"
                            )}{" "}
                            -{" "}
                            {moment(res.shift_end_time, "HH:mm:ss").format(
                              "h:mm A"
                            )}{" "}
                            at {res.site_name}
                          </p>
                          <p className="m-0">{res.business_name}</p>
                          <p
                            className="m-0"
                            onClick={() => toggleBreakDetails(index)}
                          >
                            Break details{" "}
                            <i className="ri-arrow-down-s-fill"></i>
                          </p>
                          {showBreakDetails &&
                            index == selectedBreak &&
                            res.shift_breaks.map((data) => {
                              return (
                                <p key={data.id}>
                                  {data.break_type}(unpaid): 30 mins
                                </p>
                              );
                            })}
                        </Col>
                        <Button
                          className="btn btn-primary mt-2 p-1"
                          style={{ borderRadius: "8px" }}
                          onClick={() => claimShift(res.id)}
                        >
                          Claim Shift
                        </Button>
                      </Row>
                    </Card>
                  </>
                );
              })}
            </ModalBody>
          </Modal>
          {/* Upcoming Modal */}
          <Modal
            size="md"
            isOpen={upcoming_Modal}
            toggle={() => {
              tog_upcomingModal();
            }}
            centered
            scrollable
          >
            <ModalHeader
              toggle={() => {
                tog_upcomingModal();
              }}
              className="fw-semibold"
            >
              {upcomingShiftsCount && upcomingShiftsCount} Upcoming Shifts
            </ModalHeader>
            <ModalBody className="px-0 ms-2 py-2 vstack">
              {upcomingShifts?.length <= 0 && (
                <>
                  <div className="p-5 ps-0">
                    You have no upcoming shifts in this week.
                  </div>
                </>
              )}
              {upcomingShifts?.map((res) => {
                return (
                  <>
                    {/* <h6 className="mb-2">Upcoming Shifts</h6> */}
                    <div className="m-1 hstack justify-content-start gap-2">
                      <Col md={2} className=" align-self-start mt-3">
                        <h6 className="m-0 mb-1 text-center">
                          <span className="text-muted fw-normal mt-2">
                            {moment(res.shift_date).format("ddd")}
                          </span>{" "}
                          <br />
                          {moment(res.shift_date).format("D MMM")}
                        </h6>
                      </Col>
                      <Col md={8} className=" fw-normal">
                        <div
                          className="border p-3 mb-2"
                          style={{ borderRadius: "15px" }}
                        >
                          <p className="m-0 fw-semibold">
                            {moment(res.shift_start_time, "HH:mm:ss").format(
                              "h:mm A"
                            )}{" "}
                            -{" "}
                            {moment(res.shift_end_time, "HH:mm:ss").format(
                              "h:mm A"
                            )}{" "}
                          </p>
                          <p className="m-0">
                            {res.meal_breaks}x Meal Break{" "}
                            {res.is_paid == 1 ? "(paid)" : "(unpaid)"}
                          </p>
                          <p className="m-0">at {res.zone_name}</p>
                          <p className="m-0">
                            <i className="mdi mdi-store-marker-outline me-2"></i>
                            {res.business_name}
                          </p>
                        </div>
                      </Col>
                    </div>
                  </>
                );
              })}
            </ModalBody>
            {/* <hr className="mt-0" style={{ borderColor: "gray" }} />
            <ModalFooter className="pt-0">
              <Button
                className="btn btn-soft-primary mt-0 me-4 align-self-end  px-3 py-2"
                onClick={() => tog_upcomingModal()}
              >
                Done
              </Button>
            </ModalFooter> */}
          </Modal>
          {/* Open Shifts Modal */}
          <Modal
            size="md"
            isOpen={isOpenModal}
            toggle={() => {
              setIsOpenModal(!isOpenModal);
            }}
            centered
            scrollable
          >
            <ModalHeader
              toggle={() => {
                setIsOpenModal(!isOpenModal);
              }}
              className="fw-semibold"
            >
              Open Shift
            </ModalHeader>
            <ModalBody className="px-0 ms-2 py-2 vstack">
              {selectedOpenShift && (
                <>
                  {/* <h6 className="mb-2">Upcoming Shifts</h6> */}
                  <div className="m-1 hstack justify-content-start gap-2">
                    <Col md={2} className=" align-self-start mt-3">
                      <h6 className="m-0 mb-1 text-center">
                        <span className="text-muted fw-normal mt-2">
                          {moment(selectedOpenShift.shift_date).format("ddd")}
                        </span>{" "}
                        <br />
                        {moment(selectedOpenShift.shift_date).format("D MMM")}
                      </h6>
                    </Col>
                    <Col md={8} className=" fw-normal">
                      <div
                        className="border p-3 mb-2"
                        style={{ borderRadius: "15px" }}
                      >
                        <p className="m-0 fw-semibold">
                          {moment(
                            selectedOpenShift.shift_start_time,
                            "HH:mm:ss"
                          ).format("h:mm A")}{" "}
                          -{" "}
                          {moment(
                            selectedOpenShift.shift_end_time,
                            "HH:mm:ss"
                          ).format("h:mm A")}{" "}
                        </p>
                        <p className="m-0">
                          {selectedOpenShift?.meal_breaks}x Meal Break{" "}
                          {selectedOpenShift?.is_paid == 1
                            ? "(paid)"
                            : "(unpaid)"}
                        </p>
                        <p className="m-0">at {selectedOpenShift.zone_name}</p>
                        <p className="m-0">
                          <i className="mdi mdi-store-marker-outline me-2"></i>
                          {selectedOpenShift.site_name}
                        </p>
                      </div>
                      <Button
                        className="btn btn-primary mt-2 px-3 py-2 w-100 border rounded-4"
                        // style={{ borderRadius: "10px" }}
                        onClick={() =>
                          claimOpenShift(selectedOpenShift.shift_id)
                        }
                      >
                        Claim Shift
                      </Button>
                    </Col>
                  </div>
                </>
              )}
            </ModalBody>
            {/* <hr className="mt-0" style={{ borderColor: "gray" }} />
            <ModalFooter className="pt-0">
              <Button
                className="btn btn-soft-primary mt-0 me-4 align-self-end  px-3 py-2"
                onClick={() => setIsOpenModal(!isOpenModal)}
              >
                Done
              </Button>
            </ModalFooter> */}
          </Modal>

          {/* -----------------End Shift modal-------------------- */}
          <Modal
            className="zoomIn"
            id="editShift"
            size="lg"
            isOpen={isEditModalOpen}
            // toggle={() => {
            //   toggleEditModal();
            // }}
            centered
          >
            <ModalHeader
              // toggle={() => {
              //   setIsEditModalOpen(false);
              // }}
              className="fw-semibold"
            >
              End Shift
            </ModalHeader>
            <ModalBody>
              <Row>
                <hr></hr>
                <Row>
                  <Col
                    md={5}
                    style={{
                      display: "flex",
                     
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div className="mt-0">
                      {/* <p className="hstack mb-0" style={{ fontSize: "30px" }}>
                        <i className="bx bx-time me-1"></i>
                        {Math.floor(
                          moment
                            .duration(
                              moment(new Date(shiftData.end_time * 1000)).diff(
                                moment(new Date(shiftData.start_time * 1000))
                              )
                            )
                            .asHours()
                        )}{" "}
                        hrs{" "}
                        {Math.floor(
                          moment
                            .duration(
                              moment(new Date(shiftData.end_time * 1000)).diff(
                                moment(new Date(shiftData.start_time * 1000))
                              )
                            )
                            .asMinutes() % 60
                        )}{" "}
                        mins
                      </p> */}
                      <p className="hstack mb-0" style={{ fontSize: "30px" }}>
                        <i className="bx bx-time me-1"></i>
                        {hours} hrs {minutes} mins
                      </p>
                      <div style={{ textAlign: "center" }}>Total Paid Time</div>
                    </div>
                  </Col>
                  {console.log(shiftData, " shift data")}
                  <Col md={3} className="mt-3">
                    <div className="mt-0">
                      <label className="form-label mb-0">Start Time</label>
                      <Flatpickr
                        className="form-control"
                        value={new Date(shiftData?.start_time * 1000)}
                        onChange={(selectedDates) => {
                          // Convert the selected time to Unix time
                          const selectedTime =
                            selectedDates[0].getTime() / 1000;
                          setShiftData((prevState) => ({
                            ...prevState,
                            start_time: selectedTime, // Update the shift_start_time in the shiftData state
                          }));
                        }}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "h:i K",
                          defaultDate: "13:45",
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={1}>
                    <div style={{ marginLeft: "13px", marginTop: "45px" }}>
                      -
                    </div>
                  </Col>
                  <Col md={3} className="mt-3">
                    <div className="mt-0">
                      <label className="form-label mb-0">End Time</label>
                      <Flatpickr
                        className="form-control"
                        value={
                          shiftData?.end_time
                            ? new Date(shiftData?.end_time * 1000)
                            : "00:00"
                        }
                        onChange={(selectedDates) => {
                          // Convert the selected time to Unix time
                          const selectedTime =
                            selectedDates[0].getTime() / 1000;
                          setShiftData((prevState) => ({
                            ...prevState,
                            end_time: selectedTime, // Update the shift_start_time in the shiftData state
                          }));
                        }}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "h:i K",
                          defaultDate: "13:45",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={2}>
                    <h5>Breaks</h5>
                  </Col>
                </Row>
                <div>
                  {breaks.map((breakItem, index) => {
                    // Convert Unix timestamps to JavaScript Date objects
                    const startTime = new Date(breakItem.start * 1000);
                    let endTime = new Date(breakItem.finish * 1000);

                    // If end time is before start time, add a day to end time
                    if (endTime < startTime) {
                      endTime = new Date(
                        endTime.getTime() + 24 * 60 * 60 * 1000
                      ); // Adding 24 hours in milliseconds
                    }

                    // Calculate duration
                    const durations = moment.duration(
                      moment(endTime).diff(moment(startTime))
                    );

                    // Get total minutes from the duration and round it to the nearest whole number
                    const totalMinutes = Math.round(durations.asMinutes());

                    {
                      console.log(breakItem, "breakItem1243");
                    }
                    return (
                      <Row className="mb-2" key={index}>
                        <Col md={1}></Col>
                        <Col md={3}>
                          <div className="mt-0">
                            <label className="form-label mb-0">Start</label>
                            <Flatpickr
                              className="form-control"
                              value={
                                breakItem.start
                                  ? new Date(breakItem.start * 1000)
                                  : "00:00"
                              }
                              onChange={(selectedDates) => {
                                // Convert the selected time to Unix time
                                const selectedTime =
                                  selectedDates[0].getTime() / 1000;
                                const newBreaks = [...breaks];
                                newBreaks[index].start = selectedTime;
                                setBreaks(newBreaks);
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "h:i K",
                                defaultDate: "13:45",
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mt-0">
                            <label className="form-label mb-0">Finish</label>
                            <Flatpickr
                              className="form-control"
                              value={
                                breakItem.finish
                                  ? new Date(breakItem.finish * 1000)
                                  : "00:00"
                              }
                              onChange={(selectedDates) => {
                                // Convert the selected time to Unix time
                                const selectedTime =
                                  selectedDates[0].getTime() / 1000;
                                const newBreaks = [...breaks];
                                newBreaks[index].finish = selectedTime;
                                console.log("Selected end time:", newBreaks);
                                setBreaks(newBreaks);
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "h:i K",
                                defaultDate: "13:45",
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          md={3}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className="mt-0" style={{ marginLeft: "40px" }}>
                            <label className="form-label mb-0">
                              Duration(mins)
                            </label>
                            {/* {console.log(new Date(breakItem.start * 1000),new Date(breakItem.finish * 1000), "duration23434")} */}
                            {/* <Input
                              type="text"
                              style={{ width: "110%" }}
                              className="form-control"
                              id="duration"
                              value={
                                Math.round(
                                  moment
                                    .duration(
                                      (breakItem.finish
                                        ? moment(
                                            new Date(breakItem.finish * 1000)
                                          )
                                        : moment().startOf("day")
                                      ).diff(
                                        breakItem.start
                                          ? moment(
                                              new Date(breakItem.start * 1000)
                                            )
                                          : moment().startOf("day")
                                      )
                                    )
                                    .asMinutes()
                                ) + " minutes"
                              }
                              disabled
                            /> */}
                            <Input
                              type="text"
                              style={{ width: "110%" }}
                              className="form-control"
                              id="duration"
                              value={
                                isNaN(totalMinutes)
                                  ? "0 minutes"
                                  : totalMinutes + " minutes"
                              }
                              disabled
                            />
                          </div>
                        </Col>
                        <Col md={1} className="mt-3">
                          <button
                            onClick={() => handleDeleteBreak(index)}
                            className="btn btn-ghost-dark"
                          >
                            <i className="ri-delete-bin-6-line align-bottom me-1"></i>{" "}
                          </button>
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                    );
                  })}
                  {/* <div className="mt-2">
                    Total Duration: {Math.round(totalDuration)} minutes
                  </div> */}
                </div>
                <Row className="mt-3">
                  <Col md={2}>
                    <button
                      onClick={handleAddBreak}
                      className="btn btn-soft-primary"
                    >
                      Add Break
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3 mt-3">
                    <div>
                      <label className="form-label mb-0">Comments</label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea5"
                        rows="3"
                        value={workerComments}
                        onChange={(e) => {
                          setWorkerComments(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
                {/* <hr></hr> */}
                <Col lg={12}>
                  <div className="hstack gap-2 justify-content-end">
                    {/* <button
                      className="btn btn-link btn-soft-primary text-decoration-none fw-medium"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setIsEditModalOpen(false);
                        setBreaks([]);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={(e) => handleEndShift(e)}
                    >
                      End Shift
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            {isLoadings && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                }}
              >
                <Loader />
              </div>
            )}
          </Modal>
          {/* -----------------End Shift modal-------------------- */}
        </Container>
        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default Overview;
