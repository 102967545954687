import React from "react";
import { Col,Row} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import getrosterlogowhite from "../../assets/images/getrosterlogowhite.png";
import imageregister1 from "../../assets/images/imageregister1.png";
import imageregister2 from "../../assets/images/imageregister2.png";
import imageregister3 from "../../assets/images/imageregister3.png";
import imageregister4 from "../../assets/images/imageregister4.png";
import imageregister5 from "../../assets/images/imageregister5.png";
import imageregister6 from "../../assets/images/imageregister6.png";
import imageregister7 from "../../assets/images/imageregister7.png";
import imageregister8 from "../../assets/images/imageregister8.png";
import imageregister9 from "../../assets/images/imageregister9.png";
import imageregister10 from "../../assets/images/imageregister10.png";
import imageregister11 from "../../assets/images/imageregister11.png";
import imageregister12 from "../../assets/images/imageregister12.png";


const AuthSlider = () => {
    return (


<Col lg={6}>
        <div className="auth-one-bg ">
          <div className="bg-overlay">
          <div className="position-relative  d-flex flex-column">
            <div className="mt-auto p-5 reg-height">
              <Carousel
                showThumbs={false}
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                showIndicators = {false}
                className="carousel slide"
                id="qoutescarouselIndicators"
              >
                <div className="carousel-inner text-center text-white">
                <div className="item">
                    <div className="container fluid">
                    <div className="justify-content-start">
                        <Row className="justify-content-start " style={{ paddingLeft: "0" }}>
                            <Col md={6} className="text-start" style={{ paddingLeft: "0" }}>
                                <Link to="/dashboard" className="d-block">
                                    <img
                                        src={getrosterlogowhite}
                                        alt=""
                                        className="img-fluid h-30 d-block"
                                        style={{ marginLeft: "-7px" }} // Adjust the value as needed
                                    />
                                </Link>
                            </Col>
                        </Row>
                    </div>
                      <div className="row mt-1">
                        <div className="col">
                          <p
                            className="mb-2"
                            style={{
                              fontWeight: "500",
                              fontSize: "20px",
                              textAlign: "start",
                            }}
                          >
                            Unlock a World of Talent
                          </p>
                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                          Instant access to a dynamic talent pool, much like Uber's model for drivers, ensuring you're never short of skilled workers
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-6 d-flex flex-column">
                          <img
                            src={imageregister1}
                            alt=""
                            className="img-fluid "
                            style={{
                              borderRadius: "10px",
                              height: "100%",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <div className="row mb-4 flex-grow-1">
                            <img
                              src={imageregister2}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                          <div className="row flex-grow-1">
                            <img
                              src={imageregister3}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white">
                <div className="item">
                    <div className="container fluid">
                    <div className="justify-content-start">
                    <Row className="justify-content-start" style={{ paddingLeft: "0" }}>
                        <Col md={6} className="text-start" style={{ paddingLeft: "0" }}>
                            <Link to="/dashboard" className="d-block">
                                <img
                                    src={getrosterlogowhite}
                                    alt=""
                                    className="img-fluid h-30 d-block"
                                  
                                />
                            </Link>
                        </Col>
                    </Row>
                </div>

                      <div className="row mt-1">
                        <div className="col">
                          <p
                            className="mb-2"
                            style={{
                              fontWeight: "500",
                              fontSize: "20px",
                              textAlign: "start",
                            }}
                          >
                           Direct Talent Discovery
                          </p>
                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                          Seamlessly search and add exceptional talent to your pool with the power of advanced AI-driven job matching.
                          </p>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-6 d-flex flex-column">
                          <img
                            src={imageregister4}
                            alt=""
                            className="img-fluid "
                            style={{
                              borderRadius: "10px",
                              height: "100%",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <div className="row mb-4 flex-grow-1">
                            <img
                              src={imageregister5}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                          <div className="row flex-grow-1">
                            <img
                              src={imageregister6}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white">
                <div className="item">
                    <div className="container fluid">
                    <div className="justify-content-start">
                    <Row className="justify-content-start" style={{ paddingLeft: "0" }}>
                        <Col md={6} className="text-start" style={{ paddingLeft: "0" }}>
                            <Link to="/dashboard" className="d-block">
                                <img
                                    src={getrosterlogowhite}
                                    alt=""
                                    className="img-fluid h-30 d-block"
                                    style={{ marginLeft: "-7px" }} // Adjust the value as needed
                                />
                            </Link>
                        </Col>
                    </Row>
                </div>
                      <div className="row mt-1">
                        <div className="col">
                          <p
                            className="mb-2"
                            style={{
                              fontWeight: "500",
                              fontSize: "20px",
                              textAlign: "start",
                            }}
                          >
                     Dynamic Rostering 
                          </p>
                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                          Utilise smart, dynamic scheduling to efficiently manage team shifts, adapting to the ever-changing needs of your business
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-6 d-flex flex-column">
                          <img
                            src={imageregister7}
                            alt=""
                            className="img-fluid "
                            style={{
                              borderRadius: "10px",
                              height: "100%",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <div className="row mb-4 flex-grow-1">
                            <img
                              src={imageregister8}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                          <div className="row flex-grow-1">
                            <img
                              src={imageregister9}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-inner text-center text-white ">
                <div className="item">
                    <div className="container fluid">
                    <div className="justify-content-start">
                    <Row className="justify-content-start" style={{ paddingLeft: "0" }}>
                        <Col md={6} className="text-start" style={{ paddingLeft: "0" }}>
                            <Link to="/dashboard" className="d-block">
                                <img
                                    src={getrosterlogowhite}
                                    alt=""
                                    className="img-fluid h-30 d-block"
                                    style={{ marginLeft: "-7px" }} // Adjust the value as needed
                                />
                            </Link>
                        </Col>
                    </Row>
                </div>
                      <div className="row mt-1">
                        <div className="col">
                          <p
                            className="mb-2"
                            style={{
                              fontWeight: "500",
                              fontSize: "20px",
                              textAlign: "start",
                            }}
                          >
                            Effortless Job Posting
                          </p>
                          <p style={{ textAlign: "start", fontSize: "16px" }}>
                          Easily post vacancies and receive applications directly from the workforce, streamlining your hiring process
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-6 d-flex flex-column">
                          <img
                            src={imageregister10}
                            alt=""
                            className="img-fluid "
                            style={{
                              borderRadius: "10px",
                              height: "100%",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <div className="row mb-4 flex-grow-1">
                            <img
                              src={imageregister11}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                          <div className="row flex-grow-1">
                            <img
                              src={imageregister12}
                              alt=""
                              className="img-fluid w-100"
                              style={{
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
          </div>
       
        </div>
      </Col>
 
    );
};

export default AuthSlider;