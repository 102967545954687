import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Container,
  Button,
  UncontrolledTooltip,
  Input,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import { get_cookie } from "../../helpers/get_cookie";
//Import Icons
import FeatherIcon from "feather-icons-react";
import PersonalInfo from "../Chat/PersonalInfo";
import Pusher from "pusher-js";
//import { chatContactData } from "../../common/data";

import Picker from "emoji-picker-react";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getDirectContact as onGetDirectContact,
  getMessages,
  getChannels as onGetChannels,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage,
} from "../../store/actions";

import avatar2 from "../../assets/images/users/avatar-2.jpg";
import userDummayImage from "../../assets/images/users/user-dummy-img.jpg";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import BreadCrumb from "../../Components/Common/BreadCrumb";

const Chat = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const ref = useRef();
  const dispatch = useDispatch();
  const [authUserId, setAuthUserId] = useState();
  const [isInfoDetails, setIsInfoDetails] = useState(false);
  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  const [Chat_Box_Image, setChat_Box_Image] = useState(avatar2);
  const [Chat_Box_Email, setChat_Box_Email] = useState();
  const [Chat_Box_Id, setChat_Box_Id] = useState();
  const [currentRoomId, setCurrentRoomId] = useState(null);
  const [messageBox, setMessageBox] = useState(null);
  const [curMessage, setcurMessage] = useState("");
  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);
  const [reply, setreply] = useState("");
  const [emojiPicker, setemojiPicker] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [profiles, setprofiles] = useState("");
  const [profileemail, setprofileemail] = useState("");
  const [profilename, setprofilename] = useState("");
  const [profilelocation, setprofilelocation] = useState("");
  const [profilecontact, setprofilecontact] = useState();
  const [business_name, setbusiness_name] = useState();
  const [businesslocation, setlocationbus] = useState();
  const [business_description, setbusdescription] = useState();
  const [prefferedavailability, setpreferredavailability] = useState([]);
  const [isSameRoom, setIsSameRoom] = useState(false);
  const [type, settype] = useState("");
  const [currentUser, setCurrentUser] = useState({
    name: "Anna Adame",
    isActive: true,
  });
  const [messageId, setMessageId] = useState(null);
  const [receiverId, setReceiverId] = useState();
  const [chatContactData, setChatContactData] = useState([]);
  const { channels } = useSelector((state) => ({
    // chats: state.chat.chats,
  }));
  const [isInitialPage, setInitialPage] = useState(true);
  const [isMessageloading, setIsMessageLoading] = useState(false);
  console.log("messages", messages);

  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  //Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };
  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("fc8c478ec64fb8c9147b", {
      cluster: "ap4",
      encrypted: true,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe("my-channel");

    // Listen for the 'new-message' event
    channel.bind("new-message", function (data) {
      // Handle the event, update the UI, etc.
      if (data.new_message.message_id === messageId) {
        setIsSameRoom(true);
        setMessages((prevChats) => [...prevChats, data.new_message]);
        getChatMessages(data.new_message.message_id);
        getChats(authUser_id);
      } else {
        setIsSameRoom(false);
        console.log("not the current convo");
        getChats(authUser_id);
      }
      console.log(data, "pusher message");
      //alert(data.new_message);
    });

    // Clean up function
    return () => {
      // Unsubscribe from the channel when component unmounts
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [messageId]);

  useEffect(() => {
    // dispatch(onGetDirectContact());
    // dispatch(onGetChannels());
    // dispatch(getMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  useEffect(() => {
    axios
      .get(`/user-details/${authUser_id}`)
      .then((res) => {
        setAuthUserId(res.data.user_details.user_id);
        getChatContactData(res.data.user_details.user_id);
        getChats(res.data.user_details.user_id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  console.log(chats, "chats");

  const getChats = (userId) => {
    let formData = {
      user_id: authUser_id,
    };
    axios
      .post("get-work-chat-lists", formData)
      .then((res) => {
        console.log(res, "heyyyyyy");
        setChats(res);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const getprofiledetails = (userId) => {
    let formData = {
      user_id: authUser_id,
    };
    axios
      .post("get-chat-profile-details", formData)
      .then((res) => {
        setprofiles(res);
        setprofilename(res.name);
        setprofilelocation(res.location);
        setprofilecontact(res.contact);
        setprofileemail(res.email);
        settype(res.type);
        setbusiness_name(res.business_name);
        setlocationbus(res.business_location);
        setbusdescription(res.about_the_business);
        setpreferredavailability(res.prefered_availability);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const getChatContactData = (userId) => {
    let formData = {
      user_id: userId,
    };
    axios
      .post("get-work-chat-contacts", formData)
      .then((res) => {
        if (res.status === 401) {
          // Check the custom status from the response
          setChatContactData(""); // Clear chat contact data if unauthorized
        } else {
          setChatContactData(res); // Set chat data from the response
        }
      })
      .catch((err) => {
        console.log(err); // Log any errors that occur
      });
  };

  const getChatMessages = (msgId) => {
    console.log("hellohiii");
    let formData = {
      user_id: authUser_id,
      message_id: msgId,
    };

    console.log(msgId, "msgIdmsgIdmsgId");
    if (msgId != null) {
      axios
        .post("get-work-chat-messages", formData)
        .then((res) => {
          console.log(res);
          setMessages(res);
          setInitialPage(false);
          setIsMessageLoading(false);
        })
        .then((err) => {
          console.log(err);
        });
    } else {
      setMessages([]);
      setIsMessageLoading(false);
    }
    setMessageId(msgId);
  };

  //Use For Chat Boxmessage_id
  const userChatOpen = (id, name, status, roomId, image, contact_id) => {
    setIsMessageLoading(true);
    setInitialPage(false);
    setChat_Box_Username(name);
    setChat_Box_Id(id);
    setCurrentRoomId(roomId);
    setChat_Box_Image(image);
    getChatMessages(roomId);
    setMessageId(roomId);
    setReceiverId(contact_id);
    getprofiledetails(contact_id);
    const chatIndex = chats.findIndex((chat) => chat.id === id);

    // If chat index is found and the chat has a badge value, update the badge value to 0
    if (chatIndex !== -1 && chats[chatIndex].badge) {
      const updatedChats = [...chats];
      updatedChats[chatIndex] = { ...updatedChats[chatIndex], badge: 0 };
      setChats(updatedChats);
    }
    //console.log(getMessages(roomId));
  };

  const addMessage = (roomId, sender) => {
    const formData = {
      user_id: authUser_id,
      message: curMessage,
      message_id: messageId,
      receiver_id: receiverId,
    };
    if (curMessage !== "") {
      axios
        .post("send-work-chat", formData)
        .then((res) => {
          getChatMessages(res.new_message.message_id);
        })
        .then((err) => {
          console.log(err);
        });
      setcurMessage("");
    }

    //dispatch(onAddMessage(message));
  };

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages, scrollToBottom]);

  const onKeyPress = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      setcurMessage(value);
      addMessage();
    }
  };
  const renderMessagesByDate = (messages) => {
    // Group messages by date
    const messagesByDate = {};
    messages.forEach((message) => {
      const messageDate = new Date(message.created_date);
      const formattedDate = messageDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      if (!messagesByDate[formattedDate]) {
        messagesByDate[formattedDate] = [];
      }
      messagesByDate[formattedDate].push(message);
    });

    // Render messages grouped by date
    return Object.entries(messagesByDate).map(([date, messages]) => (
      <>
        <div className="d-flex justify-content-center">
          <Badge key={date} color="primary">
            {date}
          </Badge>
        </div>

        {messages.map((message, index) => (
          <li
            className={
              message.sender_id === Chat_Box_Id
                ? "chat-list left"
                : "chat-list right"
            }
            key={index}
          >
            {console.log(message.sender_id, Chat_Box_Id, "chat box id")}
            <div className="conversation-list align-items-center">
              {message.sender_id === Chat_Box_Id && (
                <div
                  className="chat-avatar"
                  style={{ height: "30px", width: "30px" }}
                >
                  {Chat_Box_Image === null ? (
                    <div className={"avatar-title rounded-circle userprofile"}>
                      {Chat_Box_Username.charAt(0)}
                    </div>
                  ) : (
                    <img src={Chat_Box_Image} alt="" />
                  )}
                </div>
              )}

              <div className="user-chat-content">
                <div className="ctext-wrap">
                  <div className="ctext-wrap-content">
                    <p className="mb-0 ctext-content">{message.message}</p>
                  </div>
                  <UncontrolledDropdown className="align-self-start message-box-drop">
                    <DropdownToggle href="#" className="btn nav-btn" tag="i">
                      <i className="ri-more-2-fill"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        onClick={(e) => handleCkick(e.target)}
                      >
                        <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                        Copy
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="conversation-name">
                  <small className="text-muted time">
                    {message.created_time}
                  </small>{" "}
                  <span className="text-success check-message-icon">
                    <i className="ri-check-double-line align-bottom"></i>
                  </span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </>
    ));
  };
  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    var userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    });
  };

  //Search Message
  const searchMessages = () => {
    var searchInput, searchFilter, searchUL, searchLI, a, i, txtValue;
    searchInput = document.getElementById("searchMessage");
    searchFilter = searchInput.value.toUpperCase();
    searchUL = document.getElementById("users-conversation");
    searchLI = searchUL.getElementsByTagName("li");
    Array.prototype.forEach.call(searchLI, function (search) {
      a = search.getElementsByTagName("p")[0]
        ? search.getElementsByTagName("p")[0]
        : "";
      txtValue =
        a.textContent || a.innerText ? a.textContent || a.innerText : "";
      if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
        search.style.display = "";
      } else {
        search.style.display = "none";
      }
    });
  };

  // Copy Message
  const handleCkick = (ele) => {
    var copy = ele
      .closest(".chat-list")
      .querySelector(".ctext-content").innerHTML;
    navigator.clipboard.writeText(copy);

    document.getElementById("copyClipBoard").style.display = "block";
    setTimeout(() => {
      document.getElementById("copyClipBoard").style.display = "none";
    }, 2000);
  };

  // emoji
  const [emojiArray, setemojiArray] = useState("");

  const onEmojiClick = (event, emojiObject) => {
    setemojiArray([...emojiArray, emojiObject.emoji]);
    let emoji = [...emojiArray, emojiObject.emoji].join(" ");
    setcurMessage(emoji);
  };

  document.title = "Chat | Get Rostered";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Chat" pageTitle="Chat" />
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-4">
                <div className="search-box">
                  <input
                    onKeyUp={searchUsers}
                    id="search-user"
                    type="text"
                    className="form-control bg-light border-light"
                    placeholder="Search contacts..."
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>
              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                      getChats(authUserId);
                    }}
                  >
                    Chats
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={customActiveTab} className="text-muted">
                <TabPane tabId="1" id="chats">
                  <SimpleBar
                    className="chat-room-list pt-3"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                    <div className="d-flex align-items-center px-4 mb-2"></div>

                    <div className="chat-message-list">
                      <ul
                        className="list-unstyled chat-list chat-user-list users-list"
                        id="userList"
                      >
                        {(chats || []).map((chat) => (
                          <li
                            key={chat.id + chat.status}
                            className={
                              currentRoomId === chat.message_id ? "active" : ""
                            }
                          >
                            <Link
                              to="#"
                              onClick={(e) => {
                                userChatOpen(
                                  chat.id,
                                  chat.name,
                                  chat.status,
                                  chat.message_id,
                                  chat.image,
                                  chat.id,
                                  chat
                                );
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                  <div className="avatar-xxs">
                                    {chat.image ? (
                                      <img
                                        src={chat.image}
                                        className="rounded-circle img-fluid userprofile"
                                        alt=""
                                      />
                                    ) : (
                                      <div
                                        className={
                                          "avatar-title rounded-circle bg-" +
                                          chat.bgColor +
                                          " userprofile"
                                        }
                                      >
                                        {chat.name.charAt(0) +
                                          chat.name
                                            .split(" ")
                                            .slice(-1)
                                            .toString()
                                            .charAt(0)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-truncate mb-0">
                                    {chat.name}
                                  </p>
                                </div>
                                {chat.badge ? (
                                  !isSameRoom ? (
                                    <div className="flex-shrink-0">
                                      <span className="badge badge-soft-success rounded p-1">
                                        {chat.badge}
                                      </span>
                                    </div>
                                  ) : null
                                ) : null}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </SimpleBar>
                </TabPane>
                <TabPane tabId="2" id="contacts">
                  <SimpleBar
                    className="chat-room-list pt-3"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                    <div className="sort-contact">
                      {Array.isArray(chatContactData) && chatContactData.length > 0 ? (
                        chatContactData.map((item, key) => (
                          <div className="mt-3" key={key}>
                            <div className="contact-list-title">
                              {item.title}
                            </div>
                            <ul
                              id={"contact-sort-" + item.title}
                              className="list-unstyled contact-list"
                            >
                              {item.contacts.map((item, key) => (
                                <li key={key}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="avatar-xxs">
                                        {item.image ? (
                                          <img
                                            src={item.image}
                                            className="img-fluid rounded-circle"
                                            alt=""
                                          />
                                        ) : (
                                          <div
                                            className={
                                              "avatar-title rounded-circle bg-" +
                                              item.bgColor +
                                              " userprofile"
                                            }
                                          >
                                            {item.name.charAt(0) +
                                              item.name
                                                .split(" ")
                                                .slice(-1)
                                                .toString()
                                                .charAt(0)}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="flex-grow-1"
                                      onClick={(e) => {
                                        userChatOpen(
                                          item.user_id,
                                          item.name,
                                          item.status,
                                          item.message_id,
                                          item.image,
                                          item.user_id
                                        );
                                      }}
                                    >
                                      <p className="text-truncate contactlist-name mb-0">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <div className="mt-3 text-center">
                          <i className="text-muted">No contacts found.</i>
                        </div>
                      )}
                    </div>
                  </SimpleBar>
                </TabPane>
              </TabContent>
            </div>

            <div className="user-chat w-100 overflow-hidden">
              {!isInitialPage ? (
                <div className="chat-content d-lg-flex">
                  <div className="w-100 overflow-hidden position-relative">
                    <div className="position-relative">
                      <div className="p-3 user-chat-topbar">
                        <Row className="align-items-center">
                          <Col sm={4} xs={8}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 d-block d-lg-none me-3">
                                <Link
                                  to="#"
                                  className="user-chat-remove fs-18 p-1"
                                >
                                  <i className="ri-arrow-left-s-line align-bottom"></i>
                                </Link>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className=" chat-user-img online user-own-img align-self-center me-3 ms-0">
                                    {Chat_Box_Image === null ? (
                                      <span
                                        className="avatar-title rounded-circle bg-primary fs-18"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      >
                                        {Chat_Box_Username.charAt(0) +
                                          Chat_Box_Username.split(" ")
                                            .slice(-1)
                                            .toString()
                                            .charAt(0)}
                                      </span>
                                    ) : (
                                      <img
                                        src={Chat_Box_Image}
                                        className="rounded-circle avatar-xs"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate mb-0 fs-16">
                                      <a
                                        className="text-reset username"
                                        data-bs-toggle="offcanvas"
                                        href="#userProfileCanvasExample"
                                        aria-controls="userProfileCanvasExample"
                                      >
                                        {Chat_Box_Username}
                                      </a>
                                    </h5>
                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                      <small>Online</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={8} xs={4}>
                            <ul className="list-inline user-chat-nav text-end mb-0">
                              <li className="list-inline-item m-0">
                                <Dropdown
                                  isOpen={search_Menu}
                                  toggle={toggleSearch}
                                >
                                  <DropdownToggle
                                    className="btn btn-ghost-secondary btn-icon"
                                    tag="button"
                                  >
                                    <FeatherIcon
                                      icon="search"
                                      className="icon-sm"
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-lg">
                                    <div className="p-2">
                                      <div className="search-box">
                                        <Input
                                          onKeyUp={searchMessages}
                                          type="text"
                                          className="form-control bg-light border-light"
                                          placeholder="Search here..."
                                          id="searchMessage"
                                        />
                                        <i className="ri-search-2-line search-icon"></i>
                                      </div>
                                    </div>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>

                              <li className="list-inline-item d-none d-lg-inline-block m-0">
                                <button
                                  type="button"
                                  className="btn btn-ghost-secondary btn-icon"
                                  onClick={toggleInfo}
                                >
                                  <FeatherIcon
                                    icon="info"
                                    className="icon-sm"
                                  />
                                </button>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>

                      <div className="position-relative" id="users-chat">
                        <PerfectScrollbar
                          className="chat-conversation p-3 p-lg-4"
                          id="chat-conversation"
                          containerRef={(ref) => setMessageBox(ref)}
                        >
                          <div id="elmLoader"></div>
                          <ul
                            className="list-unstyled chat-conversation-list"
                            id="users-conversation"
                          >
                            {isMessageloading ? (
                              <div>
                                <li className="chat-list left">
                                  <div className=" align-items-center">
                                    <div className="user-chat-content">
                                      <h3 className="card-title placeholder-glow  ">
                                        <span
                                          className="placeholder placeholder-xl mt-1 col-6 bg-dark"
                                          style={{ height: "1.5em" }}
                                        ></span>{" "}
                                        <br />
                                        <span
                                          className="placeholder placeholder-xl mt-1 col-3 bg-dark"
                                          style={{ height: "1.5em" }}
                                        ></span>
                                      </h3>
                                    </div>
                                  </div>
                                </li>
                                <li className="chat-list right align-items-right mt-3">
                                  <div className="me-2 mt-12 text-end  ">
                                    <h3 className="card-title placeholder-glow ">
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-2 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>{" "}
                                      <br />
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-4 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>
                                    </h3>
                                  </div>
                                </li>
                                <li className="chat-list left align-items-left mt-3">
                                  <div className="me-2 mt-12 text-start  ">
                                    <h3 className="card-title placeholder-glow ">
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-3 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>{" "}
                                      <br />
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-5 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>
                                    </h3>
                                  </div>
                                </li>
                                <li className="chat-list right align-items-right mt-3">
                                  <div className="me-2 mt-12 text-end  ">
                                    <h3 className="card-title placeholder-glow ">
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-4 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>{" "}
                                      <br />
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-6 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>
                                    </h3>
                                  </div>
                                </li>
                                <li className="chat-list left align-items-left mt-3">
                                  <div className="me-2 mt-12 text-start  ">
                                    <h3 className="card-title placeholder-glow ">
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-2 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>{" "}
                                      <br />
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-1 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>
                                    </h3>
                                  </div>
                                </li>
                                <li className="chat-list right align-items-right mt-3">
                                  <div className="me-2 mt-12 text-end  ">
                                    <h3 className="card-title placeholder-glow ">
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-3 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>{" "}
                                      <br />
                                      <span
                                        className="placeholder placeholder-xl mt-1 col-1 bg-dark"
                                        style={{ height: "1.5em" }}
                                      ></span>
                                    </h3>
                                  </div>
                                </li>
                              </div>
                            ) : (
                              <> {renderMessagesByDate(messages)}</>
                            )}
                          </ul>
                        </PerfectScrollbar>
                        <div
                          className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
                          id="copyClipBoard"
                          role="alert"
                        >
                          Message copied
                        </div>
                        {emojiPicker && (
                          <div className="alert pickerEmoji">
                            <Picker
                              disableSearchBar={true}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        )}
                      </div>

                      <div className="chat-input-section p-3 p-lg-4">
                        <Row className="g-0 align-items-center">
                          <div className="col">
                            <div className="chat-input-feedback">
                              Please Enter a Message
                            </div>
                            <input
                              type="text"
                              value={curMessage}
                              onKeyPress={onKeyPress}
                              onChange={(e) => setcurMessage(e.target.value)}
                              className="form-control chat-input bg-light border-light"
                              id="chat-input"
                              placeholder="Type your message..."
                            />
                          </div>
                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">
                                <Button
                                  type="button"
                                  color="success"
                                  onClick={() => {
                                    addMessage(currentRoomId, currentUser.name);
                                    setemojiPicker(false);
                                    setemojiArray("");
                                  }}
                                  className="chat-send waves-effect waves-light"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>

                      <div className={reply ? "replyCard show" : "replyCard"}>
                        <Card className="mb-0">
                          <CardBody className="py-3">
                            <div className="replymessage-block mb-0 d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h5 className="conversation-name">
                                  {reply && reply.sender}
                                </h5>
                                <p className="mb-0">{reply && reply.message}</p>
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                  type="button"
                                  id="close_toggle"
                                  className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                  onClick={() => setreply("")}
                                >
                                  <i className="bx bx-x align-middle"></i>
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <h4 className="text-center">
                    Click on a contact to view their conversation
                  </h4>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={profilename}
        currentuseremail={profileemail}
        currentuserlocation={profilelocation}
        currentusercontact={profilecontact}
        cuurentiseImg={Chat_Box_Image}
        currenttype={type}
        currentbusiness={business_name}
        about_business={business_description}
        businesslocation={businesslocation}
        preferedavailability={prefferedavailability}
      />
    </React.Fragment>
  );
};

export default Chat;
