import { Card, CardBody, CardHeader, Col, Container, Row, Accordion, AccordionItem, Collapse, Label, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SettingsTabPages } from './Constants/SettingsConstants';
import SettingsTabControl from './TabComponents/SettingsTabControl';
import SettingsTabContent from './TabComponents/SettingsTabContent';

const EditSite = () => {

    document.title = "Business Settings | Get Roster";
    const params = useParams();
    const tab = params.activeTab;
    const [activeTab, setActiveTab] = useState('');
  
    useEffect(() => {
    // Handle tab parameter if it's provided in the URL
    if (tab === 'general') {
      setActiveTab(SettingsTabPages.General);
    } else if (tab === 'zone') {
      setActiveTab(SettingsTabPages.People);
    }else {
      setActiveTab(SettingsTabPages.General);
    }
  }, [tab]);

  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.General:
        return 'General';
      case SettingsTabPages.Zone:
        return 'Zone';
      default:
        return 'General';
    }
  };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Edit Site" pageTitle="Edit Site" />
                    <Row>
                        <Col md="3">
                            <SettingsTabControl activeTabSetter={setActiveTab} activeTab={activeTab} />
                          </Col>
                          {activeTab ? (
                            <Col md="9">
                              <SettingsTabContent activeTab={activeTab} />
                            </Col>) : (<Col md="9">
                              <Card className='p-3 text-bold'> Permission Not Granted.</Card>
                            </Col>)}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EditSite;