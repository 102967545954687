import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo,useRef} from "react";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//dateformat
import moment from 'moment';
import { get_cookie } from '../../../helpers/get_cookie';
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";
import Dropzone from "react-dropzone";
import uploadImg from '../../../assets/images/freepik/uploading_img.png';
import img13 from "../../../assets/images/small/img-12.jpg";
import { api } from "../../../config";
import GoogleAutocomplete from "react-google-autocomplete";
import { useParams } from 'react-router-dom';
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const General = () => {
  document.title="General | Roster";
  const params = useParams();
    const site_id = params.id

 const [isLoading, setisLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [site_data, setSiteData]=useState();
  //site
  const [site_name, setSiteName] = useState('');
  const [address, setAddress] = useState('');
  const [week_starts, setWeekStarts] = useState('');
  /*const [time_zone, setTimeZone] = useState('');
  const [time_option, setTimeOption] = useState('');*/
  const [time_zone, setTimeZone] = useState('');
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [talent_pool, setTalentPool] = useState('');
  const [addressError, setAddressError] = useState('');
  const [siteNameError, setSiteNameError] = useState('');
  // operation hours
  const [mondayFromTime, setMondayFromTime] = useState('');
  const [mondayToTime, setMondayToTime] = useState('');
  const [tuesdayFromTime, setTuesdayFromTime] = useState('');
  const [tuesdayToTime, setTuesdayToTime] = useState('');
  const [wednesdayFromTime, setWednesdayFromTime] = useState('');
  const [wednesdayToTime, setWednesdayToTime] = useState('');
  const [thursdayFromTime, setThursdayFromTime] = useState('');
  const [thursdayToTime, setThursdayToTime] = useState('');
  const [fridayFromTime, setFridayFromTime] = useState('');
  const [fridayToTime, setFridayToTime] = useState('');
  const [saturdayFromTime, setSaturdayFromTime] = useState('');
  const [saturdayToTime, setSaturdayToTime] = useState('');
  const [sundayFromTime, setSundayFromTime] = useState('');
  const [sundayToTime, setSundayToTime] = useState('');
  const [id, setId] = useState('');
  const [notes, setNotes] = useState('');
  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };
      const [selectedCity, setSelectedcity] = useState();
      const [selectedSuburb, setselectedSuburb] = useState();
      const [selectedPostcode, setselectedPostcode] = useState();
      const [country, setCountry] = useState([]);
  const residentialplace = (place) => {
      const addressComponents = place?.address_components;
        console.log(place);
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(`${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `);
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);

  };
  const handleSelectTimeZone = (selectedOption) => {
    if (selectedOption) {
      setTimeZone(selectedOption.value);
    } else {
      setTimeZone(''); // Assuming time_zone is initially set to an empty string
    }
  };
  const loadgeneral = () => {
    setisLoading(true);
    axios.get(`/api/get-site-data/${site_id}`)
      .then(response => {
        setTalentPool(response.dropdownOptions);
          if (Array.isArray(response.timezones)) {
            setTimeZone(response.timezones);
            console.log(response.timezones);
          }
        setSiteData(response.site_data);
        setAddress(response.full_address);
        setSiteName(response.site_data.site_name);
        setId(response.site_data.id);
        setWeekStarts(response.week_starts);
        if (Array.isArray(response.timezones)) {
          setTimeZoneOptions(response.timezones.map(timeZone => ({ value: timeZone, label: timeZone })));
        }
        // Set the selected time zone
        setTimeZone(response.site_data.timezone);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadgeneral();
  }, []);

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectDay(selectedDay) {
    setWeekStarts(selectedDay);
  }
  const productCountry = [
    {
      options: [
        { label: "Select Day...", value: "Select Day" },
        { label: "Monday", value: "Mon" },
        { label: "Tuesday", value: "Tue" },
        { label: "Wednesday", value: "Wed" },
        { label: "Thursday", value: "Thu" },
        { label: "Friday", value: "Fri" },
        { label: "Saturday", value: "Sat" },
        { label: "Sunday", value: "Sun" },
      ],
    },
  ];
    
    const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? '00' : `${m}`;
        const time = `${hour}:${minute}`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    options.push({ label: 'Open_24_hours', value: 'open_24_hours' });
    options.push({ label: 'Closed', value: 'closed' });
    return options;
  };
  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(':');
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? 'AM' : 'PM';
    return `${formattedHour}:${minute} ${period}`;
  };
  const timeOptions = generateTimeOptions();
  // edit site
  const handleSubmit = (event) => {
    //event.preventDefault();
      setisLoading(true);
    /* validation */
    if (!site_name.trim()) {
            setSiteNameError('Please enter site name.');
        } else {
            setSiteNameError('');
        }
    if (!address.trim()) {
            setAddressError('Please enter your address.');
        } else {
            setAddressError('');
        }
            var formData = {
          "id":id,
          "site_name": site_name,
          "site_address": selectedCity,
          "suburb": selectedSuburb,
          "post_code": selectedPostcode,
          "country_name": country,
          "week_starts": week_starts,
          "time_zone": time_zone,
          "notes": notes,
          "working_hours": [
            {
              day_of_week: 'Monday',
              is_closed: isMondayOpen,
              fromTime: mondayFromTime,
              toTime: mondayToTime
            },
            {
              day_of_week: 'Tuesday',
              is_closed: isTueOpen,
              fromTime: tuesdayFromTime,
              toTime: tuesdayToTime
            },
            {
              day_of_week: 'Wednesday',
              is_closed: isWedOpen,
              fromTime: wednesdayFromTime,
              toTime: wednesdayToTime
            },
            {
              day_of_week: 'Thursday',
              is_closed: isThuOpen,
              fromTime: thursdayFromTime,
              toTime: thursdayToTime
            },
            {
              day_of_week: 'Friday',
              is_closed: isFriOpen,
              fromTime: fridayFromTime,
              toTime: fridayToTime
            },
            {
              day_of_week: 'Saturday',
              is_closed: isSatOpen,
              fromTime: saturdayFromTime,
              toTime: saturdayToTime
            },
            {
              day_of_week: 'Sunday',
              is_closed: isSunOpen,
              fromTime: sundayFromTime,
              toTime: sundayToTime
            }
          ]
        };

    axios.post('/api/update-site-data', formData)
      .then(response => {
        toast.success(response.success, { theme: "light" });
        loadgeneral();
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  }
  const [isMondayOpen, setIsMondayOpen] = useState(true);
  const [isTueOpen, setIsTueOpen] = useState(true);
  const [isWedOpen, setIsWedOpen] = useState(true);
  const [isThuOpen, setIsThuOpen] = useState(true);
  const [isFriOpen, setIsFriOpen] = useState(true);
  const [isSatOpen, setIsSatOpen] = useState(true);
  const [isSunOpen, setIsSunOpen] = useState(true);
  const handleCheckboxChange = (e, day) => {
    const isOpen = e.target.checked;

    // Update the state based on the selected day
    switch (day) {
      case 'Monday':
        setIsMondayOpen(isOpen);
        break;
      case 'Tuesday':
        setIsTueOpen(isOpen);
        break;
      case 'Wednesday':
        setIsWedOpen(isOpen);
        break;
      case 'Thursday':
        setIsThuOpen(isOpen);
        break;
      case 'Friday':
        setIsFriOpen(isOpen);
        break;
      case 'Saturday':
        setIsSatOpen(isOpen);
        break;
      case 'Sunday':
        setIsSunOpen(isOpen);
        break; 
      // Add more cases for other days if needed

      default:
        break;
    }
  };

    // useEffect to update dropdown values after state has been updated
    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isMondayOpen) {
        setMondayFromTime('closed');
      }
    }, [isMondayOpen]);

    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isTueOpen) {
        setTuesdayFromTime('closed');
      }
    }, [isTueOpen]);
    // Add more useEffect blocks for other days if needed

    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isWedOpen) {
        setWednesdayFromTime('closed');
      }
    }, [isWedOpen]);
    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isThuOpen) {
        setThursdayFromTime('closed');
      }
    }, [isThuOpen]);
    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isFriOpen) {
        setFridayFromTime('closed');
      }
    }, [isFriOpen]);
    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isSatOpen) {
        setSaturdayFromTime('closed');
      }
    }, [isSatOpen]);
    useEffect(() => {
      // If the checkbox is unchecked, set the dropdown values to 'closed'
      if (!isSunOpen) {
        setSundayFromTime('closed');
      }
    }, [isSunOpen]); 
 return (
    <div>
      <Card id="contactList">
        <CardHeader><h5 className="mb-0 text-primary">General</h5></CardHeader>
        <CardBody>
          <Col xxl={12}>

            <div className="live-preview">
              <Row className="gy-4">
                <Col xxl={12} md={12}>
                  <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-firstName"
                                  className="form-label"
                                >
                                  Site Name<span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text" value={site_name} onChange={(e) => setSiteName(e.target.value)}
                                  className={`form-control ${siteNameError ? 'is-invalid' : ''}`}
                                  id="billinginfo-firstName"
                                  placeholder="Enter first name"
                                />
                                {siteNameError && <div className="invalid-feedback">{siteNameError}</div>}
                              </div>
                </Col>
                <Col xxl={12} md={12}>
                  <div className="mb-3">
                                <Label
                                  htmlFor="billinginfo-lastName"
                                  className="form-label"
                                >
                                  Site Address<span className="text-danger">*</span>
                                </Label>
                                <GoogleAutocomplete className={`${addressError ? 'is-invalid' : ''}`}
                                apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                onPlaceSelected={(place) => residentialplace(place)}
                                options={{
                                  types: ["address"],
                                  componentRestrictions: {
                                    country: "au",
                                  },
                                }}
                                style={{
                                  width:"100%",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  zIndex: 9999,
                                }}
                              />
                              {addressError && <div className="invalid-feedback">{addressError}</div>}
                              </div>
                </Col>
                  <Col xxl={12} md={12}>
                  <div className="mb-3">
                                <Label htmlFor="day" className="form-label">
                                  At this site, the week starts on
                                </Label>
                                <Select
                                value={productCountry[0].options.find(option => option.value === week_starts)}
                                onChange={(selectedOption) => {
                                  handleSelectDay(selectedOption.value); // Pass the selected value to the function
                                }}
                                options={productCountry[0].options} // Extract the options array from the nested structure
                                id="day"
                              ></Select>
                              </div>
                              </Col>
                                <Col xxl={12} md={12}>
                            <div className="mb-3">
                              <Label htmlFor="state" className="form-label">
                                TimeZone
                              </Label>
                              <Select
                                id="state"
                                value={timeZoneOptions.find(option => option.value === time_zone)}
                                onChange={(selectedOption) => handleSelectTimeZone(selectedOption)}
                                options={timeZoneOptions}
                              ></Select>
                            </div>
                          </Col>
                <Col xxl={12} md={12}>
                  <div className="mb-3">
                                <Label htmlFor="state" className="form-label">
                                  Opening Hours
                                </Label>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isMondayOpen} onChange={() => setIsMondayOpen(!isMondayOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Monday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={mondayFromTime || 'default'}
                                onChange={(e) => {setMondayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsMondayOpen(false);
                                  }
                              }} disabled={!isMondayOpen}
                              >
                              <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isMondayOpen && mondayFromTime !== 'closed' && mondayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={mondayToTime || 'default'}
                                  onChange={(e) => {setMondayToTime(e.target.value);
                                  if(e.target.value === 'closed') {
                                    setIsMondayOpen(false);
                                  }
                                }}
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isTueOpen} onChange={() => setIsTueOpen(!isTueOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                        Tuesday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={tuesdayFromTime || 'default'}
                                onChange={(e) => {setTuesdayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsTueOpen(false);
                                  }
                              }} disabled={!isTueOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isTueOpen && tuesdayFromTime !== 'closed' && tuesdayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={tuesdayToTime || 'default'}
                                  onChange={(e) => {setTuesdayToTime(e.target.value);
                                  if(e.target.value === 'closed') {
                                    setIsTueOpen(false);
                                  }
                                }}
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isWedOpen} onChange={() => setIsWedOpen(!isWedOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Wednesday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={wednesdayFromTime || 'default'}
                                onChange={(e) => {setWednesdayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsWedOpen(false);
                                  }
                              }} disabled={!isWedOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isWedOpen && wednesdayFromTime !== 'closed' && wednesdayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={wednesdayToTime || 'default'}
                                  onChange={(e) => {setWednesdayToTime(e.target.value);
                                  if(e.target.value === 'closed') {
                                    setIsWedOpen(false);
                                  }}
                                }
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isThuOpen} onChange={() => setIsThuOpen(!isThuOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Thursday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={thursdayFromTime || 'default'}
                                onChange={(e) => {setThursdayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsThuOpen(false);
                                  }
                              }} disabled={!isThuOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isThuOpen && thursdayFromTime !== 'closed' && thursdayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={thursdayToTime || 'default'}
                                  onChange={(e) => {setThursdayToTime(e.target.value);
                                  if(e.target.value === 'closed') {
                                    setIsThuOpen(false);
                                  }}
                                }
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isFriOpen} onChange={() => setIsFriOpen(!isFriOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Friday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={fridayFromTime || 'default'}
                                onChange={(e) => {setFridayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsFriOpen(false);
                                  }}
                              } disabled={!isFriOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isFriOpen && fridayFromTime !== 'closed' && fridayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={fridayToTime || 'default'}
                                  onChange={(e) => {setFridayToTime(e.target.value);
                                  if(e.target.value === 'closed') {
                                    setIsFriOpen(false);
                                  }}
                                }
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isSatOpen} onChange={() => setIsSatOpen(!isSatOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Saturday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={saturdayFromTime || 'default'}
                                onChange={(e) => {setSaturdayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsSatOpen(false);
                                  }}
                              } disabled={!isSatOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isSatOpen && saturdayFromTime !== 'closed' && saturdayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={saturdayToTime || 'default'}
                                  onChange={(e) => {
                                    setSaturdayToTime(e.target.value);
                                    if(e.target.value === 'closed') {
                                    setIsSatOpen(false);
                                  }
                                  }}
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                          <Row>
                            <Col md={2}>                                                 
                                  <div className="form-check mb-3 m-2">
                                      <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isSunOpen} onChange={() => setIsSunOpen(!isSunOpen)}/>
                                      <Label className="form-check-label" for="formCheck1">
                                          Sunday
                                      </Label>
                                  </div>                                                        
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                value={sundayFromTime || 'default'}
                                onChange={(e) => {setSundayFromTime(e.target.value);
                                if(e.target.value === 'closed') {
                                    setIsSunOpen(false);
                                  }
                              }} disabled={!isSunOpen}
                              >
                                <option value="default" disabled>Select a time</option>
                                {timeOptions.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>

                              </div>
                            </Col>
                            {isSunOpen && sundayFromTime !== 'closed' && sundayFromTime !== 'open_24_hours' && (
                            <Col md={3}>
                              <div className="mb-3">
                                <select className="form-select"
                                  value={sundayToTime || 'default'}
                                  onChange={(e) => setSundayToTime(e.target.value)}
                                >
                                  <option value="default" disabled>Select a time</option>
                                  {timeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            )}
                          </Row>
                  </div>
                </Col>
                <Col xxl={12} md={12}>
                  <div>
                      <Label htmlFor="exampleFormControlTextarea5" className="form-label">Notes</Label>
                      <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <hr></hr>
          <ToastContainer closeButton={false} limit={1} theme="light" />
           {/* reloading the page */}
           {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                </div>
              )}
          <div className="text-center">
            <Button className="btn btn-success" onClick={handleSubmit} disabled={isUpdating}>Save</Button> &nbsp;
            {/*<Button className="btn btn-dark" onClick={handleReset}>Reset</Button>*/}
          </div>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
