import {
  Card,
  CardBody,
  Col,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
} from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../assets/scss/pages/_settings.scss";
import { get_cookie } from "../../helpers/get_cookie";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
} from "reactstrap";
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import classnames from "classnames";
import { format } from "date-fns";
import Select from "react-select";
import DOMPurify from "dompurify";
import { useParams} from 'react-router-dom'

const JobOffer = () => {
  document.title = "Job Offers | Get Rostered";
  const [modal, setModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [all, setall] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [jobStatusError, setJobStatusError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [job_status_id, setJobStatusId] = useState(""); // initialJobStatusId should be the initial value

  const authUserId = JSON.parse(get_cookie("workauthUser")).user_id;

  const params = useParams();
  const related_id = params.id
  console.log(related_id,'related_id_joboffer')

  const toggleModal1 = () => {
    setModal1(!modal1);
  };
  const toggleModalChangeStatus = () => {
    setModalChangeStatus(!modalChangeStatus);
  };
  useEffect(() => {
    loadTable(authUserId);
  }, []);

  function handleSelectStatus(selectedStatus) {
    setJobStatusId(selectedStatus);
  }

  const [col1, setcol1] = useState(false);
  const t_col1 = () => {
    setcol1(!col1);
  };

  const jobStatus = [
    { label: "Closed", value: 2 },
    { label: "Filled", value: 3 },
    { label: "On Hold", value: 4 },
  ];

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  //react
  const isContactSuccess = true;
  const [data, setData] = useState([]);
  const crmcontacts = data;
  const loadTable = (userId, value = "") => {
    let statusIds = [];
    if (value == "reset") {
      statusIds = all
        .filter(
          (status) => status.id === 1 || status.id === 2 || status.id === 3
        )
        .map((status) => ({ value: status.id, label: status.status_name }))
        .map((status) => status.value);
    } else {
      statusIds = selectedStatus
        ? selectedStatus.map((status) => status.value)
        : [];
    }
    const formData = {
      user_id: userId,
      status_id: statusIds,
    };

    setisLoading(true);
    axios
      .post("/job-offers-list", formData)
      .then((response) => {
        if (value != "search") {
          setall(response.data.statussys);
        }
        setData(response.data.offers);
        console.log(response.data.offers,'Joboffers');

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const statusupdate = (id, statusid) => {
    const formData = {
      id: id,
      offer_status: statusid,
    };

    setisLoading(true);
    axios
      .post("/update-offer-status", formData)
      .then((response) => {
        if (statusid === 2) {
          setSelectedRow((prevRow) => ({
            ...prevRow,
            offer_status_name: "Accepted",
          }));
        } else if (statusid === 3) {
          setSelectedRow((prevRow) => ({
            ...prevRow,
            offer_status_name: "Declined",
          }));
        }

        setButtonsVisible(false);
        loadTable(authUserId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    if (all && all.length > 0) {
      const defaultValues = all
        .filter(
          (status) => status.id === 1 || status.id === 2 || status.id === 3
        )
        .map((status) => ({ value: status.id, label: status.status_name }));
      setSelectedStatus(defaultValues);

      // Update statusOptions based on all.statussys
      const dynamicStatusOptions = all.map((status) => ({
        value: status.id,
        label: status.status_name,
      }));
      setStatusOptions(dynamicStatusOptions);
    }
  }, [all]);

  const handleStatusMulti = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };

  const handleSearch = () => {
    console.log("Search button clicked!");
    // Handle the search functionality, using selectedStatus
    console.log("Selected status for search:", selectedStatus);
    loadTable(authUserId, "search");
  };

  const handleReset = () => {
    // Set default values or clear selected options based on your requirements
    const defaultValues = all
      .filter((status) => status.id === 1 || status.id === 2 || status.id === 3)
      .map((status) => ({ value: status.id, label: status.status_name }));
    setSelectedStatus(defaultValues);
    loadTable(authUserId, "reset");
  };

  //input field values
  const [id, setId] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  // Date & Time Format
  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }, []);

  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios
      .get(`${"api/companies/destroy/"}${orderIds}`)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        loadTable();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusInfo, setStatusInfo] = useState(null);

  const handleRowClick = (rowData) => {
    console.log(rowData,'jobOfferId')
    setButtonsVisible(true);
    axios
      .get(`/get-status-info/${rowData.id}`)
      .then((response) => {
        setStatusInfo(response.data);
        setSelectedRow(rowData); // Set selectedRow after fetching statusInfo
        setModalOpen(true); // Open the modal after setting the selectedRow
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  //react
  const smscolumn = useMemo(
    () => [
      {
        Header: "Business",
        accessor: "business_name",
        filterable: false,
      },
      {
        Header: "Title",
        accessor: "Title",
        Cell: ({ row }) => (
          <div
            onClick={() => handleRowClick(row.original)}
            style={{ color: "#7037d6", cursor: "pointer" }}
          >
            {row.original.Title}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
      },
      {
        Header: "Salary  Type",
        accessor: "salary_range_type",
        filterable: false,
      },
      {
        Header: "Salary",
        accessor: (row) => {
          const salary = row.salary !== null ? `$${row.salary}` : "$00.00";
          return `${salary}`;
        },
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "offer_status_name",
        filterable: false,
      },
      {
        Header: "Post Date",
        accessor: "post_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }

          // Format the date using date-fns
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return formattedDate;
        },
      },
      {
        Header: "Close Date",
        accessor: "close_date",
        filterable: false,
        Cell: ({ value }) => {
          // Check if value is null or undefined
          if (value == null) {
            return "00/00/0000";
          }
          // Format the date using date-fns or any other date formatting library you prefer
          const formattedDate = format(new Date(value), "dd MMM yyyy");
          return formattedDate;
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  useEffect(()=>{
    if(crmcontacts.length > 0 && related_id) {
      console.log(crmcontacts.length > 0 , related_id, 'testData')
      const filteredData = crmcontacts.find(data => data.id == related_id)
      setSelectedRow(filteredData); // Set selectedRow after fetching statusInfo
      setModalOpen(true); // Open the modal after setting the selectedRow
      console.log(filteredData,'filteredData')
    }
  },[crmcontacts])
  // react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const changeVacancyStatus = (event) => {
    event.preventDefault();
    /* validation */
    if (job_status_id === "") {
      setJobStatusError("Please select job status.");
    } else {
      setJobStatusError("");
    }
    if (job_status_id === "") {
      return;
    }
    setisLoading(true);
    var formData = {
      user_id: authUserId,
      id: id,
      vacancy_status_id: job_status_id,
    };
    axios
      .post("/update-job-vacacncy-status", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setModalChangeStatus(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  return (
    <React.Fragment>
      <div>
        <div>
          <Modal
            isOpen={modalOpen}
            toggle={toggleModal}
            centered
            scrollable
            size="lg"
          >
            <ModalHeader className=" position-relative">
              {selectedRow && (
                <>
                  <div className="hstack justify-content-between">
                    <h5
                      className="modal-title w-100"
                      style={{ color: "#ee786b" }}
                    >
                      {selectedRow.business_name}
                    </h5>

                    <Button
                      color="dark"
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                      }}
                      onClick={toggleModal}
                    >
                      Close
                    </Button>
                  </div>
                  {/* <h6 className="text-muted"> {selectedRow.location}</h6> */}
                  <div className=" hstack align-items-center gap-1">
                    <i
                      className="ri-map-pin-line fs-14 "
                      style={{ color: "red" }}
                    ></i>
                    <span className="text-muted fs-14 fw-normal">
                      {selectedRow.location}
                    </span>
                  </div>
                </>
              )}
            </ModalHeader>
            <hr className="mb-0" />
            <ModalBody>
              {selectedRow && (
                <>
                  <div className="row mb-3">
                    <div className="col-lg-6 fs-15  hstack">
                      <span className="hstack ">
                        <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>
                        Salary Offered:
                      </span>
                      <span className=" ms-4">
                        {" "}
                        ${selectedRow.salary} per hour
                      </span>
                    </div>

                    <div className="col-lg-6">
                      {statusInfo &&
                        statusInfo &&
                        statusInfo.status_name === "Open" &&
                        buttonsVisible && (
                          <div className="hstack gap-3">
                            <Button
                              color="success"
                              className="px-4"
                              outline
                              onClick={() => setIsModalOpen(true)}
                            >
                              Accept
                            </Button>

                            <Button
                              color="danger"
                              className="px-4"
                              outline
                              onClick={() => {
                                setIsDeclineModalOpen(true);
                                // Optionally, you can also call the statusupdate function here if needed
                                // statusupdate(selectedRow.id, 3);
                              }}
                            >
                              Decline
                            </Button>
                          </div>
                        )}

                      {selectedRow.offer_status_name === "Accepted" ||
                      selectedRow.offer_status_name === "Declined" ? (
                        <div className="col-lg-12 fs-15  hstack">
                          <i className="text-info  bx bx-trophy fs-18 me-1"></i>
                          <span className="hstack" style={{ fontSize: "16px" }}>
                            Offer Status:{" "}
                          </span>
                          <span
                            className="ms-4"
                            style={{
                              color:
                                selectedRow.offer_status_name === "Accepted"
                                  ? "green"
                                  : "red",
                              fontSize: "16px",
                            }}
                          >
                            {selectedRow.offer_status_name}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-6 fs-15 hstack">
                      <span className="me-4 hstack">
                        {" "}
                        <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                        Offer Valid Until:
                      </span>
                      <span>
                        {selectedRow.offer_valid_until &&
                          new Date(
                            selectedRow.offer_valid_until
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                      </span>
                    </div>

                    <div className="col-lg-6 fs-15 hstack">
                      <span className="me-4 hstack">
                        {" "}
                        <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                        Proposed Job Start Date:
                      </span>
                      <span>
                        {selectedRow.job_start_date &&
                          new Date(
                            selectedRow.job_start_date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                      </span>
                    </div>
                  </div>

                  <hr className="mt-3" />
                  <div>
                    <div className="row mb-1">
                      <div className="col-md-12 ">
                        <h5 className="text-primary">{selectedRow.Title}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="">
                        <p className=" hstack fs-14">
                          {" "}
                          <i
                            className="ri-map-pin-line me-1"
                            style={{ color: "red" }}
                          ></i>
                          {selectedRow.location}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        {selectedRow.description
                          .split(".")
                          .map((paragraph, index) => (
                            <p
                              style={{ color: "#252525" }}
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(paragraph.trim()),
                              }}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div>
        <Modal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          centered
        >
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
            <h5 className="text-center">Accept Confirmation</h5>
          </ModalHeader>

          <ModalBody>
            <p className="mb-0">Ready to take on this new opportunity?</p>
            <p>
              Confirm to accept the job offer and embark on this exciting
              journey!
            </p>
          </ModalBody>
          <ModalFooter className="mt-2 hstack ">
            <Button
              color="success"
              onClick={() => {
                setIsModalOpen(false);
                statusupdate(selectedRow.id, 2);
              }}
            >
              Accept Offer
            </Button>
            <Button color="secondary" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={isDeclineModalOpen}
          toggle={() => setIsDeclineModalOpen(!isDeclineModalOpen)}
          centered
        >
          <ModalHeader
            toggle={() => setIsDeclineModalOpen(!isDeclineModalOpen)}
          >
            <h5 className="text-center"> Decline Confirmation</h5>
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Are you sure you want to decline this job offer?
            </p>
            <p>
              It's okay to take a step back if you feel this isn't the right fit
              for you.
            </p>
            <p>Confirm to decline the offer.</p>
          </ModalBody>
          <ModalFooter className="mt-2 hstack ">
            {/* Add buttons or actions for the Decline Confirmation modal */}
            <Button
              color="danger"
              onClick={() => {
                setIsDeclineModalOpen(!isDeclineModalOpen);
                statusupdate(selectedRow.id, 3);
              }}
            >
              Decline Offer
            </Button>
            <Button
              color="secondary"
              onClick={() => setIsDeclineModalOpen(!isDeclineModalOpen)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          orderId={selectedOrderId}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Col>
          <Card>
            <CardHeader>
              <div className="d-flex align-items-center flex-wrap gap-2">
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <h5
                    className="mb-0"
                    style={{
                      color: "black",
                      fontSize: "1.5em",
                      color: "black",
                    }}
                  >
                    <i className="bx bxs-user-account" /> Job Offers
                  </h5>
                </div>
              </div>
            </CardHeader>
          </Card>
          <Accordion id="default-accordion-example">
            <AccordionItem>
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button fw-semibold", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  Search Filters
                </button>
              </h2>
              <Collapse
                isOpen={col1}
                className="accordion-collapse"
                id="collapseOne"
              >
                <div className="accordion-body">
                  <Row className="d-flex justify-content-between">
                    <Col md={8}>
                      <div className="mb-3">
                        <Label for="leadStatusinput" className="form-label">
                          Job Offer Status
                        </Label>
                        <Select
                          isMulti={true}
                          onChange={handleStatusMulti}
                          options={statusOptions}
                          value={selectedStatus}
                        />
                      </div>
                    </Col>

                    <Col md={4} className="hstack gap-3">
                      <div className="mt-0">
                        <Button
                          color="primary"
                          style={{
                            // backgroundColor: "#7037d6",
                            // borderColor: "#7037d6",
                            marginTop: "10px",
                            padding: "7px 35px",
                          }}
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>

                      <div className="mt-0">
                        <Button
                          color="dark"
                          style={{
                            marginTop: "10px",
                            padding: "7px 35px",
                          }}
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </AccordionItem>
          </Accordion>

          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {isContactSuccess && crmcontacts.length ? (
                  <TableContainer
                    columns={smscolumn}
                    data={crmcontacts || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={100}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={true}
                    SearchPlaceholder="Search for Offers..."
                  />
                ) : (
                  crmcontacts.length === 0 && (
                    <p style={{ textAlign: "center", marginTop: "10px" }}>
                      No records found
                    </p>
                  )
                )}
              </div>
              <ToastContainer closeButton={false} limit={1} />
            </CardBody>
          </Card>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, .5)",
                    display: "flex",
                    boxShadow:"0 1px 2px rgba(56, 65, 74, 0.15)",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
          <Modal
            className="zoomIn"
            id="addSeller1"
            size="md"
            isOpen={modal1}
            toggle={toggleModal1}
            centered
          >
            <ModalHeader toggle={toggleModal1}></ModalHeader>
            <ModalBody>
              Enter the desired salary range, selecting between hourly, weekly,
              monthly or yearly rates. Ensure the minimum rate complies with the
              National Minimum Wage as outlined by Fair Work Australia. For the
              latest wage standards and guidelines, visit Fair Work Australia's
              Minimum Wages page. Consider setting rates that reflect the role's
              requirements and the qualifications needed, while keeping industry
              standards in mind."
            </ModalBody>
          </Modal>

          <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modalChangeStatus}
            toggle={toggleModalChangeStatus}
            centered
          >
            <ModalHeader toggle={toggleModalChangeStatus}>
              Change Job Status
            </ModalHeader>
            <div className="modal-content border-0 mt-3"></div>
            <ModalBody>
              <Form action="#">
                <Input
                  type="text"
                  value={id}
                  onChange={(e) => {
                    setId(e.target.value);
                  }}
                  id="firstnameInput"
                />
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label for="cityidInput" className="form-label">
                        Change Status
                      </Label>
                      <Select
                        className={`${jobStatusError ? "is-invalid" : ""}`}
                        value={job_status_id}
                        onChange={(selectedOption) => {
                          handleSelectStatus(selectedOption.value); // Make sure selectedOption is the correct structure
                          setJobStatusError(""); // Clear error if any
                        }}
                        options={jobStatus}
                        id="day"
                      ></Select>
                      {jobStatusError && (
                        <div className="invalid-feedback">{jobStatusError}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <Row>
                      <div className="hstack gap-2 justify-content-end">
                        <Col
                          lg={6}
                          className="d-flex justify-content-end gap-2"
                        >
                          <button
                            className="btn btn-link link-success text-decoration-none fw-medium"
                            data-bs-dismiss="modal"
                            onClick={toggleModalChangeStatus}
                          >
                            <i className="ri-close-line me-1 align-middle"></i>{" "}
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-light"
                            style={{ color: "#ec1388" }}
                            onClick={changeVacancyStatus}
                          >
                            <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                            Change Status
                          </button>
                        </Col>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default JobOffer;
