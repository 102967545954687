import React, { useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/pages/_settings.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { get_cookie } from "../../../helpers/get_cookie";
import "react-toastify/dist/ReactToastify.css";

export const DeleteAccount = () => {
  document.title = "Organisation Setting | Get Roster";
  const [modal, setModal] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [textError, setTextError] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate()
  const handleTextChange = (e) => {
    const value = e.target.value;
    setTypedText(value);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      company: (contact && contact.company) || "",
      designation: (contact && contact.designation) || "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });
  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
  };
  const handleDeleteAccount = () => {
    if (typedText === "DELETE") {
      setTextError(false);
      const postData = {
        userId: authUser_id,
      };
      console.log(postData);

      axios.post("/delete-account", postData).then(async (response) => {
        if (response.status == 200) {
          toast.warning(response.message, { theme: "light" });
          localStorage.setItem("expiredSessionRedirectUrl", "/jobroster");
          navigate('/logout')
          //   authContext.signOut();
        } else {
          toast.error(response.message, { theme: "light" });
        }
      });
    } else {
      setTextError(true);
      toast.error("Type 'DELETE' to confirm account deletion", {
        theme: "light",
      });
    }
  };

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  //*tenantid*
  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;

  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Delete Account</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              <h6>Sorry to see you go!</h6>
              <ul>
                <li>
                  If you are having a problem in Getrostered, please consider
                  contacting us about it before deleting your account.
                </li>
                <li className="mt-2">
                  Deleting your account will not delete your timesheet records
                  and chats. To do so please delete them individually.
                </li>
              </ul>
              <button className="btn btn-danger  float-end" onClick={() => toggleDelete()}>
                Delete account
              </button>
            </Col>
            <Modal isOpen={deleteModal} toggle={toggleDelete} centered>
              <ModalHeader>Confirm Account Deletion</ModalHeader>
              <ModalBody>
                <div
                  className="  text-danger round ps-0 py-3  p-2"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#ffc5c52e",
                    border: "0.5px solid pink",
                  }}
                >
                  <h6 className="text-danger ps-3">Warning!</h6>
                  <div className="ps-3">
                    <div className="hstack gap-1 align-items-start">
                      <i className="ri-close-circle-line"></i>
                      <span>This action cannot be undone.</span>
                    </div>
                    <div className="hstack gap-1 align-items-start">
                      <i className="ri-close-circle-line"></i>
                      <span>
                        {" "}
                        Your account and all related information will be deleted
                        from our site.
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="position-relative mt-3 auth-pass-inputgroup">
                    <label className="form-label" htmlFor="oldpassword-input">
                      Please type "DELETE" to confirm
                    </label>
                    <div className="hstack gap-2">
                      <Input
                        className={`form-control pe-5 oldpassword-input w-50 ${
                          passwordError ? "is-invalid" : ""
                        }`}
                        placeholder=""
                        id="oldpassword-input"
                        name="oldpassword"
                        value={typedText}
                        onChange={handleTextChange}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <button
                        type="button"
                        className="btn btn-danger rounded"
                        onClick={handleDeleteAccount}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-dark"
                        onClick={toggleDelete}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer />
    </div>
  );
};
