import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Button,
  Spinner,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobView = ({ jobId,applyJobModal,toggle,reloadData }) => {
  const user_id = JSON.parse(get_cookie("workauthUser")).user_id;

  //constants to store the main data
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Sanitize the HTML content
  const sanitizedDescription = DOMPurify.sanitize(jobData?.description || "");

  const loadData = () => {
    setIsLoading(true);
    let jobDetail;
    if (user_id != null && jobId != undefined ) {
      jobDetail = {
        job_id: jobId,
        user_id: user_id,
      };
      axios
        .post("/job-description-data", jobDetail)
        .then((res) => {
          if (res.message === "Success") {
            setJobData(res.Job_search);
            setIsLoading(false);
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
      loadData();
  }, [reloadData]);

  // -------- End ----------- //

  document.title = "Job Detail | Get Rostered Workforce";
  return (
    <React.Fragment>
      <div className="pt-4">
        <Container fluid>
          {!isLoading ? (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      <div className="hstack justify-content-between">
                        <h4 className="text-primary mb-0">
                          {jobData?.job_title}
                        </h4>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={toggle}
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="hstack justify-content-between mt-2">
                      <div className="hstack  h6 mb-0 gap-2">
                        <div className="me-2 hstack">
                          <i className="ri-map-pin-line me-1  fs-12 text-danger align-middle"></i>
                          <p className="fs-12 fw-normal">
                            {jobData?.location ? jobData?.location : "--"}
                          </p>
                        </div>
                        <div>
                          <span className="text-muted fw-normal">
                            Hourly Rate :
                          </span>{" "}
                          <span className=" fw-semibold ms-1">
                            {jobData?.rate}
                          </span>
                        </div>
                      </div>
                        <div
                          className="px-2 hstack gap-2"
                          // style={{
                          //   position: "absolute",
                          //   right: "10px",
                          //   top: "25px",
                          // }}
                        >
                          {jobData?.is_talent !== undefined &&
                          jobData?.is_talent === 1 ? (
                            <div
                              className="text-warning"
                              style={{
                                border: "1px solid #ffc24f",
                                borderRadius: "0.25rem",
                                padding: "8px",
                              }}
                            >
                              <i className=" ri-user-follow-line align-bottom me-2 fs-16"></i>
                              Already in this business's talent pool
                            </div>
                          ) : (
                            <div>
                              {jobData?.is_applied === null ? (
                                <Button
                                  color="success"
                                  onClick={() => applyJobModal(jobId)}
                                  outline
                                  className="btn-label"
                                  style={{
                                    paddingLeft: "35px",
                                    position: "relative",
                                  }}
                                >
                                  {" "}
                                  <i
                                    className="bx bxs-send align-middle fs-16"
                                    style={{
                                      width: "30px",
                                      position: "absolute",
                                      left: "0px",
                                      top: "10px",
                                    }}
                                  ></i>{" "}
                                  Apply for the Job{" "}
                                </Button>
                              ) : (
                                <div
                                  className="text-success"
                                  style={{
                                    border: "1px solid #28a745",
                                    borderRadius: "0.25rem",
                                    padding: "8px",
                                  }}
                                >
                                  <i className="ri-checkbox-circle-line align-bottom me-2 fs-16"></i>
                                  Application submitted for this job
                                </div>
                              )}
                            </div>
                          )}

                          {/* <Button outline  className="btn btn-info ">
                        <i className=" bx bxl-telegram me-1" /> Message
                      </Button> */}

                          <Button
                            color="info"
                            outline
                            className="btn-label"
                            style={{
                              paddingLeft: "35px",
                              position: "relative",
                            }}
                          >
                            {" "}
                            <i
                              className="bx bxl-telegram align-middle fs-16"
                              style={{
                                width: "30px",
                                position: "absolute",
                                left: "0px",
                                top: "10px",
                              }}
                            ></i>{" "}
                            Message{" "}
                          </Button>
                        </div>
                      </div>
                      <div className="hstack justify-content-between">
                        <p className="text-muted fw-normal mt-2">
                          Job Published:{" "}
                          <span className="fw-semibold text-dark fw-normal">
                            {jobData?.posted_date}
                          </span>
                        </p>
                      </div>
                      <div className="mt-4">
                        <p
                          style={{ lineHeight: "1.5rem" }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizedDescription,
                          }}
                        ></p>
                      </div>
                      <div className="pt-3">
                        <h5>Skills</h5>
                        <div className="my-2">
                          {jobData?.job_skills &&
                          jobData?.job_skills.length > 0 ? (
                            jobData?.job_skills?.map((skill, index) => (
                              <span
                                key={index}
                                className="badge badge-soft-info fs-12 me-2 p-2"
                              >
                                {skill}
                              </span>
                            ))
                          ) : (
                            <Badge className="bg-soft-dark fs-12 me-2">
                              None
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* <Col xs={12} className="col-lg-auto ">
                    <div className="hstack gap-2 mt-3"></div>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <div className="mt-0 pt-4 px-2">
                      <h5>About the Business</h5>
                      <div className="hstack gap-4 align-items-center mt-3">
                        <h6 className="mt-0 fw-semibold">
                          {jobData?.business_name}{" "}
                        </h6>
                        <div className="hstack gap-2 ">
                          <i className="text-danger fs-18 bx bx-map-pin"></i>
                          <h6 className="mt-0 text-muted fw-normal">
                            {jobData?.suburb}
                          </h6>
                        </div>
                      </div>
                      <div
                        className="mt-3"
                        style={{ maxHeight: "200px", overflow: "scroll" }}
                      >
                        {jobData?.about_the_business}
                      </div>
                    </div>
                    {/* <div className="text-end">
                     
                    </div> */}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      <Row className="text-primary mb-1 placeholder-glow hstack justify-content-between">
                        <Col xs={8}>
                          <span className="placeholder col-4 bg-dark"></span>
                        </Col>
                        <Col xs={4}>
                          <div className="hstack justify-content-end">
                            <button
                              className="btn btn-success me-2 disabled placeholder"
                              onClick={() => {}}
                            >
                              <i className="bx bxs-send me-1" /> Apply for the
                              Job
                            </button>
                            <button className="btn btn-light text-info disabled placeholder">
                              <i className=" bx bxl-telegram me-1" /> Message
                            </button>
                          </div>
                        </Col>
                      </Row>

                      <div className="hstack  h6 gap-2 placeholder-glow">
                        <span className="placeholder col-2 bg-dark"></span>
                        <div className="placeholder-glow col-4">
                          <span>
                            <b>Hourly Rate :</b>
                          </span>{" "}
                          <span className="placeholder col-6 bg-dark"></span>
                        </div>
                      </div>
                      <p className="text-muted placeholder-glow">
                        <span className="placeholder col-1 bg-dark"></span>
                      </p>
                      <div className="mt-4">
                        <p className=" placeholder-glow">
                          <span className="placeholder col-6 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                        </p>
                      </div>
                      <div className="pt-3">
                        <h5>Skills</h5>
                        <div className="my-2 placeholder-glow">
                          <span className="placeholder col-1 bg-dark me-2"></span>
                          <span className="placeholder col-1 bg-dark"></span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* <Col xs={12} className="col-lg-auto ">
                    <Link to='#' tabIndex='-1' className ="btn btn-primary disabled placeholder col-6" ></Link>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <div className="mt-0 pt-4 px-2">
                      <h5>About the Business</h5>
                      <div className="hstack gap-4 align-items-center mt-2 placeholder-glow">
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                      </div>
                      <div
                        className="mt-3 placeholder-glow"
                        style={{ maxHeight: "200px", overflow: "scroll" }}
                      >
                        <span className="placeholder col-6 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Container>
      </div>
      {/* <ToastContainer closeButton={false} limit={1} /> */}
    </React.Fragment>
  );
};

export default JobView;
