import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import SettingsTabControl from "./TabComponents/SettingsTabControl";
import SettingsTabContent from "./TabComponents/SettingsTabContent";
import { SettingsTabPages } from "./Constants/SettingsConstants";
import { useParams } from "react-router-dom";
import { get_cookie } from "../../helpers/get_cookie";

// import Components
import BreadCrumb from "../../Components/Common/BreadCrumb";
function Settings() {
  document.title = "Settings | Get Roster";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);

  console.log(tab, "tab");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const is_resetPassword = JSON.parse(
          get_cookie("workauthUser")
        ).is_google_login;
        console.log(is_resetPassword, "is_resetPassword");
        setShowResetPassword(is_resetPassword === true ? true: false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [tab]);

  console.log(showResetPassword, "showResetPassword");
  useEffect(() => {
    // Handle tab parameter if it's provided in the URL
    if (tab === "organisation-settings") {
      setActiveTab(showResetPassword && SettingsTabPages.ResetPassword);
    } else if (tab === "privacy-policy") {
      setActiveTab(SettingsTabPages.PrivacyPolicy);
    } else if (tab === "terms-conditions") {
      setActiveTab(SettingsTabPages.TermsOfUse);
    } else if (tab === "delete-account") {
      setActiveTab(SettingsTabPages.DeleteAccount);
    } else {
      setActiveTab(SettingsTabPages.DeleteAccount);
    }
  }, [tab, showResetPassword]);
  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.ResetPassword:
        return "Reset Password";
      case SettingsTabPages.DeleteAccount:
        return "Delete Account";
      case SettingsTabPages.TermsOfUse:
        return "Terms & Conditions";
      case SettingsTabPages.PrivacyPolicy:
        return "Privacy policy";
      default:
        return "";
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={getPageTitle()} pageTitle="" />
        <Row>
          <Col md="3">
            <SettingsTabControl
              activeTabSetter={setActiveTab}
              activeTab={activeTab}
              showResetPassword={showResetPassword}
            />
          </Col>
          {activeTab ? (
            <Col md="9">
              <SettingsTabContent activeTab={activeTab} />
            </Col>
          ) : (
            <Col md="9">
              <Card className="p-3 text-bold"> Permission Not Granted.</Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
export default Settings;
