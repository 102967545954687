import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Collapse,
  Label,
  Input,
  Button,
  Badge,
  Form,
  Tooltip,
  Spinner,
  Modal,
  Table,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Alert,
} from "reactstrap";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../setCanvasPreview";
import { Swiper, SwiperSlide } from "swiper/react";
import profileBg from "../../assets/images/profile-bg.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useState, useRef, useEffect } from "react";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Flatpickr from "react-flatpickr";
import GoogleAutocomplete from "react-google-autocomplete";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import classnames from "classnames";
import industryDummy from "../../assets/images/industry.png";
import moment from "moment/moment";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import portdummy from "../../assets/images/portdummy.png";
import workHistory from "../../assets/images/freepik/circle-briefcase.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import "react-image-crop/dist/ReactCrop.css";
import Rating from "react-rating";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";

// Profile edit
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

// Portfolio
const ASPECT_RATIO_P = 1;
const MIN_DIMENSION_P = 100;

const MyProfile = () => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);
  const [error, setError] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [review, setReview] = useState([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [address, setAddress] = useState("");
  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadFileName(file.name);
    }

    if (!file) return;

    // Validate file type
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!validFileTypes.includes(file.type)) {
      setError("Invalid file type. Please select a JPEG or PNG image.");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");

        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError(
            `Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`
          );
          return setImgSrc("");
        }
      });
      // Set imgSrc state if validation passes
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  // Image load handler
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(initialCrop, width, height);
    console.log(centeredCrop, "centeredCrop");
    setCrop(centeredCrop);
  };

  // Update canvas preview
  const updateCanvasPreview = (crop) => {
    if (
      imgRef.current &&
      previewCanvasRef.current &&
      crop &&
      crop.width &&
      crop.height
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      const canvas = previewCanvasRef.current;
      const dataUrl = canvas.toDataURL("image/jpeg");
      setCroppedImgSrc(dataUrl);
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrc && crop) {
      updateCanvasPreview(crop);
    }
  }, [imgSrc, crop]);

  // Add Portfolio
  const imgRefPortfolio = useRef(null);
  const previewCanvasRefPortfolio = useRef(null);
  const [imgSrcPortfolio, setImgSrcPortfolio] = useState("");
  const [cropPortfolio, setCropPortfolio] = useState();
  const [croppedImgSrcPortfolio, setCroppedImgSrcPortfolio] = useState("");
  const [errorPortfolio, setErrorPortfolio] = useState("");
  const [uploadFileNamePortfolio, setUploadFileNamePortfolio] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  // Update Portfolio
  const imgRefPortEdit = useRef(null);
  const previewCanvasRefPortEdit = useRef(null);
  const [filePortfolio, setFilePortfolio] = useState("");
  const [errorPortEdit, setErrorPortEdit] = useState("");
  const [uploadFileNameEdit, setUploadFileNamePortEdit] = useState("");

  const onSelectFilePortfolio = (e, data = false) => {
    const file = e.target.files?.[0];
    // add
    if (!data) {
      console.log("if");
      if (!file) return;

      if (file && file?.name) {
        setUploadFileNamePortfolio(file.name);
      }

      // Validate file type
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      setErrorPortfolio("");
      if (!validFileTypes.includes(file.type)) {
        setErrorPortfolio(
          "Please select a valid image format (jpeg, jpg, png)."
        );
        setImgSrcPortfolio("");
        setIsPortImageSelected(false);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || "";
        imageElement.src = imageUrl;
        imageElement.addEventListener("load", (e) => {
          setErrorPortfolio(""); // Clear any previous errors

          const { naturalWidth, naturalHeight } = e.currentTarget;
          console.log(
            naturalWidth,
            naturalHeight,
            MIN_DIMENSION_P,
            "MIN_DIMENSION_P123"
          );

          if (
            naturalWidth < MIN_DIMENSION_P ||
            naturalHeight < MIN_DIMENSION_P
          ) {
            setErrorPortfolio(
              `Image must be at least ${MIN_DIMENSION_P} x ${MIN_DIMENSION_P} pixels.`
            );
            setImgSrcPortfolio("");
            setIsPortImageSelected(false); // Clear image source if dimensions are not met
            return;
          }
        });
        console.log(imageUrl, "imageUrl43554");
        setImgSrcPortfolio(imageUrl);
        setIsPortImageSelected(true);
      });

      reader.readAsDataURL(file);
    } else {
      console.log("else");

      if (!file) return;

      const updateddata = [...userPortfolio];
      const taskIndex = updateddata.findIndex((task) => task.id === data);

      setFilePortfolio(file);
      console.log(file, "file131");
      if (file && file?.name) {
        setUploadFileNamePortEdit(file.name);
      }

      // Validate file type
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      setErrorPortfolio("");
      if (!validFileTypes.includes(file.type)) {
        setErrorPortfolio(
          "Please select a valid image format (jpeg, jpg, png)."
        );
        updateddata[taskIndex].imageurl = null;
        setImgSrcPortfolio("");
        setUserPortfolio(updateddata);
        // setIsPortImageSelected(false);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || "";
        imageElement.src = imageUrl;
        imageElement.addEventListener("load", (e) => {
          setErrorPortEdit(""); // Clear any previous errors

          const { naturalWidth, naturalHeight } = e.currentTarget;
          if (
            naturalWidth < MIN_DIMENSION_P ||
            naturalHeight < MIN_DIMENSION_P
          ) {
            setErrorPortEdit(
              `Image must be at least ${MIN_DIMENSION_P} x ${MIN_DIMENSION_P} pixels.`
            );
            updateddata[taskIndex].imageurl = null;
            setImgSrcPortfolio("");
            setUserPortfolio(updateddata);

            // setIsPortImageSelected(false); // Clear image source if dimensions are not met
            return;
          }
        });
        console.log(imageUrl, "imageUrl213");
        updateddata[taskIndex].imageurl = imageUrl;
        setImgSrcPortfolio(imageUrl);
        setUserPortfolio(updateddata);
        // setIsPortImageSelected(true);
      });
      console.log(userPortfolio, "imageUrl12324");
      reader.readAsDataURL(file);
    }
  };

  const onImageLoadPortfolio = (e, id = false) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION_P / width) * 100;
    setCropPortfolio("");
    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO_P,
      width,
      height
    );
    const centeredCrop = centerCrop(initialCrop, width, height);
    setCropPortfolio(centeredCrop);

    // Ensure canvas reference is set before using it
    if (id && previewCanvasRefPortEdit.current) {
      setCanvasPreview(
        e.currentTarget, // HTMLImageElement
        previewCanvasRefPortEdit.current, // HTMLCanvasElement
        convertToPixelCrop(centeredCrop, width, height)
      );
      const canvas = previewCanvasRefPortEdit.current;
      const dataUrl = canvas.toDataURL("image/jpeg");

      const updateddata = [...userPortfolio];
      const taskIndex = updateddata.findIndex((task) => task.id === id);
      if (taskIndex !== -1) {
        updateddata[taskIndex].image = dataUrl;
        setUserPortfolio(updateddata);
      }
    }
  };

  const updateCanvasPreviewPortfolio = (crop, id = false) => {
    if (!id) {
      if (
        imgRefPortfolio.current &&
        previewCanvasRefPortfolio.current &&
        crop &&
        crop.width &&
        crop.height
      ) {
        setCanvasPreview(
          imgRefPortfolio.current, // HTMLImageElement
          previewCanvasRefPortfolio.current, // HTMLCanvasElement
          convertToPixelCrop(
            crop,
            imgRefPortfolio.current.width,
            imgRefPortfolio.current.height
          )
        );

        const canvas = previewCanvasRefPortfolio.current;
        const dataUrl = canvas.toDataURL("image/jpeg");
        setCroppedImgSrcPortfolio(dataUrl);
      }
    } else {
      if (
        imgRefPortEdit.current &&
        previewCanvasRefPortEdit.current &&
        crop &&
        crop.width &&
        crop.height
      ) {
        setCanvasPreview(
          imgRefPortEdit.current, // HTMLImageElement
          previewCanvasRefPortEdit.current, // HTMLCanvasElement
          convertToPixelCrop(
            crop,
            imgRefPortEdit.current.width,
            imgRefPortEdit.current.height
          )
        );

        const canvas = previewCanvasRefPortEdit.current;
        const dataUrl = canvas.toDataURL("image/jpeg");

        const updateddata = [...userPortfolio];
        const taskIndex = updateddata.findIndex((task) => task.id === id);
        if (taskIndex !== -1) {
          updateddata[taskIndex].image = dataUrl;
          setUserPortfolio(updateddata);
        }
      }
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrcPortfolio && cropPortfolio) {
      updateCanvasPreviewPortfolio(cropPortfolio);
    }
  }, [imgSrcPortfolio, cropPortfolio]);

  // const {  quillRef } = useQuill();
  // Sanitize the HTML content
  // const sanitizedDescription = DOMPurify.sanitize(profileSummary || "");
  // const sanitizedEmploymentDescription = DOMPurify.sanitize(profileSummary || "");
  const config = {
    ALLOWED_TAGS: ["ul", "ol", "li", "br", "strong"], // Allow essential tags for formatting
  };

  const [userId, setUserId] = useState(null);
  const [username, setUserName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [editsubalone, seteditsubalone] = useState();
  const [email, setEmail] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [suburb, setSuburb] = useState(null);
  const [country, setCountry] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [suburbWithState, setSuburbWithState] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [street, setStreet] = useState(null);
  const [isPortfolioEdit, setIsPortfolioEdit] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [selectedState, setselectedState] = useState([]);
  const [editstate, seteditstate] = useState([]);
  const [radius, setRadius] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [editsuburb, seteditsuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [editpostcode, seteditpostcode] = useState();
  const [statelocation, setstatelocation] = useState([]);
  const [ressuburb, setressuburb] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [userProfileImg, setUserProfileImg] = useState("");
  const [suburbAlone, setselectedSuburbAlone] = useState();
  const [userRating, setuserRating] = useState(0);
  const [skills, setSkills] = useState([]);
  const [industryNames, setIndustryNames] = useState([]);
  const [allIndustryNames, setAllIndustryNames] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [userSkill, setUserSkill] = useState(null);
  const [tags, setTags] = useState([]);
  const [suggestion, setSuggestions] = useState([]);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [selectedSingle, setSelectedSingle] = useState(null);

  const [selected, setSelected] = useState(["Barista"]);

  // side section states
  const [workforcePreferences, setWorkforcePreferences] = useState(null);
  const [hourlyRate, setHourlyRate] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [preferredWorkType, setPreferredWorkType] = useState("");
  const [workPreferrences, setWorkPreferrences] = useState([]);
  const [availableStartDate, setAvailableStartDate] = useState(null);
  const [profileSummary, setProfileSummary] = useState(null);
  const [profileTitle, setProfileTitle] = useState(null);
  const [currentAvailability, setCurrentAvailability] = useState(null);
  const [employeeAddress, setEmployeeAddress] = useState();
  const [showProfile, setShowProfile] = useState(false);
  const [showEducation, setShowEducation] = useState(false);
  const [showCertification, setShowCertification] = useState(false);
  const [showSection1, setShowSection1] = useState(false);
  const [showSection3, setShowSection3] = useState(false);
  const [oldpassword, setoldpassword] = useState("");
  const [showProfession, setShowProfession] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);
  const [showVolunteerAvailability, setShowVolunteerAvailability] =
    useState(false);
  const [editlatitude, seteditlatitude] = useState("");
  const [editlongitude, seteditlongitude] = useState("");
  const [showlocation, setshowlocation] = useState(false);
  const [userhistory, setUserHistory] = useState();
  const [userlocation, setUserlocation] = useState(null);

  // Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [workid, setWorkid] = useState();
  const [userEducation, setUserEducation] = useState(null);
  const [userCertificates, setUserCertificates] = useState(null);
  const [userPortfolio, setUserPortfolio] = useState(null);

  const [instituteNameError, setinstituteNameError] = useState(false);
  const [edustartError, setedustartError] = useState(false);
  const [eduendError, seteduendError] = useState(false);

  //section 3 verification
  const [documents, setDocuments] = useState();
  const [docType, setDocType] = useState([]);
  const [verificationDocuments, setVerificationDocuments] = useState([]);
  const [availableDoc, setAvailableDoc] = useState([]);
  const [issuedDate, setIssuedDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [selectedDocType, setSelectedDocType] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [editemployeeValue, setEditemployeeValue] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [issuingAuthority, setIssuingAuthority] = useState("");
  const [isEditVerification, setEditVerification] = useState(false);
  const [isDocUrl, setIsDocUrl] = useState(false);
  const [imagesel, setImage] = useState("");
  const [selectedLatitude, setselectedLatitude] = useState("");
  const [selectedLongitude, setselectedLongitude] = useState("");

  const [uploadedVerificationImage, setVerificationUploadedImage] =
    useState("");
  const fileInputRef = useRef(null);
  const fileEditInputRef = useRef(null);
  const verificationFileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [deleteProfilePicture, setDeleteProfilePicture] = useState(false);
  const [imageDimensionsValid, setImageDimensionsValid] = useState(true);
  const [fileExtensionFormat, setFileExtensionFormat] = useState("");
  const [documentId, setDocumentId] = useState();
  const [verifiedTooltipOpen, setVerifiedTooltipOpen] = useState(false);
  const [pendingTooltipOpen, setPendingTooltipOpen] = useState(false);
  const [unverifiedTooltipOpen, setunVerifiedTooltipOpen] = useState(false);
  const [missingDocIds, setMissingDocIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // edit button states
  const [isLocationEditClicked, setIsLocationEditClicked] = useState(false);
  const [isVerificationEditClicked, setIsVerificationEditClicked] =
    useState(false);
  const [isEmployeeEditClicked, setIsEmployeeEditClicked] = useState(false);
  const [isEducationEditClicked, setIsEducationEditClicked] = useState(false);
  const [isCertificateEditClicked, setIsCertificateEditClicked] =
    useState(false);
  const [isPortfolioEditClicked, setIsPortfolioEditClicked] = useState(false);

  // preferences
  const [employeeChecked, setEmployeeChecked] = useState(false);
  const [contractorChecked, setContractorChecked] = useState(false);
  const [volunteerChecked, setVolunteerChecked] = useState(false);

  const [summaryEditorContent, setSummaryEditorContent] = useState("");

  // Reset Password
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordoldshow, setPasswordOldShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [addressFocused, setAddressFocused] = useState(false);
  const [addPreffFocused, setAddPreffFocused] = useState(false);
  // const [updateAvatar, setUpdateAvatar] = useState("");

  const validatePassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value || !isValid) {
      setPasswordError(
        "Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value) {
      setConfirmPasswordError("Please Re-enter your password.");
      return false;
    } else if (value != password || !isValid) {
      setConfirmPasswordError("Passwords do not match!");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const handleoldpassword = (e) => {
    const value = e.target.value;
    setoldpassword(value);
    setPasswordOldShow(true); // Set to true temporarily to show password text
    setTimeout(() => {
      setPasswordOldShow(false); // Set back to false after a short delay
    }, 1000); // Adjust the delay as needed
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordShow(true); // Set to true temporarily to show password text
    setTimeout(() => {
      setPasswordShow(false); // Set back to false after a short delay
    }, 1000); // Adjust the delay as needed
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setConfirmPasswordShow(true); // Set to true temporarily to show password text
    setTimeout(() => {
      setConfirmPasswordShow(false); // Set back to false after a short delay
    }, 1000); // Adjust the delay as needed
  };

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };

  const toggleoldpasswordVisibility = () => {
    setPasswordOldShow(!passwordoldshow);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    /* validation */
    if (
      !validatePassword(password) ||
      !validateConfirmPassword(confirmPassword)
    ) {
      // Validation failed, don't proceed with submission
      // setIsLoading(false);
      return;
    }

    var formData = new FormData();
    formData.append("password", oldpassword);
    formData.append("newpassword", confirmPassword);
    formData.append("user_id", authUser_id);

    axios
      .post("/reset-password", formData)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.password_status);

          toast.success(response.message, { theme: "light" });
        } else {
          toast.error("Password change failed : Incorrect old password");
        }
        fetchUserProfile("initial");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        console.error(error);
        toast.error("New password cannot be the same as the old password");
      });
  };

  // Availability Tabs
  const [availabilityTab, setAvailabilityTab] = useState("1");
  const availabilityToggle = (tab) => {
    if (availabilityTab !== tab) {
      setAvailabilityTab(tab);
    }
  };

  // edit icon toggle ()
  const handleLocationEditClick = () => {
    setIsLocationEditClicked(!isLocationEditClicked);
  };
  const handleVerificationEditClick = () => {
    setIsVerificationEditClicked(!isVerificationEditClicked);
  };
  const handleEmployeeEditClick = () => {
    setIsEmployeeEditClicked(!isEmployeeEditClicked);
  };
  const handleEducationEditClick = () => {
    setIsEducationEditClicked(!isEducationEditClicked);
  };
  const handleCertificateEditClick = () => {
    setIsCertificateEditClicked(!isCertificateEditClicked);
  };
  const handlePortfolioEditClick = () => {
    setIsPortfolioEditClicked(!isPortfolioEditClicked);
  };

  // S3 Bucket URL
  const s3ImgUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";
  const s3portfolioUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-portfolio/";

  const autocompleteService = useRef(null);
  const itemIdRef = useRef(null);

  const handleSummaryEditorChange = (content) => {
    setSummaryEditorContent(content);
  };

  const handleKeyUp = async (e, inputType, item = "") => {
    const input = e.target.value || inputValue;
    console.log("Input value:", input);
    console.log("inputValue:", inputValue);
    // Set the itemIdRef to the current item.id
    itemIdRef.current = item.id;

    if (e.key === "Enter") {
      e.preventDefault();
      try {
        const predictions = await getPlacePredictions(input, inputType);

        if (predictions && predictions.length > 0) {
          const firstPlaceId = predictions[0].place_id;
          const request = {
            placeId: firstPlaceId,
            fields: [
              "address_components",
              "formatted_address",
              "geometry",
              "place_id",
            ],
          };

          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if (inputType === "location") {
                handlePlaceSelected(place);
              } else if (inputType === "suburb") {
                selectSuburbOption(place);
              } else if (inputType === "editsuburb") {
                // Access the itemIdRef.current to get the correct item.id
                editlocationfield(place, { id: itemIdRef.current, ...item });
              }
            } else {
              console.error("Error fetching place details:", status);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    }
  };

  const getPlacePredictions = (input, inputType) => {
    return new Promise((resolve, reject) => {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }

      const types = inputType === "location" ? ["geocode"] : ["(regions)"];

      autocompleteService.current.getPlacePredictions(
        {
          input,
          types,
          componentRestrictions: { country: "au" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  //Education
  const [dataEdu, setDataEdu] = useState({
    achievements: "",
    degree_name: "",
    start_date: "",
    end_date: "",
    institution_name: "",
    field_of_study: "",
  });

  const [datahistory, sethistory] = useState({
    role: "",
    company_name: "",
    location: "",
    degree: "",
    start_date: "",
    end_date: "",
    description: "",
    checkbox: "",
  });

  const [datalocation, setlocation] = useState({
    suburb: "",
    radius_distance: "",
  });

  const [portuploadImage, setPortUploadedImage] = useState("");
  const [portobjectUrl, setPortObjectUrl] = useState("");
  const [isPortImageSelected, setIsPortImageSelected] = useState(false);
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());
  const [uploadedImage, setUploadedImage] = useState(""); // State for sending to backend
  // State to check if an image is selected
  const [logoError, setLogoError] = useState("");
  const [objectUrl, setObjectUrl] = useState(""); // State to store the object URL for preview
  // Preferred Availability Timings
  const [preferredAvailability, setPreferredAvailability] = useState();
  // Volunteering Availability Timings
  const [volunteeringAvailability, setvolunteeringAvailability] = useState();

  const [volunteerMondayFromTime, setVolunteerMondayFromTime] =
    useState("unavailable");
  const [volunteerMondayToTime, setVolunteerMondayToTime] = useState();
  const [volunteerTuesdayFromTime, setVolunteerTuesdayFromTime] =
    useState("unavailable");
  const [volunteerTuesdayToTime, setVolunteerTuesdayToTime] = useState();
  const [volunteerWednesdayFromTime, setVolunteerWednesdayFromTime] =
    useState("unavailable");
  const [volunteerWednesdayToTime, setVolunteerWednesdayToTime] = useState();
  const [volunteerThursdayFromTime, setVolunteerThursdayFromTime] =
    useState("unavailable");
  const [volunteerThursdayToTime, setVolunteerThursdayToTime] = useState();
  const [volunteerFridayFromTime, setVolunteerFridayFromTime] =
    useState("unavailable");
  const [volunteerFridayToTime, setVolunteerFridayToTime] = useState();
  const [volunteerSaturdayFromTime, setVolunteerSaturdayFromTime] =
    useState("unavailable");
  const [volunteerSaturdayToTime, setVolunteerSaturdayToTime] = useState();
  const [volunteerSundayFromTime, setVolunteerSundayFromTime] =
    useState("unavailable");
  const [volunteerSundayToTime, setVolunteerSundayToTime] = useState();

  const [isVolunteerMondayAvailable, setIsVolunteerMondayAvailable] =
    useState(true);
  const [isVolunteerTueAvailable, setIsVolunteerTueAvailable] = useState(true);
  const [isVolunteerWedAvailable, setIsVolunteerWedAvailable] = useState(true);
  const [isVolunteerThuAvailable, setIsVolunteerThuAvailable] = useState(true);
  const [isVolunteerFriAvailable, setIsVolunteerFriAvailable] = useState(true);
  const [isVolunteerSatAvailable, setIsVolunteerSatAvailable] = useState(true);
  const [isVolunteerSunAvailable, setIsVolunteerSunAvailable] = useState(true);

  const [mondayFromTime, setMondayFromTime] = useState("unavailable");
  const [mondayToTime, setMondayToTime] = useState();
  const [tuesdayFromTime, setTuesdayFromTime] = useState("unavailable");
  const [tuesdayToTime, setTuesdayToTime] = useState();
  const [wednesdayFromTime, setWednesdayFromTime] = useState("unavailable");
  const [wednesdayToTime, setWednesdayToTime] = useState();
  const [thursdayFromTime, setThursdayFromTime] = useState("unavailable");
  const [thursdayToTime, setThursdayToTime] = useState();
  const [fridayFromTime, setFridayFromTime] = useState("unavailable");
  const [fridayToTime, setFridayToTime] = useState();
  const [saturdayFromTime, setSaturdayFromTime] = useState("unavailable");
  const [saturdayToTime, setSaturdayToTime] = useState();
  const [sundayFromTime, setSundayFromTime] = useState("unavailable");
  const [sundayToTime, setSundayToTime] = useState();
  const [showToast, setShowToast] = useState(false);

  const [isMondayAvailable, setIsMondayAvailable] = useState(true);
  const [isTueAvailable, setIsTueAvailable] = useState(true);
  const [isWedAvailable, setIsWedAvailable] = useState(true);
  const [isThuAvailable, setIsThuAvailable] = useState(true);
  const [isFriAvailable, setIsFriAvailable] = useState(true);
  const [isSatAvailable, setIsSatAvailable] = useState(true);
  const [isSunAvailable, setIsSunAvailable] = useState(true);

  //validation params
  const [profileSummaryError, setProfileSummaryError] = useState("");
  const [profileTitleError, setProfileTitleError] = useState("");
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [locationError, setLocationError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const avatarUrl = useRef(
    "https://avatarfiles.alphacoders.com/161/161002.jpg"
  );

  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  const [dataCert, setDataCert] = useState({
    certification_name: "",
    issuing_authority: "",
    date_issued: "",
    expiration_date: "",
    document_url: "",
  });

  const [dataPort, setDataPort] = useState({
    name: "",
    description: "",
    image: "",
  });

  const [profileModal, setProfileModal] = useState(false);

  function profile_modal() {
    setProfileModal(!profileModal);
  }

  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;

  useEffect(() => {
    fetchUserProfile("initial");
  }, []);

  //UseEffect for fetching VERIFICATION DETAILS
  const loadVerificationData = () => {
    const formData = {
      user_id: authUser_id,
    };
    axios
      .post("/get-verification-data", formData)
      .then((res) => {
        setAvailableDoc(res.document_type_names);
      })
      .then((err) => {
        console.error(err);
      });
    axios
      .post("/document-type-names", formData)
      .then((res) => {
        setDocType(res.documentData);
        setDocuments(res.documentData);
      })
      .then((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    loadVerificationData();
  }, []);

  useEffect(() => {
    // Whenever userProfileImg changes, update the timestamp
    setImageTimestamp(Date.now());
  }, [userProfileImg]);

  useEffect(() => {
    const availableDocIds = availableDoc.map((doc) => doc.id);

    // Filter documents from setDocuments that are not in setAvailableDoc
    const missingDocIds = documents
      ?.filter((doc) => !availableDocIds.includes(doc.id))
      .map((doc) => doc.id);
    setMissingDocIds(missingDocIds);
  }, [availableDoc, documents]);

  useEffect(() => {
    const newSuggestions = skills.map((skill) => ({
      id: skill.id,
      name: skill.name,
    }));
    setSuggestions(newSuggestions);
  }, [skills]);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const toggleVerifiedTooltip = () => {
    setVerifiedTooltipOpen(!verifiedTooltipOpen);
  };
  const togglePendingTooltip = () => {
    setPendingTooltipOpen(!pendingTooltipOpen);
  };
  const toggleUnverifiedTooltip = () => {
    setunVerifiedTooltipOpen(!unverifiedTooltipOpen);
  };

  useEffect(() => {
    processAvailabilityData();
  }, [preferredAvailability]);

  useEffect(() => {
    processVolunteerAvailabilityData();
  }, [volunteeringAvailability]);

  // useEffect to update dropdown values after state has been updated
  useEffect(() => {
    if (!isMondayAvailable) {
      setMondayFromTime("unavailable");
    }
    if (!isTueAvailable) {
      setTuesdayFromTime("unavailable");
    }
    if (!isWedAvailable) {
      setWednesdayFromTime("unavailable");
    }
    if (!isThuAvailable) {
      setThursdayFromTime("unavailable");
    }
    if (!isFriAvailable) {
      setFridayFromTime("unavailable");
    }
    if (!isSatAvailable) {
      setSaturdayFromTime("unavailable");
    }
    if (!isSunAvailable) {
      setSundayFromTime("unavailable");
    }

    if (!isVolunteerMondayAvailable) {
      setVolunteerMondayFromTime("unavailable");
    }
    if (!isVolunteerTueAvailable) {
      setVolunteerTuesdayFromTime("unavailable");
    }
    if (!isVolunteerWedAvailable) {
      setVolunteerWednesdayFromTime("unavailable");
    }
    if (!isVolunteerThuAvailable) {
      setVolunteerThursdayFromTime("unavailable");
    }
    if (!isVolunteerFriAvailable) {
      setVolunteerFridayFromTime("unavailable");
    }
    if (!isVolunteerSatAvailable) {
      setVolunteerSaturdayFromTime("unavailable");
    }
    if (!isVolunteerSunAvailable) {
      setVolunteerSundayFromTime("unavailable");
    }
  }, []);

  useEffect(() => {
    // Handle initial state based on the value from the backend
    if (mondayFromTime === "unavailable") {
      setMondayToTime("default");
      setIsMondayAvailable(false);
    } else {
      setIsMondayAvailable(true);
    }
    if (tuesdayFromTime === "unavailable") {
      setTuesdayToTime("default");
      setIsTueAvailable(false);
    } else {
      setIsTueAvailable(true);
    }
    if (wednesdayFromTime === "unavailable") {
      setWednesdayToTime("default");
      setIsWedAvailable(false);
    } else {
      setIsWedAvailable(true);
    }
    if (thursdayFromTime === "unavailable") {
      setThursdayToTime("default");
      setIsThuAvailable(false);
    } else {
      setIsThuAvailable(true);
    }
    if (fridayFromTime === "unavailable") {
      setFridayToTime("default");
      setIsFriAvailable(false);
    } else {
      setIsFriAvailable(true);
    }
    if (saturdayFromTime === "unavailable") {
      setSaturdayToTime("default");
      setIsSatAvailable(false);
    } else {
      setIsSatAvailable(true);
    }
    if (sundayFromTime === "unavailable") {
      setSundayToTime("default");
      setIsSunAvailable(false);
    } else {
      setIsSunAvailable(true);
    }

    // volunteer availability
    if (volunteerMondayFromTime === "unavailable") {
      setVolunteerMondayToTime("default");
      setIsVolunteerMondayAvailable(false);
    } else {
      setIsVolunteerMondayAvailable(true);
    }
    if (volunteerTuesdayFromTime === "unavailable") {
      setVolunteerTuesdayToTime("default");
      setIsVolunteerTueAvailable(false);
    } else {
      setIsVolunteerTueAvailable(true);
    }
    if (volunteerWednesdayFromTime === "unavailable") {
      setVolunteerWednesdayToTime("default");
      setIsVolunteerWedAvailable(false);
    } else {
      setIsVolunteerWedAvailable(true);
    }
    if (volunteerThursdayFromTime === "unavailable") {
      setVolunteerThursdayToTime("default");
      setIsVolunteerThuAvailable(false);
    } else {
      setIsVolunteerThuAvailable(true);
    }
    if (volunteerFridayFromTime === "unavailable") {
      setVolunteerFridayToTime("default");
      setIsVolunteerFriAvailable(false);
    } else {
      setIsVolunteerFriAvailable(true);
    }
    if (volunteerSaturdayFromTime === "unavailable") {
      setVolunteerSaturdayToTime("default");
      setIsVolunteerSatAvailable(false);
    } else {
      setIsVolunteerSatAvailable(true);
    }
    if (volunteerSundayFromTime === "unavailable") {
      setVolunteerSundayToTime("default");
      setIsVolunteerSunAvailable(false);
    } else {
      setIsVolunteerSunAvailable(true);
    }
  }, [
    mondayFromTime,
    tuesdayFromTime,
    wednesdayFromTime,
    thursdayFromTime,
    fridayFromTime,
    saturdayFromTime,
    sundayFromTime,
    volunteerMondayFromTime,
    volunteerTuesdayFromTime,
    volunteerWednesdayFromTime,
    volunteerThursdayFromTime,
    volunteerFridayFromTime,
    volunteerSaturdayFromTime,
    volunteerSundayFromTime,
  ]);

  const fetchUserProfile = async (initial = false) => {
    try {
      if (initial) {
        setIsLoading(true);
      }
      if (get_cookie("workauthUser") !== false) {
        const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
        setUserId(user_id);
        // Call userdetails API
        const userResponse = await axios.get(`/user-details/${user_id}`);

        if (userResponse.data && userResponse.message === "Success") {
          // Assuming the API response has a property 'data' containing user details
          const userProfile = userResponse.data.user_profile[0];
          const workforcePreferences = userResponse.data.preferences;
          const userDetails = userResponse.data.user_details;
          const userhistory = userResponse.data.history;
          const Education = userResponse.data.education;
          const Certifications = userResponse.data.certificates;
          const Portfolio = userResponse.data.portfolio;
          const workprofileid = userResponse.data.workforce_profile_id;
          const preferredWorkLocations = userResponse.data.locations;
          const employeeReviews = userResponse.data.review;

          // Assuming the user details are nested under 'data'
          setUserProfile(userProfile);
          setUserHistory(userhistory);
          setUserEducation(Education);
          setUserPortfolio(Portfolio);
          setUserCertificates(Certifications);
          setWorkid(workprofileid);
          setUserlocation(preferredWorkLocations);
          setWorkPreferrences(workforcePreferences);
          setReview(employeeReviews);

          // Profile section
          if (userProfile) {
            const {
              first_name,
              last_name,
              suburb,
              make_profile_private,
              profile_pic,
              latitude,
              longitude,
              address,
              postcode,
              email,
              contact_number,
              star_rating,
              workforce_skills,
              workforce_preferences,
              workforce_industry,
              full_address,
              workforce_verification_documents,
              workforce_availability,
            } = userProfile;
            setUserName(`${first_name} ${last_name}`);
            setFirstName(first_name);
            setLastName(last_name);
            setUserProfileImg(profile_pic);
            setSuburbWithState(suburb);
            setSelectedLocation({ latitude: latitude, longitude: longitude });
            setStreet(address);
            setFullAddress(address);
            setPostalCode(postcode);
            setEmail(email);
            setIsChecked(make_profile_private === 1);
            setContactNumber(contact_number);
            setuserRating(star_rating);
            setUserSkill(workforce_skills);
            setWorkforcePreferences(workforce_preferences);
            setVerificationDocuments(workforce_verification_documents);
            setIndustryNames(workforce_industry);
            const defaultSelectedOptions = workforce_industry?.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            const defaultSelectedSkillsOptions = workforce_skills?.map(
              (skill) => ({ value: skill.skill_id, label: skill.skill_name })
            );
            //
            setSelectedIndustry(defaultSelectedOptions);
            setSelectedSkills(defaultSelectedSkillsOptions);
            if (workforce_availability.length > 0) {
              setCurrentAvailability(workforce_availability[0]);
            }
          }

          // workpreferences
          if (workforcePreferences) {
            const {
              desired_hourly_rate,
              desired_monthly_salary,
              preferred_work_type,
              available_start_date,
              minimum_engagement_duration,
              profile_summary,
              profile_title,
              is_employee,
              is_contractor,
              is_volunteer,
            } = workforcePreferences;
            setHourlyRate(desired_hourly_rate);
            setMonthlySalary(desired_monthly_salary);
            setPreferredWorkType(preferred_work_type);
            setAvailableStartDate(available_start_date);
            setProfileSummary(profile_summary);
            setProfileTitle(profile_title);
            setEmployeeChecked(is_employee == 1 ? true : false);
            setContractorChecked(is_contractor == 1 ? true : false);
            setVolunteerChecked(is_volunteer == 1 ? true : false);
          }

          // Call get-skills API
          const skillsResponse = await axios.get("/list-skills");

          if (skillsResponse.data && skillsResponse.message === "Success") {
            // Assuming the skills are nested under 'data'
            const skillsData = skillsResponse.data;

            // Update state with the fetched skills
            setSkills(skillsData);

            // Log the skills to the console for debugging
            //
          } else {
            // Handle case where skills are not found
            console.error("Skills not found");
          }

          // get Availability data
          const availabilityResponse = await axios.get(
            `/get-availability/${user_id}`
          );

          if (
            availabilityResponse.data &&
            availabilityResponse.message === "Success"
          ) {
            const availabilityData =
              availabilityResponse.data.workforce_availability;

            // Update state with the fetched skills
            setPreferredAvailability(availabilityData);
          } else {
            // Handle case where skills are not found
            console.error("Workforce availability not found");
          }

          // get volunteering Availability data
          const VolunteeringAvailabilityResponse = await axios.get(
            `/volunteering-availability/${user_id}`
          );

          if (
            VolunteeringAvailabilityResponse.data &&
            VolunteeringAvailabilityResponse.message === "Success"
          ) {
            const volunteeringAvailabilityData =
              VolunteeringAvailabilityResponse.data
                .workforce_volunteering_availability;

            // Update state with the fetched skills
            setvolunteeringAvailability(volunteeringAvailabilityData);
          } else {
            // Handle case where skills are not found
            console.error("volunteering availability not found");
          }
          setIsLoading(false);
        } else {
          // Handle case where user details are not found
          console.error("User details not found");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user profile:", error);
    }
  };

  // Function to extract and store data for each day
  const processAvailabilityData = () => {
    preferredAvailability?.forEach((availability) => {
      const {
        day_of_week,
        start_time,
        end_time,
        unavailable,
        available_anytime,
      } = availability;

      //

      // Handle "unavailable" and "Available anytime" cases
      let formattedStartTime = "";
      let formattedEndTime = "";

      if (start_time === "unavailable") {
        formattedStartTime = "unavailable";
        formattedEndTime = null; // Set a default end time for unavailable
      } else if (start_time === "Available anytime") {
        formattedStartTime = "available_anytime";
        formattedEndTime = null; // Set a default end time for available anytime
      } else {
        // Parse start_time and end_time as strings
        const parseTime = (time) => {
          const [hours, minutes, seconds] = time.split(":").map(Number);
          return new Date(2000, 0, 1, hours, minutes, seconds || 0);
        };

        const startTime = parseTime(start_time);
        const endTime = end_time ? parseTime(end_time) : null; // Parse only if end_time is not null

        // Format the time using Intl.DateTimeFormat
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        };
        formattedStartTime = startTime.toLocaleTimeString("en-US", timeOptions);
        formattedEndTime = endTime
          ? endTime.toLocaleTimeString("en-US", timeOptions)
          : null;
      }

      // Remove seconds from time values
      const selectedStartTime = start_time.slice(unavailable, 5); // Extract hours and minutes
      const selectedEndTime = end_time ? end_time.slice(0, 5) : null; // Extract hours and minutes

      // Set data for each day based on the day_of_week
      switch (day_of_week) {
        case "Monday":
          setMondayFromTime(formattedStartTime);
          setMondayToTime(formattedEndTime);
          break;
        case "Tuesday":
          setTuesdayFromTime(formattedStartTime);
          setTuesdayToTime(formattedEndTime);
          break;
        case "Wednesday":
          setWednesdayFromTime(formattedStartTime);
          setWednesdayToTime(formattedEndTime);
          break;
        case "Thursday":
          setThursdayFromTime(formattedStartTime);
          setThursdayToTime(formattedEndTime);
          break;
        case "Friday":
          setFridayFromTime(formattedStartTime);
          setFridayToTime(formattedEndTime);
          break;
        case "Saturday":
          setSaturdayFromTime(formattedStartTime);
          setSaturdayToTime(formattedEndTime);
          break;
        case "Sunday":
          setSundayFromTime(formattedStartTime);
          setSundayToTime(formattedEndTime);
          break;
        default:
          break;
      }
    });
  };

  // volunteering availability
  const processVolunteerAvailabilityData = () => {
    volunteeringAvailability?.forEach((availability) => {
      const {
        day_of_week,
        start_time,
        end_time,
        unavailable,
        available_anytime,
      } = availability;

      //

      // Handle "unavailable" and "Available anytime" cases
      let formattedStartTime = "";
      let formattedEndTime = "";

      if (start_time === "unavailable") {
        formattedStartTime = "unavailable";
        formattedEndTime = null; // Set a default end time for unavailable
      } else if (start_time === "Available anytime") {
        formattedStartTime = "available_anytime";
        formattedEndTime = null; // Set a default end time for available anytime
      } else {
        // Parse start_time and end_time as strings
        const parseTime = (time) => {
          const [hours, minutes, seconds] = time.split(":").map(Number);
          return new Date(2000, 0, 1, hours, minutes, seconds || 0);
        };

        const startTime = parseTime(start_time);
        const endTime = end_time ? parseTime(end_time) : null; // Parse only if end_time is not null

        // Format the time using Intl.DateTimeFormat
        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        };
        formattedStartTime = startTime.toLocaleTimeString("en-US", timeOptions);
        formattedEndTime = endTime
          ? endTime.toLocaleTimeString("en-US", timeOptions)
          : null;
      }

      // Remove seconds from time values
      const selectedStartTime = start_time.slice(unavailable, 5); // Extract hours and minutes
      const selectedEndTime = end_time ? end_time.slice(0, 5) : null; // Extract hours and minutes

      // Set data for each day based on the day_of_week
      switch (day_of_week) {
        case "Monday":
          setVolunteerMondayFromTime(formattedStartTime);
          setVolunteerMondayToTime(formattedEndTime);
          break;
        case "Tuesday":
          setVolunteerTuesdayFromTime(formattedStartTime);
          setVolunteerTuesdayToTime(formattedEndTime);
          break;
        case "Wednesday":
          setVolunteerWednesdayFromTime(formattedStartTime);
          setVolunteerWednesdayToTime(formattedEndTime);
          break;
        case "Thursday":
          setVolunteerThursdayFromTime(formattedStartTime);
          setVolunteerThursdayToTime(formattedEndTime);
          break;
        case "Friday":
          setVolunteerFridayFromTime(formattedStartTime);
          setVolunteerFridayToTime(formattedEndTime);
          break;
        case "Saturday":
          setVolunteerSaturdayFromTime(formattedStartTime);
          setVolunteerSaturdayToTime(formattedEndTime);
          break;
        case "Sunday":
          setVolunteerSundayFromTime(formattedStartTime);
          setVolunteerSundayToTime(formattedEndTime);
          break;
        default:
          break;
      }
    });
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    options.unshift({ label: "Available anytime", value: "available_anytime" });
    options.unshift({ label: "Unavailable", value: "unavailable" });
    return options;
  };

  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };
  const timeOptions = generateTimeOptions();

  const t_Profile = () => {
    setShowProfile(!showProfile);
    fetchUserProfile();
    setUserProfileImg(null);
    setIsImageSelected(false);
  };
  const t_Profession = () => {
    setShowProfession(!showProfession);
    fetchUserProfile();
  };

  const t_Availability = () => {
    setShowAvailability(!showAvailability);
    setShowToast(false); // Reset toast state
    fetchUserProfile();
  };

  const t_VolunteerAvailability = () => {
    setShowVolunteerAvailability(!showVolunteerAvailability);
    setShowToast(false); // Reset toast state
    fetchUserProfile();
  };

  const t_addlocation = () => {
    setshowlocation(!showlocation);
    setselectedSuburb(""); // Clear the selected suburb
    setRadius("");
    fetchUserProfile();
  };

  const t_location = (id, cancel = false) => {
    const updateddata = [...userlocation];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    console.log(updateddata[taskIndex], "updateddata");
    setUserlocation(updateddata);
    seteditsubalone(updateddata[taskIndex].suburb);
    seteditlatitude(updateddata[taskIndex].latitude);
    seteditlongitude(updateddata[taskIndex].longitude);
    seteditpostcode(updateddata[taskIndex].post_code);
    seteditstate(updateddata[taskIndex].state);

    if (cancel) {
      fetchUserProfile();
    }
    //setShowEducation(!showEducation);
  };

  const t_history = async (id, cancel = false) => {
    const updateddata = [...userhistory];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    setUserHistory(updateddata);
    if (cancel) {
      await fetchUserProfile();
    }
    //setShowEducation(!showEducation);
  };

  const t_Education = async (id, cancel = false) => {
    const updateddata = [...userEducation];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    setUserEducation(updateddata);
    if (cancel) {
      await fetchUserProfile();
    }
    //setShowEducation(!showEducation);
  };

  const t_Certification = async (id, cancel = false) => {
    const updateddata = [...userCertificates];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    setUserCertificates(updateddata);
    if (cancel) {
      await fetchUserProfile();
    }
    //setShowEducation(!showEducation);
  };
  const t_Portfolio = async (id, cancel = false) => {
    const updateddata = [...userPortfolio];
    const taskIndex = updateddata.findIndex((task) => task.id === id);
    //
    updateddata[taskIndex].isEditing =
      updateddata[taskIndex].isEditing == null
        ? true
        : !updateddata[taskIndex].isEditing;
    setUserPortfolio(updateddata);
    if (cancel) {
      await fetchUserProfile();
      setIsPortfolioEdit(null);
    } else {
      setIsPortfolioEdit(id);
    }
    //setShowEducation(!showEducation);
  };

  const submitlocation = async () => {
    // Check if suburb or radius is empty
    if (!selectedSuburb) {
      toast.warning("Please enter the suburb.", { theme: "light" });
      return;
    }
    if (!radius || isNaN(radius) || parseFloat(radius) <= 0) {
      toast.warning("Please provide a valid radius.", { theme: "light" });
      return;
    }

    // Get the full address, handle the case when it returns undefined
    const geocodedData = await geocodeAddress(selectedSuburb);

    if (
      !geocodedData ||
      !geocodedData.latitude ||
      !geocodedData.longitude ||
      !geocodedData.formattedAddress
    ) {
      throw new Error("Invalid address data received");
    }

    const {
      latitude,
      longitude,
      formattedAddress,
      state,
      suburb,
      postal_code,
      country,
    } = geocodedData;

    // Set isLoading to true to show the loader
    setIsLoading(true);

    let data = {
      country_code: "au",
      state: state,
      country_id: 8,
      workforce_profile_id: userId,
      suburb: suburb,
      postcode: postal_code,
      radius_distance: radius,
      longitude: longitude,
      latitude: latitude,
    };

    axios
      .post("/add-work-location", data)
      .then((res) => {
        if (res.message === "Success") {
          toast.success("Location Added Successfully", { theme: "light" });
          // setstatelocation(res.state);
          // setressuburb(res.suburb);
          t_addlocation();
          fetchUserProfile();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // Set isLoading to false when the request is complete (whether successful or not)
        setIsLoading(false);
      });
  };

  // ...

  const t_AddEducation = () => {
    if (showEducation) {
      fetchUserProfile();
    }
    setShowEducation(!showEducation);
    setDataEdu({
      achievements: "",
      degree_name: "",
      start_date: "",
      end_date: "",
      institution_name: "",
      field_of_study: "",
    });
  };

  const t_AddCertification = () => {
    if (showCertification) {
      fetchUserProfile();
    }
    setShowCertification(!showCertification);
    fetchUserProfile();
    setDataCert({
      certification_name: "",
      issuing_authority: "",
      date_issued: "",
      expiration_date: "",
      document_url: "",
    });
  };

  const [showhistory, setShowHistory] = useState();
  const [showportfolio, setShowPortfolio] = useState();

  const t_addhistory = () => {
    setShowHistory(!showhistory);

    setemployeelocation("");
    sethistory({
      role: "",
      company_name: "",
      location: "",
      degree: "",
      start_date: "",
      end_date: "",
      description: "",
      checkbox: "",
    });
  };

  const t_addportfolio = () => {
    setShowPortfolio(!showportfolio);
    setDataPort({
      name: "",
      description: "",
      image: "",
    });
    setPortObjectUrl(null);

    setImgSrcPortfolio("");
    setCroppedImgSrcPortfolio("");
    setErrorPortfolio("");
    setUploadFileNamePortfolio("");
    setIsPortImageSelected(false);
  };

  const t_Sec1 = () => {
    setShowSection1(!showSection1);

    axios
      .get("/list-industries")
      .then((res) => {
        if (res.message == "Success") {
          // toast.success(res.message, { theme: "light" });
          setAllIndustryNames(res.industry_names);
          fetchUserProfile();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const t_Sec3 = (isplus) => {
    if (isplus === "plus") {
      resetVerification();
      setEditVerification(false);
    }
    setShowSection3(!showSection3);
  };
  const handleVerificationEdit = (docId) => {
    setIsDocUrl(true);
    setImage("");
    const formData = {
      id: docId,
      user_id: authUser_id,
    };
    axios
      .post("/edit-verification", formData)
      .then((res) => {
        setSelectedDocType(res.load_data?.document_type_id);
        setDocNumber(res.load_data?.document_number);
        setIssuingAuthority(res.load_data?.issuing_authority);
        setIssuedDate(res.load_data?.date_issued);
        setExpirationDate(res.load_data?.expiration_date);
        setDocumentId(res.load_data?.id);
        setImage(res.load_data?.s3_url);
        setIsImageSelected(true);
        setVerificationUploadedImage(res.load_data?.document_url);
        setFileExtensionFormat(getFileExtension(res.load_data?.s3_url));
      })
      .then((err) => {
        console.error(err);
      });
  };
  function getFileExtension(url) {
    // Get the last part of the URL after the last dot
    const parts = url.split(".");
    return parts[parts.length - 1].toLowerCase();
  }
  const handleVerificationUpdate = () => {
    var formData = new FormData();
    formData.append("id", documentId);
    formData.append("user_id", authUser_id);
    formData.append("document_type_id", selectedDocType);
    formData.append("document_number", docNumber);
    formData.append("issuing_authority", issuingAuthority);
    formData.append("date_issued", issuedDate);
    formData.append("expiration_date", expirationDate);
    formData.append("document_url", uploadedVerificationImage);
    axios
      .post("/update-verification", formData)
      .then((res) => {
        if (res.error == 0) {
          resetVerification();
          toast.success(res.message, { autoClose: 3000 });
          t_Sec3();
        } else if (res.error == 1) {
          toast.warning(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const save_Sec3 = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    var formData = new FormData();
    formData.append("user_id", authUser_id);
    formData.append("document_type_id", selectedDocType);
    formData.append("document_number", docNumber);
    formData.append("issuing_authority", issuingAuthority);
    formData.append("date_issued", issuedDate);
    formData.append("expiration_date", expirationDate);
    formData.append("document_url", uploadedVerificationImage);
    axios
      .post("/add-verification", formData)
      .then((res) => {
        if (res.error == 0) {
          resetVerification();
          toast.success(res.message, { autoClose: 3000 });
          t_Sec3();
          loadVerificationData();
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  const resetVerification = () => {
    setEditVerification(false);
    setDocNumber("");
    setIssuingAuthority("");
    setIssuedDate("");
    setSelectedDocType(0);
    setExpirationDate("");
    setImage("");
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    setVerificationUploadedImage(null);
    verificationFileInputRef.current.value = null;
  };

  const handleVerificationFileInputChange = (event) => {
    setIsDocUrl(false);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check file type
      const acceptedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      if (!acceptedFormats.includes(selectedFile.type)) {
        setLogoError(
          "Please select a valid image or PDF format (jpeg, jpg, png, pdf)."
        );
        setVerificationUploadedImage(null);
        return; // Exit the function if the file type is not valid
      } else {
        setLogoError("");
      }

      // Check image dimensions (for image files)
      if (selectedFile.type.startsWith("image/")) {
        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);
        image.onload = () => {
          const objectUrl = URL.createObjectURL(selectedFile);
          setVerificationUploadedImage(selectedFile);
          setSelectedImage(objectUrl);
          setIsImageSelected(true);
          setImageDimensionsValid(true);
        };
      } else {
        // Handle PDF file here (if needed)
        const objectUrl = URL.createObjectURL(selectedFile);
        setVerificationUploadedImage(selectedFile);
        setSelectedImage(objectUrl);
        setIsImageSelected(true);
        setImageDimensionsValid(true);
        // Additional handling for PDF files, if required
      }
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setImgSrc(null);
    setIsImageSelected(false);
    setVerificationUploadedImage(null);
    verificationFileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    verificationFileInputRef.current.click();
  };

  const save_Sec1 = (e) => {
    e.preventDefault();

    // Check if a save operation is already in progress
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    // Validation
    if (!hourlyRate || hourlyRate.trim() === "") {
      toast.error("Hourly rate is required.");
      setIsSaving(false);
      return;
    }

    let data = {
      id: userId,
      user_industries: selectedIndustry,
      hourly_rate: hourlyRate,
      monthly_salary: monthlySalary,
      start_date: availableStartDate,
      employee: employeeChecked ? 1 : 0,
      contractor: contractorChecked ? 1 : 0,
      volunteer: volunteerChecked ? 1 : 0,
    };

    axios
      .post("/add-side-section", data)
      .then((res) => {
        if (res.status == "Success") {
          toast.success(res.message);
          fetchUserProfile();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsSaving(false);
        setShowSection1(!showSection1);
      });
  };

  // console.log("mondayFromTime",mondayFromTime)
  // console.log("mondayToTime",mondayToTime)
  // console.log("tuesdayFromTime",tuesdayFromTime)
  // console.log("tuesdayToTime",tuesdayToTime)
  // console.log("wednesdayFromTime",wednesdayFromTime)
  // console.log("wednesdayToTime",wednesdayToTime)
  // console.log("thursdayFromTime",thursdayFromTime)
  // console.log("thursdayToTime",thursdayToTime)
  // console.log("fridayFromTime",fridayFromTime)
  // console.log("fridayToTime",fridayToTime)
  // console.log("saturdayFromTime",saturdayFromTime)
  // console.log("saturdayToTime",saturdayToTime)
  // console.log("sundayFromTime",sundayFromTime)
  // console.log("sundayToTime",sundayToTime)

  // console.log("isMondayAvailable",isMondayAvailable)
  // console.log("isTueAvailable",isTueAvailable)
  // console.log("isWedAvailable",isWedAvailable)
  // console.log("isThuAvailable",isThuAvailable)
  // console.log("isFriAvailable",isFriAvailable)
  // console.log("isSatAvailable",isSatAvailable)
  // console.log("isSunAvailable",isSatAvailable)

  const save_Availability = () => {
    console.log("Monday", mondayFromTime, mondayToTime);
    console.log("Tuesday", tuesdayFromTime, tuesdayToTime);

    console.log(isSaving, "isSaving");
    if (isSaving) {
      console.log("saving true");
      return;
    }

    setIsSaving(true);

    const validateDay = (day, isAvailable, fromTime, toTime) => {
      if (
        isAvailable &&
        fromTime !== "unavailable" &&
        fromTime !== "available_anytime" &&
        (toTime === null || toTime === "default")
      ) {
        console.log("validate true");

        // setShowToast(true);
        toast.error(
          'Please select both "From Time" and "To Time" for all selected days.'
        );
        setIsSaving(false);
        return false;
      }
      return true;
    };

    if (
      validateDay("Monday", isMondayAvailable, mondayFromTime, mondayToTime) &&
      validateDay("Tuesday", isTueAvailable, tuesdayFromTime, tuesdayToTime) &&
      validateDay(
        "Wednesday",
        isWedAvailable,
        wednesdayFromTime,
        wednesdayToTime
      ) &&
      validateDay(
        "Thursday",
        isThuAvailable,
        thursdayFromTime,
        thursdayToTime
      ) &&
      validateDay("Friday", isFriAvailable, fridayFromTime, fridayToTime) &&
      validateDay(
        "Saturday",
        isSatAvailable,
        saturdayFromTime,
        saturdayToTime
      ) &&
      validateDay("Sunday", isSunAvailable, sundayFromTime, sundayToTime)
    ) {
      const data = {
        id: authUser_id,
        working_hours: [
          {
            day_of_week: "Monday",
            is_available: isMondayAvailable ? "true" : "false",
            fromTime: mondayFromTime,
            toTime: mondayToTime,
          },
          {
            day_of_week: "Tuesday",
            is_available: isTueAvailable ? "true" : "false",
            fromTime: tuesdayFromTime,
            toTime: tuesdayToTime,
          },
          {
            day_of_week: "Wednesday",
            is_available: isWedAvailable ? "true" : "false",
            fromTime: wednesdayFromTime,
            toTime: wednesdayToTime,
          },
          {
            day_of_week: "Thursday",
            is_available: isThuAvailable ? "true" : "false",
            fromTime: thursdayFromTime,
            toTime: thursdayToTime,
          },
          {
            day_of_week: "Friday",
            is_available: isFriAvailable ? "true" : "false",
            fromTime: fridayFromTime,
            toTime: fridayToTime,
          },
          {
            day_of_week: "Saturday",
            is_available: isSatAvailable ? "true" : "false",
            fromTime: saturdayFromTime,
            toTime: saturdayToTime,
          },
          {
            day_of_week: "Sunday",
            is_available: isSunAvailable ? "true" : "false",
            fromTime: sundayFromTime,
            toTime: sundayToTime,
          },
        ],
      };
      console.log("data", data);

      axios
        .post("/add-availability", data)
        .then((response) => {
          // Parse each JSON string into an object
          // const jsonObjectArray = response.map(JSON.parse);

          // Extract the first element
          const firstElement = response[0];
          if (firstElement.error === 0) {
            toast.success(firstElement.message, { theme: "light" });
            // setstatelocation(res.state);
            // setressuburb(res.suburb);
          } else {
            toast.error(firstElement.message, { theme: "light" });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsSaving(false);
          setShowAvailability(!showAvailability);
          fetchUserProfile();
        });
    } else {
      setIsSaving(false);
    }
  };

  const save_Volunteering_Availability = () => {
    console.log("Monday", mondayFromTime, mondayToTime);
    console.log("Tuesday", tuesdayFromTime, tuesdayToTime);

    console.log(isSaving, "isSaving");
    if (isSaving) {
      console.log("saving true");
      return;
    }

    setIsSaving(true);

    const validateDay = (day, isAvailable, fromTime, toTime) => {
      if (
        isAvailable &&
        fromTime !== "unavailable" &&
        fromTime !== "available_anytime" &&
        (toTime === null || toTime === "default")
      ) {
        console.log("validate true");

        // setShowToast(true);
        toast.error(
          'Please select both "From Time" and "To Time" for all selected days.'
        );
        setIsSaving(false);
        return false;
      }
      return true;
    };

    if (
      validateDay(
        "Monday",
        isVolunteerMondayAvailable,
        volunteerMondayFromTime,
        volunteerMondayToTime
      ) &&
      validateDay(
        "Tuesday",
        isVolunteerTueAvailable,
        volunteerTuesdayFromTime,
        volunteerTuesdayToTime
      ) &&
      validateDay(
        "Wednesday",
        isVolunteerWedAvailable,
        volunteerWednesdayFromTime,
        volunteerWednesdayToTime
      ) &&
      validateDay(
        "Thursday",
        isVolunteerThuAvailable,
        volunteerThursdayFromTime,
        volunteerThursdayToTime
      ) &&
      validateDay(
        "Friday",
        isVolunteerFriAvailable,
        volunteerFridayFromTime,
        volunteerFridayToTime
      ) &&
      validateDay(
        "Saturday",
        isVolunteerSatAvailable,
        volunteerSaturdayFromTime,
        volunteerSaturdayToTime
      ) &&
      validateDay(
        "Sunday",
        isVolunteerSunAvailable,
        volunteerSundayFromTime,
        volunteerSundayToTime
      )
    ) {
      const data = {
        id: authUser_id,
        working_hours: [
          {
            day_of_week: "Monday",
            is_available: isVolunteerMondayAvailable,
            fromTime: volunteerMondayFromTime,
            toTime: volunteerMondayToTime,
          },
          {
            day_of_week: "Tuesday",
            is_available: isVolunteerTueAvailable,
            fromTime: volunteerTuesdayFromTime,
            toTime: volunteerTuesdayToTime,
          },
          {
            day_of_week: "Wednesday",
            is_available: isVolunteerWedAvailable,
            fromTime: volunteerWednesdayFromTime,
            toTime: volunteerWednesdayToTime,
          },
          {
            day_of_week: "Thursday",
            is_available: isVolunteerThuAvailable,
            fromTime: volunteerThursdayFromTime,
            toTime: volunteerThursdayToTime,
          },
          {
            day_of_week: "Friday",
            is_available: isVolunteerFriAvailable,
            fromTime: volunteerFridayFromTime,
            toTime: volunteerFridayToTime,
          },
          {
            day_of_week: "Saturday",
            is_available: isVolunteerSatAvailable,
            fromTime: volunteerSaturdayFromTime,
            toTime: volunteerSaturdayToTime,
          },
          {
            day_of_week: "Sunday",
            is_available: isVolunteerSunAvailable,
            fromTime: volunteerSundayFromTime,
            toTime: volunteerSundayToTime,
          },
        ],
      };
      console.log("data", data);

      axios
        .post("/add-volunteering-availability", data)
        .then((response) => {
          console.log(response, "volunteeringResponse");
          // Parse each JSON string into an object
          // const jsonObjectArray = response.map(JSON.parse);

          // Extract the first element
          const firstElement = response[0];
          console.log(firstElement, "firstElement");
          if (firstElement.error === 0) {
            toast.success(firstElement.message, { theme: "light" });
            // setstatelocation(res.state);
            // setressuburb(res.suburb);
          } else {
            toast.error(firstElement.message, { theme: "light" });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsSaving(false);
          setShowVolunteerAvailability(!showVolunteerAvailability);
          fetchUserProfile();
        });
    } else {
      setIsSaving(false);
    }
  };
  // file upload content

  const handleButtonClick = (event) => {
    event.preventDefault();
    verificationFileInputRef.current.click();
  };

  const handlePortfolioButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handlePortfolioEditButtonClick = (event) => {
    event.preventDefault();
    fileEditInputRef.current.click();
  };

  const savelocation = async (item) => {
    // console.log(item.location,"INPUT VALUE");
    // Check if suburb is empty
    if (!editsubalone) {
      toast.warning("Please enter the suburb.", { theme: "light" });
      return;
    }
    // Check if radius is empty or not a valid number
    if (
      !item.radius_distance ||
      isNaN(item.radius_distance) ||
      parseFloat(item.radius_distance) <= 0
    ) {
      toast.warning("Please provide a valid radius.", { theme: "light" });
      return;
    }

    // Get the full address, handle the case when it returns undefined
    // const geocodedData = await geocodeAddress(inputValue);
    const geocodedData = await geocodeAddress(item.location);

    if (
      !geocodedData ||
      !geocodedData.latitude ||
      !geocodedData.longitude ||
      !geocodedData.formattedAddress
    ) {
      throw new Error("Invalid address data received");
    }

    const {
      latitude,
      longitude,
      formattedAddress,
      state,
      suburb,
      postal_code,
      country,
    } = geocodedData;

    setIsLoading(true); // Set isLoading to true to show the loader

    let data = {
      id: item.id,
      workforce_profile_id: item.workforce_profile_id,
      country_code: "au",
      state: state,
      suburb: suburb,
      postcode: postal_code,
      latitude: latitude,
      longitude: longitude,
      radius_distance: item.radius_distance,
    };

    console.log(data, "updateworklocation");

    axios
      .post("/update-work-location", data)
      .then((res) => {
        if (res.error === 0) {
          toast.success("Location Updated Successfully", { theme: "light" });
          setstatelocation(res.state);
          setressuburb(res.suburb);
          fetchUserProfile();
        } else {
          toast.error(res.message, { theme: "light" });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false); // Set isLoading to false to hide the loader
        t_location(item.id, true);
        //fetchUserProfile();
      });
  };

  const deletelocation = (item) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this location?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              id: item.id,
            };

            axios
              .post("/delete-work-location", data)
              .then((res) => {
                if (res.message === "Success") {
                  toast.success("Location Deleted Successfully", {
                    theme: "light",
                  });
                }
                fetchUserProfile();
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRadiusChange = (e) => {
    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const inputValue = e.target.value.replace(/\D/g, "").substring(0, 3);
    setRadius(inputValue);
  };

  const handleeditradiuschange = (item) => (e) => {
    const enteredValue = e.target.value;

    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const sanitizedValue = enteredValue.replace(/\D/g, "").substring(0, 3);

    const updatedData = userlocation.map((task) =>
      task.id === item.id ? { ...task, radius_distance: sanitizedValue } : task
    );

    setUserlocation(updatedData);
  };

  const selectSuburbOption = (place) => {
    // Check if geometry is present
    if (!place.geometry || !place.geometry.location) {
      console.error("Invalid place data:", place);
      return;
    }

    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;

      // Concatenate state if available
      if (stateComponent) {
        selectedSuburb += `, ${stateComponent.short_name}`;
      }
    }

    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    // Extract suburb and state separately
    let suburbAlone = suburbComponent?.long_name || "";
    let selectedState = stateComponent?.short_name || "";
    let postcode = selectedPostcode || "";

    // Check if location has latitude and longitude information
    const locationGeometry = place?.geometry;
    const latitude = locationGeometry?.location.lat() || null;
    const longitude = locationGeometry?.location.lng() || null;

    // Update your state variables
    setselectedSuburb(selectedSuburb);
    setselectedSuburbAlone(suburbAlone);
    setselectedPostcode(postcode);
    setselectedState(selectedState);
    setselectedLatitude(latitude);
    setselectedLongitude(longitude);
  };

  const [employeelocation, setemployeelocation] = useState();

  const addemployeelocation = (place) => {
    const addressComponents = place?.address_components;

    let city = "";
    let country = "";

    addressComponents?.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      }
    });
    setemployeelocation(city ? `${city}, ${country}` : `${country}`);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const editlocationfield = (place, item) => {
    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    let selectedState = "";
    let selectedPostcode = "";
    let selectedLatitude = "";
    let selectedLongitude = "";

    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
      if (stateComponent) {
        selectedState = stateComponent.short_name;
      }
    }

    let suburbAlone = suburbComponent?.long_name || "";

    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    if (place.geometry && place.geometry.location) {
      selectedLatitude = place.geometry.location.lat();
      selectedLongitude = place.geometry.location.lng();
    }

    if (selectedState) {
      selectedSuburb += `, ${selectedState}`;
    }
    seteditsubalone(suburbAlone); // Assuming you want to store the suburb only
    seteditpostcode(selectedPostcode);
    seteditstate(selectedState);
    seteditlatitude(selectedLatitude);
    seteditlongitude(selectedLongitude);

    setInputValue(selectedSuburb);
    console.log(selectedSuburb, "selectedSuburb");
    // Update the userlocation array with the edited values
    setUserlocation((prevUserLocation) => {
      const updatedData = prevUserLocation.map((task) =>
        task.id === item.id ? { ...task, location: selectedSuburb } : task
      );
      return updatedData;
    });
  };

  const edithistorylocation = (place, currentItem) => {
    const addressComponents = place?.address_components;

    let selectedCity = "";
    let selectedCountry = "";

    // Extract suburb (city)
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    if (suburbComponent) {
      selectedCity = suburbComponent.long_name;
    }

    // Extract country
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      selectedCountry = countryComponent.long_name;
    }

    setUserHistory((prevUserHistory) => {
      const updatedData = prevUserHistory.map((task) =>
        task.id === currentItem.id
          ? {
              ...task,
              location: selectedCity
                ? `${selectedCity}, ${selectedCountry}`
                : `${selectedCountry}`,
            }
          : task
      );
      return updatedData;
    });
  };

  const deletehistory = (item) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this employment history?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              id: item.id,
            };

            axios
              .post("/delete-employment-history", data)
              .then((res) => {
                if (res.message === "Success") {
                  toast.success("Employee History Has been Deleted", {
                    theme: "light",
                  });
                }
                fetchUserProfile();
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      // Check file type
      const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

      if (!acceptedFormats.includes(selectedFile.type)) {
        setLogoError("Please select a valid image format (jpeg, jpg, png).");
        setUploadedImage(null);
      } else {
        setLogoError("");
      }

      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);

      image.onload = () => {
        const objectUrl = URL.createObjectURL(selectedFile);
        setUploadedImage(selectedFile);
        setObjectUrl(objectUrl);
        setIsImageSelected(true);
      };
    }
  };

  const handlePortfolioFileInputChange = (event, data = false) => {
    const selectedFile = event.target.files[0];
    if (!data) {
      // add
      if (selectedFile) {
        // Check file type
        const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

        if (!acceptedFormats.includes(selectedFile.type)) {
          setLogoError("Please select a valid image format (jpeg, jpg, png).");
          setPortUploadedImage(null);
        } else {
          setLogoError("");
        }

        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);

        image.onload = () => {
          const objectUrl = URL.createObjectURL(selectedFile);
          setPortUploadedImage(selectedFile);
          setPortObjectUrl(objectUrl);
          setIsPortImageSelected(true);
        };
      }
    } else {
      // edit
      const objectUrl = URL.createObjectURL(selectedFile);
      const updateddata = [...userPortfolio];
      const taskIndex = updateddata.findIndex((task) => task.id === data);
      if (selectedFile) {
        // Check file type
        const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

        if (!acceptedFormats.includes(selectedFile.type)) {
          setLogoError("Please select a valid image format (jpeg, jpg, png).");
          updateddata[taskIndex].image = null;
          //setPortUploadedImage(null);
        } else {
          setLogoError("");
        }

        const image = new Image();
        image.src = URL.createObjectURL(selectedFile);

        image.onload = () => {
          updateddata[taskIndex].image = selectedFile;
          updateddata[taskIndex].imageurl = objectUrl;
          setUserPortfolio(updateddata);

          //setPortUploadedImage(selectedFile);
          //setPortObjectUrl(objectUrl);
          //setIsPortImageSelected(true);
        };
      }
    }
  };

  const handlePortImageremove = (data = false) => {
    if (!data) {
      setPortUploadedImage(null);
      setPortObjectUrl(null);

      setUploadFileNamePortfolio("");
      setImgSrcPortfolio("");
      setCroppedImgSrcPortfolio("");
      setErrorPortfolio("");
      setIsPortImageSelected(false);
      //fileInputRef.current.value = null;
    } else {
      const updateddata = [...userPortfolio];
      const taskIndex = updateddata.findIndex((task) => task.id === data);
      updateddata[taskIndex].image = null;
      updateddata[taskIndex].imageurl = null;
      updateddata[taskIndex].image_file_name = null;
      setUserPortfolio(updateddata);
    }
  };

  const handleRemoveImage = () => {
    // Reset image state to userDummy
    setUploadedImage(null);
    setObjectUrl(null);
    setImgSrc("");
    setCroppedImgSrc(null);
    setError("");
    const fileInput = document.getElementById("profile-img-input");
    if (fileInput) {
      fileInput.value = ""; // Reset input file element
    }
    setUserProfileImg(null);
    setIsImageSelected(false);
    setDeleteProfilePicture(true);

    // save_Avatar();
  };

  const Add_Education = (data) => {
    if (data.institution_name == "") {
      toast.warning("Please enter the institution name");
      setinstituteNameError(true);
      return false;
    }
    if (data.start_date == "") {
      toast.warning("Please select the start date");
      setedustartError(true);
      return false;
    }
    if (data.end_date == "") {
      toast.warning("Please select the end date");
      seteduendError(true);
      return false;
    }

    // Additional validation: Check if start date is less than end date
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    if (startDate >= endDate) {
      toast.warning("Start date should be before the end date");
      return;
    }
    setinstituteNameError(false);
    setedustartError(false);
    seteduendError(false);
    const postData = {
      user_id: userId,
      achievements: data.achievements,
      degree: data.degree_name,
      start_date: data.start_date,
      end_date: data.end_date,
      institution_name: data.institution_name,
      field_of_study: data.field_of_study,
    };
    axios.post("/add-education", postData).then((res) => {
      if (res.status == "Success") {
        toast.success(res.message);
        fetchUserProfile();
        t_AddEducation();
      } else {
        toast.error(res.message);
      }
    });
  };

  const Edit_Education = (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    if (data.institution_name == "") {
      toast.warning("Please enter the institution name");
      setinstituteNameError(true);
      return false;
    }
    if (data.start_date == "") {
      toast.warning("Please select the start date");
      setedustartError(true);
      return false;
    }
    if (data.end_date == "") {
      toast.warning("Please select the end date");
      seteduendError(true);
      return false;
    }
    // Additional validation: Check if start date is less than end date
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    if (startDate >= endDate) {
      toast.warning("Start date should be before the end date");
      return;
    }
    setinstituteNameError(false);
    setedustartError(false);
    seteduendError(false);
    const postData = {
      id: data.id,
      user_id: userId,
      achievements: data.achievements,
      degree: data.degree,
      start_date: data.start_date,
      end_date: data.end_date,
      institution_name: data.institution_name,
      field_of_study: data.field_of_study,
    };
    axios.post("/update-education", postData).then((res) => {
      if (res.status == "Success") {
        toast.success(res.message);
        t_Education(data.id, true);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteEducation = (id) => {
    const postData = {
      id: id,
    };
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.post("/delete-education", postData).then((res) => {
              if (res.error === 0) {
                toast.success(res.message);
                fetchUserProfile();
              }
              if (res.error === 1) {
                toast.error(res.message);
              }
            });
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const Add_Certification = (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    if (data.certification_name == "") {
      toast.warning("Please enter the certification name");
      //setinstituteNameError(true);
      return false;
    }
    if (data.issuing_authority == "" || data.issuing_authority == null) {
      toast.warning("Please enter the issuing authority");
      //setedustartError(true);
      return false;
    }
    // Additional validation: Check if start date is less than end date
    const startDate = new Date(data.date_issued);
    const endDate = new Date(data.expiration_date);
    if (startDate >= endDate) {
      toast.warning("data issued should be before the expiration date");
      return;
    }
    const postData = {
      id: data.id,
      user_id: user_id,
      certification_name: data.certification_name,
      issuing_authority: data.issuing_authority,
      date_issued: data.date_issued,
      expiration_date: data.expiration_date,
    };

    axios.post("/add-certification", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        fetchUserProfile();
        t_AddCertification();
      } else {
        toast.error(res.message);
      }
    });
  };

  const Edit_Certification = (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    if (data.certification_name == "" || data.certification_name == null) {
      toast.warning("Please enter the certification name");
      return false;
    }
    if (data.issuing_authority == "" || data.issuing_authority == null) {
      toast.warning("Please enter the issuing authority");
      return false;
    }
    const startDate = data.date_issued ? new Date(data.date_issued) : null;
    const endDate = data.expiration_date
      ? new Date(data.expiration_date)
      : null;

    if (data.expiration_date && !data.date_issued) {
      // Show toast if expiration_date is not null and date_issued is null
      toast.warning("Please choose the date issued");
      return;
    }
    if (data.date_issued && data.expiration_date && startDate >= endDate) {
      toast.warning("data issued should be before the expiration date");
      return;
    }
    const postData = {
      id: data.id,
      user_id: user_id,
      certification_name: data.certification_name,
      issuing_authority: data.issuing_authority,
      date_issued: data.date_issued,
      expiration_date: data.expiration_date,
    };
    axios.post("/update-certification", postData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        fetchUserProfile();
        t_Certification(data.id);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteCertification = (id) => {
    const postData = {
      id: id,
    };
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.post("/delete-certification", postData).then((res) => {
              if (res.error == "0") {
                toast.success(res.message);
                fetchUserProfile();
              } else {
                toast.error(res.message);
              }
            });
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const Add_Portfolio = (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    let file;

    if (croppedImgSrcPortfolio) {
      // Function to convert base64 to binary file
      const base64ToFile = (base64String, filename) => {
        // Remove the data URL prefix
        const byteString = atob(base64String.split(",")[1]);
        // Get the MIME type from the base64 string
        const mimeType = base64String.match(/:(.*?);/)[1];
        // Create an ArrayBuffer to hold the binary data
        const ab = new ArrayBuffer(byteString.length);
        // Create a typed array from the ArrayBuffer
        const ia = new Uint8Array(ab);
        // Convert the binary string to binary data
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        // Create a Blob from the binary data
        const blob = new Blob([ia], { type: mimeType });
        // Create a File from the Blob
        return new File([blob], filename, { type: mimeType });
      };

      file = base64ToFile(croppedImgSrcPortfolio, uploadFileNamePortfolio);
    }
    if (data.name == "") {
      toast.warning("Please enter the portfolio name");
      //setinstituteNameError(true);
      return false;
    }
    if (data.description == "") {
      toast.warning("Please enter the description");
      //setedustartError(true);
      return false;
    }

    var formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    if (file) {
      formData.append("image", file);
    } else {
      formData.append("image", "");
    }
    // formData.append("image", portuploadImage);
    axios.post("/add-portfolio", formData).then((res) => {
      if (res.error == 0) {
        toast.success(res.message);
        t_addportfolio();
        fetchUserProfile();
      } else {
        toast.error(res.message);
      }
    });
  };

  const Edit_Portfolio = (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    let file = null;

    // Check if there is a new image to upload
    if (data.image && typeof data.image === "string") {
      const base64ToFile = (base64String, filename) => {
        const byteString = atob(base64String.split(",")[1]);
        const mimeType = base64String.match(/:(.*?);/)[1];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], { type: mimeType });
        return new File([blob], filename, { type: mimeType });
      };

      file = base64ToFile(data.image, uploadFileNameEdit);
    }

    if (data.name === "") {
      toast.warning("Please enter the portfolio name");
      return false;
    }
    if (data.description === "") {
      toast.warning("Please enter the description");
      return false;
    }

    var formData = new FormData();
    formData.append("id", data.id);
    formData.append("user_id", user_id);
    formData.append("name", data.name);
    formData.append("description", data.description);

    // Append file if a new image is uploaded
    if (file) {
      formData.append("image", file);
    } else if (data.image_file_name) {
      // If there's an existing image file name, append it to formData
      formData.append("existing_image", data.image_file_name);
    }

    axios.post("/update-portfolio", formData).then((res) => {
      if (res && res.error === 0) {
        toast.success(res.message);
        t_Portfolio(data.id, true);
      } else {
        toast.error(res.message);
      }
    });
  };

  // const Edit_Portfolio = (data) => {
  //   const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
  //   let file;

  //   if (data.image) {
  //     // Function to convert base64 to binary file
  //     const base64ToFile = (base64String, filename) => {
  //       // Remove the data URL prefix
  //       const byteString = atob(base64String.split(",")[1]);
  //       // Get the MIME type from the base64 string
  //       const mimeType = base64String.match(/:(.*?);/)[1];
  //       // Create an ArrayBuffer to hold the binary data
  //       const ab = new ArrayBuffer(byteString.length);
  //       // Create a typed array from the ArrayBuffer
  //       const ia = new Uint8Array(ab);
  //       // Convert the binary string to binary data
  //       for (let i = 0; i < byteString.length; i++) {
  //         ia[i] = byteString.charCodeAt(i);
  //       }
  //       // Create a Blob from the binary data
  //       const blob = new Blob([ia], { type: mimeType });
  //       // Create a File from the Blob
  //       return new File([blob], filename, { type: mimeType });
  //     };

  //     file = base64ToFile(data.image, uploadFileNameEdit);
  //   }
  //   console.log(file, "data123");

  //   if (data.name == "") {
  //     toast.warning("Please enter the portfolio name");
  //     //setinstituteNameError(true);
  //     return false;
  //   }
  //   if (data.description == "") {
  //     toast.warning("Please enter the description");
  //     //setedustartError(true);
  //     return false;
  //   }
  //   setinstituteNameError(false);
  //   setedustartError(false);
  //   seteduendError(false);

  //   var formData = new FormData();
  //   formData.append("id", data.id);
  //   formData.append("user_id", user_id);
  //   formData.append("name", data.name);
  //   formData.append("description", data.description);
  //   if (file) {
  //     formData.append("image", file);
  //   }
  //   // formData.append("image", data.image);
  //   axios.post("/update-portfolio", formData).then((res) => {
  //     if (res.error == 0) {
  //       toast.success(res.message);
  //       t_Portfolio(data.id, true);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };

  const handleSummarySuggestion = (e) => {
    e.preventDefault();
    setIsGenerating(true);
    // Your logic to interact with ChatGPT-4 API or AI service to suggest a job description
    console.log("handle suggestion here");
    const postData = {
      profileTitle: profileTitle,
    };
    axios
      .post("generate-profile-summary", postData)
      .then((res) => {
        console.log(res);
        const formattedDescription = res.jobDescription.replace(/\n/g, "<br>");
        setProfileSummary(formattedDescription);
        setIsGenerating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePortfolio = (id) => {
    const postData = {
      id: id,
    };
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios.post("/delete-portfolio", postData).then((res) => {
              if (res.error === 0) {
                toast.success(res.message);
                fetchUserProfile();
              }
              if (res.error === 1) {
                toast.error(res.message);
              }
            });
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const addhistory = async (data) => {
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;

    // Validation checks
    if (data.company_name === "") {
      toast.warning("Please enter the company name");
      return; // Stop further execution if validation fails
    }
    if (data.role === "") {
      toast.warning("Please enter Role");
      return; // Stop further execution if validation fails
    }
    if (data.start_date === "") {
      toast.warning("Please select the start date");
      return;
    }
    if (!data.checkbox && data.end_date === "") {
      toast.warning("Please select the end date");
      return;
    }

    // Additional validation: Check if start date is less than end date
    const startDate = new Date(data.start_date);
    const endDate = data.checkbox ? null : new Date(data.end_date);

    if (!data.checkbox && startDate >= endDate) {
      toast.warning("Start date should be less than the end date");
      return;
    }

    // Get the full address, handle the case when it returns undefined
    const geocodedData = await geocodeAddress(employeelocation);

    if (
      !geocodedData ||
      !geocodedData.latitude ||
      !geocodedData.longitude ||
      !geocodedData.formattedAddress
    ) {
      throw new Error("Invalid address data received");
    }

    const {
      latitude,
      longitude,
      formattedAddress,
      state,
      suburb,
      postal_code,
      country,
    } = geocodedData;

    // If all validations pass, proceed with the API call
    const postData = {
      workforce_profile_id: userId,
      location: formattedAddress,
      description: data.description,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: data.checkbox ? null : moment(endDate).format("YYYY-MM-DD"),
      is_current: data.checkbox,
      role: data.role,
      company_name: data.company_name,
    };

    axios.post("/add-employment-history", postData).then((res) => {
      if (res.message === "Success") {
        toast.success("Employee History Added Successfully", {
          theme: "light",
        });
        t_addhistory();
      }
      fetchUserProfile();
    });
  };

  const edithistory = async (data) => {
    // Validation checks
    if (data.company_name.trim() === "") {
      toast.warning("Please enter the company name");
      return;
    }
    if (data.role === "") {
      toast.warning("Please enter Role");
      return; // Stop further execution if validation fails
    }
    if (data.start_date.trim() === "") {
      toast.warning("Please select the start date");
      return;
    }
    if (!data.is_current && data.end_date === "") {
      toast.warning("Please select the end date");
      return;
    }

    // Additional validation: Check if start date is less than end date
    const startDate = new Date(data.start_date);
    const endDate = data.is_current ? null : new Date(data.end_date);
    if (!data.is_current && startDate >= endDate) {
      toast.warning("Start date should be less than the end date");
      return;
    }

    // Get the full address, handle the case when it returns undefined
    const geocodedData = await geocodeAddress(data.location);

    if (
      !geocodedData ||
      !geocodedData.latitude ||
      !geocodedData.longitude ||
      !geocodedData.formattedAddress
    ) {
      throw new Error("Invalid address data received");
    }

    const {
      latitude,
      longitude,
      formattedAddress,
      state,
      suburb,
      postal_code,
      country,
    } = geocodedData;

    // Continue with the API call if validations pass
    const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
    const postData = {
      id: data.id,
      user_id: user_id,
      description: data.description,
      workforce_profile_id: data.workforce_profile_id,
      role: data.role,
      start_date: data.start_date,
      end_date: data.is_current ? null : data.end_date,
      company_name: data.company_name,
      location: formattedAddress,
      is_current: data.is_current,
    };

    console.log(postData, "editemployementhistory");

    axios.post("/edit-employment-history", postData).then((res) => {
      if (res.message === "Success") {
        toast.success("Employee History has been updated");
        t_history(data.id, true);
      } else {
        toast.error(res.message);
      }
    });
  };

  // function handleIndustryMulti(selectedMulti) {
  //     // Map the selected options to the desired format
  //     const selectedIndustries = selectedMulti.map(option => ({
  //         value: option.value,
  //         label: option.label
  //     }));

  //     // Filter out duplicates by checking for unique IDs
  //     const uniqueIndustries = Array.from(new Set([...industryNames, ...selectedIndustries]));

  //     // Set the updated industry names as state
  //     setIndustryNames(uniqueIndustries);

  //     // If you want to log the selected options
  //     console.log(selectedMulti);
  // }

  function handleIndustryMulti(selectedLeadStatus) {
    setSelectedIndustry(selectedLeadStatus);
  }
  function handleSkillsMulti(selectedLeadStatus) {
    setSelectedSkills(selectedLeadStatus);
    // console.log(selectedLeadStatus);
  }
  // console.log(selectedSkills);
  function handleDocType(selectedDoc) {
    setSelectedDocType(selectedDoc);
  }

  function handleWorkTypeMulti(selectedMulti) {
    setPreferredWorkType(selectedMulti.label);
  }

  const IndustryNameValue = industryNames?.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));

  const industrySelectOptions = allIndustryNames.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));

  const PreferredWorkTypeOptions = [
    "Full Time",
    "Part Time",
    "Casual",
    "Contract",
    "Freelance",
  ];
  // -------- React-tag-input code ----------- //

  // -------- End ----------- //

  const handleSummaryChange = (value) => {
    // const inputText = e.target.value;
    if (value.length <= 3000) {
      setProfileSummary(value);
      setProfileSummaryError("");
    }
  };

  const characterCount = `${
    profileSummary?.length === undefined ? 0 : profileSummary?.length
  }/3000`;

  const handlePlaceSelected = async (place) => {
    // Check if geometry is present
    if (!place.geometry || !place.geometry.location) {
      console.error("Invalid place data:", place);
      return;
    }

    // Extract latitude and longitude from the selected place object
    const { lat, lng } = place.geometry.location;

    // Extract different components of the address
    const addressComponents = place.address_components || [];
    let street = "";
    let streetNumber = "";
    let suburb = "";
    let state = "";
    let postalCode = "";
    let country = "";

    for (const component of addressComponents) {
      if (component.types.includes("street_number")) {
        // Component type 'street_number' represents the street number
        streetNumber = component.long_name;
      } else if (component.types.includes("route")) {
        // Component type 'route' represents the street
        street = component.long_name;
      } else if (component.types.includes("locality")) {
        // Component type 'locality' represents the suburb
        suburb = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        // Component type 'administrative_area_level_1' represents the state
        state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        // Component type 'postal_code' represents the postal code
        postalCode = component.long_name;
      } else if (component.types.includes("country")) {
        // Component type 'postal_code' represents the postal code
        country = component.long_name;
      }
    }

    // Concatenate city and state
    const suburbWithState =
      suburb && state ? `${suburb}, ${state}` : suburb || state || "";

    // Concatenate street number and name
    const fullStreet =
      streetNumber && street
        ? `${streetNumber} ${street}`
        : streetNumber || street || "";

    const fullAddressComponents = [fullStreet, suburbWithState, country];
    const filteredAddressComponents = fullAddressComponents.filter(
      (component) => component
    );

    // Construct the full address
    const fullAddress = filteredAddressComponents.join(", ");

    // Update state with selected location details
    setSelectedLocation({
      latitude: lat(),
      longitude: lng(),
    });

    // Update state with street and suburb
    setStreet(fullStreet);
    setSuburb(suburb);
    setCountry(country);
    setFullAddress(fullAddress);
    setSuburbWithState(suburbWithState);
    setPostalCode(postalCode);
  };

  // Assuming userProfileImg is the image URL
  const profleImageUrl = userProfileImg;

  // Function to validate mobile numbers for US and Australia
  const isValidMobileNumber = (number) => {
    // Regular expressions for US and Australian mobile numbers
    const usRegex = /^(?:\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/;
    const auRegex = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;

    // Check if the number matches either US or Australian format
    return usRegex.test(number) || auRegex.test(number);
  };

  // Function to call the Google Geocoding API to convert an address to lat/lng
  const geocodeAddress = async (address) => {
    const apiKey = "AIzaSyDPs4Niq_SNsZTPdNDNoOVfErDW2CQ4S3k"; // API key for Google Geocoding API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`; // Construct the API request URL

    try {
      // Step 1: Fetch the geocoding data from the API
      const response = await fetch(url);
      const data = await response.json();
      console.log("Geocode API Response:", data);

      // Step 2: If the API response contains valid results, extract the location
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location; // Extract latitude & longitude
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;

        setSelectedLocation({
          latitude: location.lat,
          longitude: location.lng,
        });
        // Extract address components
        const { suburb, state, postalCode, country } =
          extractAddressDetails(addressComponents);

        // Log the retrieved latitude and longitude
        console.log(
          `geocodeAddress = Latitude: ${location.lat}, Longitude: ${location.lng}`
        );

        // Return the latitude, longitude, and formatted address
        return {
          latitude: location.lat,
          longitude: location.lng,
          formattedAddress: formattedAddress,
          state: state,
          suburb: suburb,
          postal_code: postalCode,
          country: country,
        };
      } else {
        // If no results from the API, handle the error
        console.error("No results from geocoding API");
        Alert("Error", "Unable to fetch location from the address.");
        return null;
      }
    } catch (error) {
      // Handle any errors during the API request
      console.error("Error during geocoding fetch:", error);
      Alert("Error", "Failed to fetch location from Google.");
      return null;
    }
  };

  /* ---- Helper Function for Extracting Address Components ---- */
  const extractAddressDetails = (addressComponents) => {
    console.log(addressComponents, "addressComponents");
    const suburb =
      addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name || ""; // Default to empty string if not found

    const state =
      addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name || ""; // Fallback to empty string

    const country =
      addressComponents.find((component) => component.types.includes("country"))
        ?.long_name || ""; // Fallback to empty string

    const postalCode =
      addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.long_name || ""; // Fallback to empty string

    // Rebuild the full formatted address excluding postal code
    const formattedAddress = `${suburb ? suburb + ", " : ""}${state}${
      country ? ", " + country : ""
    }`;

    return { formattedAddress, suburb, state, postalCode, country };
  };
  /* ---- End of Helper Function ---- */

  const save_Profile = async (e) => {
    e.preventDefault();
    // Call the geocodeAddress function with fullAddress
    const geocodedData = await geocodeAddress(fullAddress);

    console.log("first");
    console.log(
      "firstName :",
      firstName,
      "lastName :",
      lastName,
      "profileTitle :",
      profileTitle,
      "fullAddress :",
      fullAddress,
      "geocodedData:",
      geocodedData
    );

    // Check if a save operation is already in progress
    if (isSaving) {
      return;
    }

    let file;

    if (croppedImgSrc) {
      // Function to convert base64 to binary file
      const base64ToFile = (base64String, filename) => {
        // Remove the data URL prefix
        const byteString = atob(base64String.split(",")[1]);
        // Get the MIME type from the base64 string
        const mimeType = base64String.match(/:(.*?);/)[1];
        // Create an ArrayBuffer to hold the binary data
        const ab = new ArrayBuffer(byteString.length);
        // Create a typed array from the ArrayBuffer
        const ia = new Uint8Array(ab);
        // Convert the binary string to binary data
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        // Create a Blob from the binary data
        const blob = new Blob([ia], { type: mimeType });
        // Create a File from the Blob
        return new File([blob], filename, { type: mimeType });
      };

      file = base64ToFile(croppedImgSrc, uploadFileName);
    }

    setIsSaving(true);

    // Check if required fields are not empty or null
    if (!firstName) {
      toast.error("Please enter your first name.");
      setIsSaving(false);
      return;
    }

    if (!lastName) {
      toast.error("Please enter your last name.");
      setIsSaving(false);
      return;
    }

    if (!profileTitle) {
      toast.error("Please enter your profile title");
      setIsSaving(false);
      return;
    }

    // Check if required fields are not empty or null
    if (!isValidMobileNumber(contactNumber)) {
      toast.error(
        "Please enter a valid mobile number for the US or Australia."
      );
      setIsSaving(false);
      return;
    }

    if (!fullAddress) {
      toast.error("Please enter your location.");
      setIsSaving(false);
      return;
    }

    // Check the geocoded data
    console.log(geocodedData, "geocodedData");

    if (
      !geocodedData ||
      !geocodedData.latitude ||
      !geocodedData.longitude ||
      !geocodedData.formattedAddress
    ) {
      throw new Error("Invalid address data received");
    }

    const {
      latitude,
      longitude,
      formattedAddress,
      state,
      suburb,
      postal_code,
      country,
    } = geocodedData;

    console.log("locationError");
    // console.log(selectedSkills)
    const skillsJsonString = JSON.stringify(selectedSkills);

    var formData = new FormData();
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("profile_title", profileTitle);
    formData.append("email", email);
    formData.append("mobile", contactNumber);
    formData.append("deleteProfilePicture", deleteProfilePicture);
    formData.append("user_skills", skillsJsonString);
    if (fullAddress && formattedAddress) {
      formData.append("address", formattedAddress);
    }
    formData.append("suburb", suburb);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("postcode", postal_code);
    formData.append("country", country);
    // if (file) {
    //   formData.append("profilepicture", croppedImgSrc);
    // }
    if (file) {
      formData.append("profilepicture", file);
    }
    if (userId) {
      // If userId is not empty, update the data
      formData.append("id", userId);
    }
    console.log(formData);
    axios
      .post("/add-user-profile", formData)
      .then((response) => {
        // console.log(response);
        if (response.error === 0) {
          toast.success(response.message, { theme: "light" });
          fetchUserProfile();
          setShowProfile(!showProfile);
        }
        if (response.error === 1) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while processing your request.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const save_Avatar = () => {
    var formData = new FormData();
    formData.append("profilepicture", uploadedImage);

    // console.log("formData", formData);
    if (userId) {
      // If userId is not empty, update the data
      formData.append("id", userId);
    }
    // console.log(formData);
    axios
      .post("/add-user-profile", formData)
      .then((response) => {
        if (response.error === 0) {
          toast.success(response.message, { theme: "light" });
          fetchUserProfile();
          // setShowProfile(!showProfile);
        }
        if (response.error === 1) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while processing your request.");
      });
  };

  function formatDateRange(startDate, endDate) {
    const formattedStartDate = new Date(startDate).toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });

    if (
      endDate &&
      endDate.trim() !== "" &&
      endDate.toLowerCase() !== "present"
    ) {
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });

      return `${formattedStartDate} - ${formattedEndDate}`;
    }

    return `${formattedStartDate} - Present`;
  }

  const save_Profession = (event) => {
    event.preventDefault();

    // Check if a save operation is already in progress
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    if (profileSummary === "" || profileSummary === null) {
      setProfileSummaryError("Please enter your profile summary.");
      setIsSaving(false);
      return;
    } else {
      setProfileSummaryError("");
    }

    // If any of the required fields are empty, do not proceed with form submission.
    if (profileSummary === "") {
      setIsSaving(false);
      return;
    }

    // Proceed with the form submission
    var formData = new FormData();
    // formData.append("profile_title", profileTitle);
    formData.append("profile_summary", profileSummary);

    if (userId) {
      // If userId is not empty, update the data
      formData.append("id", userId);
    }

    axios
      .post("/add-profile-section", formData)
      .then((response) => {
        if (response.error === 0) {
          toast.success(response.message, { theme: "light" });
          fetchUserProfile();
        } else {
          toast.error(response.message, { theme: "light" });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setIsSaving(false);
        setShowProfession(!showProfession);
      });
  };

  // preferrences validation
  const validateHourlyRate = (value) => {
    const regex = /^\d{1,5}(\.\d{0,2})?$/;
    return regex.test(value);
  };

  const validateMonthlySalary = (value) => {
    const regex = /^\d{1,6}(\.\d{0,2})?$/;
    return regex.test(value);
  };

  const handleHourlyRateChange = (e) => {
    const newValue = e.target.value;
    if (validateHourlyRate(newValue) || newValue === "") {
      setHourlyRate(newValue);
    }
  };

  const handleMonthlySalaryChange = (e) => {
    const newValue = e.target.value;
    if (validateMonthlySalary(newValue) || newValue === "") {
      setMonthlySalary(newValue);
    }
  };

  document.title = "Manage Profile | Get Rostered";

  // toogle for profile private
  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    const formData = {
      user_id: userId,
      make_profile_private: newCheckedState ? 1 : 0,
    };
    axios
      .post("/update-profile-privacy", formData)
      .then((res) => {
        console.log(res.message); // Assuming the message is sent in the response data
        fetchUserProfile();
      })
      .catch((err) => {
        toast.error("Error updating profile privacy");
      });
  };

  // Reviews
  const ReadMore = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => setIsExpanded(!isExpanded);

    // Only show "Read more" if the text length exceeds 75 characters
    const shouldShowReadMore = text?.length > 75;

    return (
      <div>
        <p
          className={`text-muted mb-1 fst-italic ${
            isExpanded ? "" : "text-truncate-two-lines"
          }`}
        >
          {isExpanded ? text : `${text?.slice(0, 75)}`}
          {shouldShowReadMore && (
            <span
              onClick={toggleReadMore}
              style={{ color: "#865ce2", cursor: "pointer" }}
            >
              {isExpanded ? " Show less" : " Read more"}
            </span>
          )}
        </p>
      </div>
    );
  };

  const getRelativeTime = (reviewDate) => {
    const now = new Date();
    const reviewDateObj = new Date(reviewDate);

    const differenceInTime = now.getTime() - reviewDateObj.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays === 0) {
      return "Today";
    } else if (differenceInDays === 1) {
      return "Yesterday";
    } else if (differenceInDays === -1) {
      return "Tomorrow";
    } else if (differenceInDays > 1) {
      return `${differenceInDays} days ago`;
    } else if (differenceInDays < -1) {
      return `In ${Math.abs(differenceInDays)} days`;
    } else {
      return reviewDate; // fallback to the original date format
    }
  };

  // Function to handle location access
  const handleGetCurrentLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      // Request user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ lat: latitude, lng: longitude });
          reverseGeocodeCoordinates(latitude, longitude);
          setError(null); // Clear any previous errors
        },
        (err) => {
          setError("Location access denied or not available.");
          setAddressFocused(false);
          setAddPreffFocused(false);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const reverseGeocodeCoordinates = async (latitude, longitude) => {
    const apiKey = "AIzaSyDPs4Niq_SNsZTPdNDNoOVfErDW2CQ4S3k"; // Google API key for reverse geocoding
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`; // Construct the API request URL

    // Step 1: Fetch the reverse geocoding data from the API
    await fetch(url)
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        // setIsLoading(false); // Optionally stop any loading indicator

        console.log(data, "data reverse");
        // Step 2: If the API response contains valid results, extract the address
        if (data.results && data.results.length > 0) {
          const address = data.results[0].formatted_address; // Extract the formatted address
          console.log(address, "CurrentAddress"); // Log the retrieved address

          if (addressFocused) {
            setFullAddress(address);
          }

          if (addPreffFocused) {
            setselectedSuburb(address);
          }

          setAddressFocused(false);
          setAddPreffFocused(false);
        } else {
          // If no results, handle the error
          Alert.alert("Error", "Unable to fetch address from your location.");
        }
      })
      .catch((error) => {
        // Handle any errors during the API request
        Alert.alert("Error", "Failed to fetch address from Google.");
        console.error(error); // Log the error for debugging purposes
      });
  };

  // const handleSelect = async (value) => {
  //   const results = await geocodeByAddress(value);
  //   const latLng = await getLatLng(results[0]);
  //   setAddress(value);
  //   setCoordinates(latLng);
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Profile" pageTitle="MyProfile" />
          {!isLoading ? (
            <>
              <Collapse isOpen={!showProfile} id="collapseprofile">
                <Row>
                  <Col xl={12}>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                      <div className="profile-wid-bg">
                        <img
                          src={profileBg}
                          alt=""
                          className="profile-wid-img"
                        />
                      </div>
                    </div>

                    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                      <div
                        className="position-absolute align-items-center form-switch form-switch-md"
                        style={{ right: "25px", zIndex: 1 }}
                      >
                        <div className="hstack gap-2 float-end">
                          <h6 className=" mb-0 text-white">
                            Make my profile private
                          </h6>
                          <Input
                            type="checkbox"
                            className={`form-check-input check-private ${
                              isChecked ? "checked" : ""
                            }`}
                            style={{ left: 0, marginLeft: "0px" }}
                            id="customSwitchsizemd"
                            onChange={handleChange}
                            checked={isChecked}
                          />
                        </div>
                        <Badge
                          className="me-2"
                          color={`${isChecked ? "dark" : "success"} `}
                        >
                          {isChecked ? "Private" : "Public"}
                        </Badge>
                      </div>

                      <Row className="align-items-center">
                        <div className="col-1   ">
                          <div className="avatar-lg">
                            {userProfileImg ? (
                              <img
                                src={profleImageUrl}
                                alt="user-img"
                                className="img-thumbnail h-100 w-100 rounded-circle"
                              />
                            ) : (
                              <img
                                src={userDummy}
                                alt="user-img"
                                className="img-thumbnail rounded-circle"
                              />
                            )}
                          </div>
                        </div>

                        <Col
                          className="col-lg-8 mt-3 py-4 pb-1 ps-4 "
                          // style={{ borderRight: "0.5px solid #e8e8e8" }}
                        >
                          <div>
                            {username && (
                              <div className="d-flex gap-2 mt-n2 justify-content-between">
                                <div className="d-flex gap-3 align-items-center ">
                                  <h3 className="mb-0 text-white">
                                    {username}{" "}
                                  </h3>
                                  <div className="d-flex align-items-center justify-content-end">
                                    {[1, 2, 3, 4, 5].map((star) => {
                                      const integerPart =
                                        Math.floor(userRating); // Integer part of the rating
                                      const isHalfStar =
                                        star === integerPart + 1 &&
                                        userRating % 1 !== 0; // Check if it's a half star
                                      const starClass = isHalfStar
                                        ? "ri-star-half-s-fill" // Half star icon
                                        : star <= integerPart
                                        ? "ri-star-s-fill" // Full star icon
                                        : "ri-star-s-line"; // Empty star icon

                                      const starColor =
                                        star <= integerPart || isHalfStar
                                          ? "gold" // Color for filled and half stars
                                          : "white"; // Color for empty stars

                                      return (
                                        <i
                                          className={starClass}
                                          key={star}
                                          style={{
                                            fontSize: "20px",
                                            color: starColor, // Set color based on whether it's a full, half, or empty star
                                            marginRight: "2px",
                                          }}
                                        ></i>
                                      );
                                    })}
                                    <Badge
                                      color="primary"
                                      className="badgems-1 px-1 ms-2 bold fs-12 "
                                      style={{ paddingBottom: "2.8px" }}
                                    >
                                      {userRating}
                                    </Badge>
                                  </div>
                                </div>

                                {/* <h6>{profileTitle && ( profileTitle )}</h6> */}
                              </div>
                            )}
                            {profileTitle && (
                              <p className="fs-14 mb-1 mt-1 text-white">
                                {profileTitle}
                              </p>
                            )}
                            {fullAddress && (
                              <div className="text-muted hstack gap-1 mb-1 align-items-center">
                                <i className="ri-map-pin-line me-1 fs-15 text-white"></i>
                                <span className="text-white-75 ">
                                  {fullAddress}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="hstack flex-wrap gap-2 mt-2">
                            {console.log(selectedSkills, "selected skills")}
                            {selectedSkills && selectedSkills !== null ? (
                              selectedSkills?.map((skill) => {
                                return (
                                  <span
                                    key={skill.value}
                                    className="badge badge-soft-light text-white fs-12 me-2"
                                  >
                                    {skill.label}
                                  </span>
                                );
                              })
                            ) : (
                              <span className="badge badge-soft-primary fs-12 me-2">
                                None
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col className="col-3 vstack justify-content-end">
                          {contactNumber && (
                            <div className=" hstack gap-2 px-3  mt-4 text-right justify-content-end  text-white ">
                              <i className=" bx bx-phone fs-14"></i>
                              <span className="py-1 ">
                                Phone: {contactNumber}
                              </span>
                            </div>
                          )}
                          {email && (
                            <div className=" hstack gap-2 text-right  justify-content-end px-3  text-white ">
                              <i className=" ri-mail-line fs-14"></i>
                              <span className="py-1 "> {email}</span>
                            </div>
                          )}
                          <div className="hstack text-white justify-content-end">
                            <Button
                              color="primary"
                              onClick={t_Profile}
                              outline
                              style={{ width: "fit-content" }}
                              className="btn btn-ghost-dark btn-sm align-items-center gap-2 hstack  me-2 fw-semibold text-white"
                            >
                              <i className="bx bx-pencil fs-14"></i>
                              <span>Edit Profile</span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Collapse>
              <Collapse isOpen={showProfile} id="collapseprofile">
                <Row>
                  <Col xxl={12}>
                    <Card className="mt-0">
                      <CardBody className="p-4">
                        <div className="d-flex justify-content-center gap-4">
                          <div className="mb-4">
                            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                              {/* Display the selected image or the S3 image */}
                              {userProfileImg ? (
                                <>
                                  <img
                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                    alt="Company Logo 1"
                                    src={
                                      croppedImgSrc
                                        ? croppedImgSrc
                                        : profleImageUrl
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {croppedImgSrc ? (
                                    <img
                                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                      alt="Company Logo 2"
                                      src={croppedImgSrc}
                                    />
                                  ) : (
                                    <img
                                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                      alt="Company Logo 3"
                                      src={userDummy}
                                    />
                                  )}
                                </>
                              )}
                              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                <Input
                                  id="profile-img-file-input"
                                  // type="file"
                                  onClick={() => profile_modal()}
                                  onChange={onSelectFile}
                                  accept=".jpeg, .jpg, .png"
                                  className="profile-img-file-input"
                                />
                                <Label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit avatar-xs"
                                >
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="ri-camera-fill"></i>
                                  </span>
                                </Label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="firstnameInput"
                                  className="form-label"
                                >
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className={"form-control"}
                                  id="firstnameInput"
                                  placeholder="Enter your firstname"
                                  value={firstName}
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setFirstNameError("");
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="lastnameInput"
                                  className="form-label"
                                >
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className={"form-control"}
                                  id="lastnameInput"
                                  placeholder="Enter your lastname"
                                  value={lastName}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="profileTitleInput"
                                  className="form-label"
                                >
                                  Professional Headline{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="profileTitleInput"
                                  placeholder="Enter your profile title"
                                  value={profileTitle}
                                  onChange={(e) => {
                                    setProfileTitle(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emailInput"
                                  className="form-label"
                                >
                                  Address <span className="text-danger">*</span>
                                </Label>
                                {addressFocused && (
                                  <span
                                    className=" ps-4 text-info cursor-pointer"
                                    onClick={handleGetCurrentLocation}
                                  >
                                    {" "}
                                    Use Current Location
                                  </span>
                                )}
                                <div className="mb-3">
                                  <GoogleAutocomplete
                                    className={"form-control"}
                                    placeholder="Enter Address"
                                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                    value={fullAddress}
                                    onChange={(e) => {
                                      setFullAddress(e.target.value);
                                    }}
                                    onFocus={() => setAddressFocused(true)}
                                    onKeyUp={(e) => handleKeyUp(e, "location")}
                                    onPlaceSelected={handlePlaceSelected}
                                    options={{
                                      types: ["geocode"],
                                      componentRestrictions: { country: "au" },
                                    }}
                                    style={{
                                      // border: "1px solid #ccc",
                                      padding: "10px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emailInput"
                                  className="form-label"
                                >
                                  Email Address
                                </Label>
                                <Input
                                  disabled
                                  type="email"
                                  className="form-control"
                                  id="emailInput"
                                  placeholder="Enter your email"
                                  value={email}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="contactNumberInput"
                                  className="form-label"
                                >
                                  Phone Number
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="contactNumberInput"
                                  placeholder="Enter your Contact  Number"
                                  value={contactNumber}
                                  onChange={(e) => {
                                    setContactNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="skillsInput"
                                  className="form-label"
                                >
                                  Skills
                                </Label>
                                <Select
                                  value={selectedSkills}
                                  isMulti={true}
                                  isClearable={true}
                                  onChange={(selectedOption) => {
                                    handleSkillsMulti(selectedOption);
                                  }}
                                  options={skills?.map((skill) => ({
                                    value: skill.id,
                                    label: skill.name,
                                  }))}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-soft-danger"
                                  onClick={t_Profile}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-success"
                                  onClick={(e) => save_Profile(e)}
                                >
                                  Update
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Collapse>
              <Row>
                {/* Left side - column layout */}
                <Col xl={4} style={{ paddingRight: 0 }}>
                  <Collapse isOpen={!showSection1} id="collapse_sec1">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center justify-content-between pt-2 pb-2">
                        <div className="hstack">
                          <i className="text-d-primary fs-18 me-1 bx bxs-wrench"></i>
                          <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                            Preferences
                          </h4>
                        </div>
                        <i
                          className="ri-edit-2-line text-primary"
                          onClick={t_Sec1}
                          style={{ cursor: "pointer", fontSize: 25 }}
                        />
                      </CardHeader>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table-borderless mb-0">
                            <tbody>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Industries
                                </th>
                                <td className="fw-normal pe-0">
                                  {industryNames.length !== 0 ? (
                                    industryNames.map((industry, index) => (
                                      <span key={industry.id}>
                                        {index > 0 && ", "}
                                        {industry.name}
                                      </span>
                                    ))
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Preferred Work Type
                                </th>
                                <td className="fw-normal pe-0">
                                  {workPreferrences ? (
                                    <>
                                      {workPreferrences.is_employee !== 0 ||
                                      workPreferrences.is_contractor !== 0 ||
                                      workPreferrences.is_volunteer !== 0 ? (
                                        <>
                                          {workPreferrences.is_employee !==
                                            0 && <span>Employee</span>}
                                          {workPreferrences.is_contractor !==
                                            0 && (
                                            <>
                                              {workPreferrences.is_employee !==
                                                0 && ", "}
                                              Contractor
                                            </>
                                          )}
                                          {workPreferrences.is_volunteer !==
                                            0 && (
                                            <>
                                              {(workPreferrences.is_employee !==
                                                0 ||
                                                workPreferrences.is_contractor !==
                                                  0) &&
                                                ", "}
                                              Volunteer
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Hourly rate
                                </th>
                                <td className="fw-normal  pe-0">
                                  {workPreferrences &&
                                  workPreferrences?.desired_hourly_rate !==
                                    null ? (
                                    `$${workPreferrences?.desired_hourly_rate}`
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Monthly Salary
                                </th>
                                <td className="fw-normal pe-0">
                                  {workPreferrences &&
                                  workPreferrences.desired_monthly_salary !==
                                    null ? (
                                    `$${workPreferrences?.desired_monthly_salary}`
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Available Start Date
                                </th>
                                <td className="fw-normal pe-0">
                                  {" "}
                                  {workPreferrences &&
                                  availableStartDate !== null ? (
                                    moment(availableStartDate)?.format(
                                      "MMMM D, Y"
                                    )
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  className="ps-0 text-muted fw-normal"
                                  scope="row"
                                >
                                  Current Availability
                                </th>
                                <td className="fw-normal pe-0">
                                  {" "}
                                  {currentAvailability &&
                                  currentAvailability?.total_hours !== null
                                    ? currentAvailability?.total_hours
                                    : 0}{" "}
                                  Hrs
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={showSection1} id="collapse_sec1">
                    <Card className="mb-2 px-2">
                      <CardBody>
                        <Form>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="industries"
                                  className="form-label"
                                >
                                  Industries
                                </Label>
                                <Select
                                  value={selectedIndustry}
                                  id="industries"
                                  isMulti={true}
                                  isClearable={true}
                                  onChange={(selectedOption) => {
                                    handleIndustryMulti(selectedOption);
                                  }}
                                  options={allIndustryNames?.map(
                                    (industry) => ({
                                      value: industry.id,
                                      label: industry.name,
                                    })
                                  )}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="workType"
                                  className="form-label "
                                >
                                  Preferred Work Type
                                </Label>
                                <div className="hstack gap-3 mt-1">
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="employee"
                                      checked={employeeChecked}
                                      onChange={() =>
                                        setEmployeeChecked(!employeeChecked)
                                      }
                                    />
                                    <Label
                                      htmlFor="employee"
                                      className="form-check-label "
                                    >
                                      Employee
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="contractor"
                                      checked={contractorChecked}
                                      onChange={() =>
                                        setContractorChecked(!contractorChecked)
                                      }
                                    />
                                    <Label
                                      htmlFor="contractor"
                                      className="form-check-label "
                                    >
                                      Contractor
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="volunteer"
                                      checked={volunteerChecked}
                                      onChange={() =>
                                        setVolunteerChecked(!volunteerChecked)
                                      }
                                    />
                                    <Label
                                      htmlFor="volunteer"
                                      className="form-check-label "
                                    >
                                      Volunteer
                                    </Label>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="hourlyRateInput"
                                  className="form-label"
                                >
                                  Hourly rate
                                </Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="hourlyRateInput"
                                  value={hourlyRate}
                                  onChange={handleHourlyRateChange}
                                  pattern="\d{1,5}(\.\d{0,2})?$"
                                  // step="0.01" // Set the step to 0.01 for two decimal places
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="monthlySalaryInput"
                                  className="form-label"
                                >
                                  Monthly Salary
                                </Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="monthlySalaryInput"
                                  value={monthlySalary}
                                  onChange={handleMonthlySalaryChange}
                                  pattern="\d{1,6}(\.\d{0,2})?$"
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Available Start Date
                                </Label>
                                <Flatpickr
                                  className="form-control"
                                  value={
                                    availableStartDate !== null &&
                                    availableStartDate
                                  } // Pass the date string directly
                                  options={{
                                    dateFormat: "Y-m-d", // Specify the format of the input date string
                                    altInput: true,
                                    altFormat: "d-m-Y", // Set the desired format for display
                                  }}
                                  onChange={(selectedDates) => {
                                    if (selectedDates.length === 0) {
                                      // If date is cleared, set availableStartDate to null
                                      setAvailableStartDate(null);
                                    } else {
                                      // Update state with formatted date
                                      setAvailableStartDate(
                                        moment(selectedDates[0]).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <Tooltip
                                target="infoTooltip"
                                isOpen={tooltipOpen}
                                toggle={toggleTooltip}
                                placement="top"
                              >
                                Current Availability is calculated based on your
                                Preferred Availability.
                              </Tooltip>
                              <div className="mb-3">
                                <div className="hstack gap-1">
                                  <Label className="form-label">
                                    Current Availability
                                  </Label>
                                  <i
                                    id="infoTooltip"
                                    size={15}
                                    style={{ color: "#4db9dc" }}
                                    className="ri-information-line mb-1"
                                    onClick={toggleTooltip}
                                  ></i>
                                </div>
                                <input
                                  type="number"
                                  disabled
                                  className="form-control"
                                  value={
                                    currentAvailability?.total_hours
                                      ? currentAvailability?.total_hours
                                      : 0
                                  }
                                  onChange={(e) => {
                                    setCurrentAvailability(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-soft-danger"
                                  onClick={t_Sec1}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-success"
                                  onClick={(e) => save_Sec1(e)}
                                >
                                  Update
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={!showlocation} id="collapseeducation">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center justify-content-between pt-2 pb-2">
                        <div className="hstack">
                          <i className="text-d-primary fs-18 me-1 ri-pin-distance-fill"></i>
                          <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                            Preferred Work Locations
                          </h4>
                        </div>
                        <div className="hstack gap-3">
                          <i
                            className="ri-add-line text-primary"
                            onClick={t_addlocation}
                            style={{ cursor: "pointer", fontSize: 25 }}
                          />
                          <i
                            className="ri-edit-2-line text-success"
                            style={{
                              cursor: "pointer",
                              fontSize: 20,
                            }}
                            onClick={handleLocationEditClick}
                          />
                        </div>
                      </CardHeader>
                      <CardBody>
                        {userlocation?.map((item, index) => {
                          return (
                            <>
                              {!item.isEditing && (
                                <div className="mb-2 pb-2" key={index}>
                                  <div className="location-container hstack ">
                                    <div className="hstack col-8 gap-4 justify-content-between">
                                      <h5>{item.location}</h5>
                                      <h6>{item.radius_distance} Km</h6>
                                    </div>
                                    <div className="hstack col-4 gap-2 justify-content-end">
                                      {isLocationEditClicked && (
                                        <>
                                          <i
                                            className="ri-edit-2-line text-success"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() => t_location(item.id)}
                                          />
                                          <i
                                            className="bx bx-trash text-danger"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() => deletelocation(item)}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {item.isEditing && (
                                <div className="mb-2 pb-2" key={index}>
                                  <div className="" key={index}>
                                    <h4 className="mb-2">
                                      Location {index + 1}
                                    </h4>
                                    <div className="justify-content-between align-items-center mb-2">
                                      <div className="education-details text-muted">
                                        <Row>
                                          <Col
                                            lg={12}
                                            className="d-flex flex-column mt-2"
                                          >
                                            <Label>Suburb</Label>
                                            <GoogleAutocomplete
                                              apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                              value={item.location}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                setInputValue(value); // Update the state with the new value

                                                const updatedData =
                                                  userlocation.map((task) =>
                                                    task.id === item.id
                                                      ? {
                                                          ...task,
                                                          location: value,
                                                        }
                                                      : task
                                                  );
                                                setUserlocation(updatedData);
                                              }}
                                              id="editlocation"
                                              onKeyUp={(e) =>
                                                handleKeyUp(
                                                  e,
                                                  "editsuburb",
                                                  item
                                                )
                                              }
                                              onPlaceSelected={(place) =>
                                                editlocationfield(place, item)
                                              }
                                              options={{
                                                types: ["(regions)"],
                                                componentRestrictions: {
                                                  country: "au",
                                                },
                                              }}
                                              style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                position: "relative",
                                              }}
                                            />
                                          </Col>

                                          <Col
                                            lg={12}
                                            className="d-flex flex-column mt-2"
                                          >
                                            <div className="mb-3">
                                              <Label className="form-label">
                                                Radius
                                              </Label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                value={item.radius_distance}
                                                onChange={handleeditradiuschange(
                                                  item
                                                )}
                                              />
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row
                                          style={{
                                            alignItems: "end",
                                            marginLeft: 8,
                                          }}
                                        >
                                          <Col>
                                            <div className="hstack gap-2 justify-content-end">
                                              <button
                                                type="button"
                                                className="btn btn-soft-danger"
                                                onClick={() =>
                                                  t_location(item.id, true)
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-soft-success"
                                                onClick={() =>
                                                  savelocation(item)
                                                }
                                                disabled={isLoading}
                                              >
                                                {isLoading ? (
                                                  <Spinner />
                                                ) : (
                                                  "Update"
                                                )}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <hr />
                            </>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={showlocation} id="collapseeducation">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center justify-content-between">
                        <h4>Location</h4>
                      </CardHeader>
                      <CardBody>
                        <Form className="mb-2 pb-2">
                          <div className="justify-content-between align-items-center mb-2">
                            <div className="education-details text-muted">
                              <Row className=""></Row>
                              <Row>
                                <Col
                                  lg={12}
                                  className="d-flex flex-column mt-2"
                                >
                                  <Label>Suburb</Label>
                                  <GoogleAutocomplete
                                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                    value={selectedSuburb}
                                    id="selectlocation"
                                    onKeyUp={(e) => handleKeyUp(e, "suburb")}
                                    onFocus={() => setAddPreffFocused(true)}
                                    onPlaceSelected={selectSuburbOption}
                                    onChange={(e) => {
                                      setselectedSuburb(e.target.value);
                                    }}
                                    options={{
                                      types: ["(regions)"],
                                      componentRestrictions: {
                                        country: "au",
                                      },
                                    }}
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      width: "100%",
                                      //   position: "relative",
                                      zIndex: 9999,
                                    }}
                                  />
                                </Col>

                                <Col
                                  lg={12}
                                  className="d-flex flex-column mt-2"
                                >
                                  <div className="mb-3">
                                    <Label className="form-label">Radius</Label>
                                    <input
                                      type="number" // Change type to 'text'
                                      className="form-control"
                                      value={radius}
                                      onChange={handleRadiusChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                className="d-flex justify-space-between"
                                style={{ alignItems: "end", marginTop: 8 }}
                              >
                                <Col>
                                  <div className="hstack gap-2 justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-soft-danger"
                                      onClick={t_addlocation}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-soft-success"
                                      onClick={submitlocation}
                                      disabled={isLoading} // Disable the button when the loader is active
                                    >
                                      {isLoading ? <Spinner /> : "Add"}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Card className="mb-2">
                    <CardHeader className="d-flex align-items-center justify-content-between pt-2 pb-2">
                      <div className="hstack">
                        <i className="text-d-primary fs-18 me-1 bx bxs-file"></i>
                        <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                          Verifications
                        </h4>
                      </div>
                      <div className="hstack gap-3">
                        <i
                          className=" ri-add-line text-primary"
                          style={{ cursor: "pointer", fontSize: 25 }}
                          onClick={() => {
                            t_Sec3("plus");
                          }}
                        />
                        <i
                          className="ri-edit-2-line text-success fs-20 cursor-pointer"
                          onClick={handleVerificationEditClick}
                        ></i>
                      </div>
                    </CardHeader>
                    <Collapse isOpen={!showSection3} id="collapseeducation">
                      <CardBody>
                        <div className="d-flex flex-column mb-4">
                          {documents?.map((document, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center justify-content-between mb-2 "
                            >
                              <span>
                                {document.verified == 1 && (
                                  <>
                                    <FeatherIcon
                                      icon="check-circle"
                                      size={15}
                                      style={{ color: "green" }}
                                      id="iconVerified"
                                      onClick={toggleVerifiedTooltip}
                                    />
                                    <Tooltip
                                      placement="top"
                                      isOpen={verifiedTooltipOpen}
                                      target="iconVerified"
                                      toggle={toggleVerifiedTooltip}
                                    >
                                      Verified
                                    </Tooltip>
                                  </>
                                )}
                                {document.verified == 0 && (
                                  <>
                                    <FeatherIcon
                                      icon="clock"
                                      size={15}
                                      style={{ color: "orange" }}
                                      id="iconPending"
                                      onClick={togglePendingTooltip}
                                    />
                                    <Tooltip
                                      placement="top"
                                      isOpen={pendingTooltipOpen}
                                      target="iconPending"
                                      toggle={togglePendingTooltip}
                                    >
                                      Pending
                                    </Tooltip>
                                  </>
                                )}
                                {document.verified == null && (
                                  <>
                                    <FeatherIcon
                                      icon="alert-triangle"
                                      size={15}
                                      style={{ color: "red" }}
                                      id="iconUnverified"
                                      onClick={toggleUnverifiedTooltip}
                                    />
                                    <Tooltip
                                      placement="top"
                                      isOpen={unverifiedTooltipOpen}
                                      target="iconUnverified"
                                      toggle={toggleUnverifiedTooltip}
                                    >
                                      Unverified
                                    </Tooltip>
                                  </>
                                )}
                                &nbsp;{" "}
                                <span className="text-start">
                                  {document.document_type_name}
                                </span>
                              </span>
                              {document.verified == null
                                ? null
                                : isVerificationEditClicked && (
                                    <>
                                      <i
                                        className="ri-edit-2-line text-success fs-18 cursor-pointer"
                                        onClick={() => {
                                          t_Sec3();
                                          setEditVerification(true);
                                          handleVerificationEdit(
                                            document.verification_row_id
                                          );
                                        }}
                                      ></i>
                                    </>
                                  )}
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Collapse>

                    <Collapse isOpen={showSection3} id="collapseverifications">
                      <CardBody>
                        <Form>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="choices-multiple-remove-button"
                                  className="form-label"
                                >
                                  Document Type
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <select
                                  className={`form-select mb-2 ${
                                    isEditVerification ? "text-muted" : ""
                                  }`}
                                  value={selectedDocType}
                                  disabled={isEditVerification}
                                  onChange={(e) => {
                                    setSelectedDocType(e.target.value);
                                  }}
                                >
                                  <option value={0} disabled={true}>
                                    Select a document{" "}
                                  </option>
                                  {docType?.map((docType) => (
                                    <option
                                      key={docType.id}
                                      value={docType.id}
                                      disabled={
                                        !missingDocIds?.includes(docType.id)
                                      }
                                    >
                                      {docType.document_type_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="firstnameInput"
                                  className="form-label"
                                >
                                  Document Number
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={docNumber}
                                  onChange={(e) => {
                                    setDocNumber(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="firstnameInput"
                                  className="form-label"
                                >
                                  Issuing Authority
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={issuingAuthority}
                                  onChange={(e) => {
                                    setIssuingAuthority(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label className="form-label ">
                                  Date Issued
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <Flatpickr
                                  className="form-control"
                                  value={issuedDate} // Pass the date string directly
                                  options={{
                                    dateFormat: "Y-m-d", // Specify the format of the input date string
                                    altInput: true,
                                    altFormat: "d-m-Y", // Set the desired format for display
                                  }}
                                  onChange={(selectedDate) => {
                                    setIssuedDate(
                                      moment(selectedDate[0]).format(
                                        "YYYY-MM-DD"
                                      )
                                    ); // Update state on date change
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label className="form-label ">
                                  Expiration Date
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <Flatpickr
                                  className="form-control"
                                  value={expirationDate} // Pass the date string directly
                                  options={{
                                    dateFormat: "Y-m-d", // Specify the format of the input date string
                                    altInput: true,
                                    altFormat: "d-m-Y", // Set the desired format for display
                                  }}
                                  onChange={(selectedDates) => {
                                    setExpirationDate(
                                      moment(selectedDates[0]).format(
                                        "YYYY-MM-DD"
                                      )
                                    ); // Update state on date change
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="choices-single-default"
                                  className="form-label"
                                >
                                  Upload Document
                                </Label>{" "}
                                <Row className="mb-0">
                                  <Col md={4}>
                                    <div className="img-container">
                                      {isImageSelected ? (
                                        <>
                                          {isDocUrl ? (
                                            <>
                                              {fileExtensionFormat === "pdf" ? (
                                                <div
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <i className=" bx bxs-file-pdf text-primary fs-20"></i>{" "}
                                                  <a
                                                    href={imagesel}
                                                    target="_blank"
                                                  >
                                                    {uploadedVerificationImage}
                                                  </a>
                                                </div>
                                              ) : (
                                                <>
                                                  {uploadedVerificationImage ? (
                                                    <a
                                                      href={imagesel}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      <img
                                                        className="img-thumbnail"
                                                        alt=""
                                                        src={imagesel}
                                                      />
                                                    </a>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {uploadedVerificationImage?.type?.startsWith(
                                                "application/"
                                              ) ? (
                                                <div
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <i className=" bx bxs-file-pdf text-primary fs-20"></i>{" "}
                                                  {
                                                    uploadedVerificationImage.name
                                                  }
                                                </div>
                                              ) : (
                                                <>
                                                  {selectedImage ? (
                                                    <img
                                                      className="img-thumbnail"
                                                      alt=""
                                                      src={selectedImage}
                                                    />
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                {isImageSelected ? (
                                  <div className="mt-2">
                                    <button
                                      className="btn btn-primary change-button"
                                      onClick={handleButtonClick}
                                    >
                                      Upload
                                    </button>
                                    {!isEditVerification && (
                                      <button
                                        className="btn btn-danger remove-button"
                                        onClick={handleRemoveClick}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    className="btn btn-info mt-2"
                                    onClick={handleButtonClick}
                                  >
                                    Select
                                  </button>
                                )}
                                <input
                                  className={`${logoError ? "is-invalid" : ""}`}
                                  type="file"
                                  style={{ display: "none" }}
                                  ref={verificationFileInputRef}
                                  accept=".jpeg, .jpg, .png, .pdf"
                                  onChange={(event) => {
                                    handleVerificationFileInputChange(event);
                                  }}
                                />
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    t_Sec3();
                                    resetVerification();
                                  }}
                                >
                                  Cancel
                                </button>
                                {!isEditVerification ? (
                                  <button
                                    type="button"
                                    className="btn btn-soft-success"
                                    onClick={save_Sec3}
                                  >
                                    Save
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-soft-success"
                                    onClick={handleVerificationUpdate}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Collapse>
                  </Card>
                </Col>

                {/* Right side - One column taking full width */}
                <Col xl={8}>
                  <Card className="mb-2">
                    <CardHeader className="d-flex pb-2 pt-2 align-items-center justify-content-between">
                      <div className="hstack">
                        <i className="text-d-primary me-1 fs-18 ri-profile-line"></i>
                        <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                          Profile Summary
                        </h5>
                      </div>
                      <i
                        className="ri-edit-2-line text-primary fs-25"
                        style={{ cursor: "pointer" }}
                        onClick={t_Profession}
                      />
                    </CardHeader>

                    <CardBody>
                      <Collapse
                        isOpen={!showProfession}
                        id="collapse_profession"
                      >
                        <p
                          style={{ lineHeight: "1.5rem" }}
                          dangerouslySetInnerHTML={{
                            __html: profileSummary || " ",
                          }}
                        ></p>
                      </Collapse>
                      <Collapse
                        isOpen={showProfession}
                        id="collapse_profession"
                      >
                        <Form>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <div className="hstack gap-2">
                                    {!isGenerating ? (
                                      <span
                                        className={`${
                                          profileTitle ? "example-2 " : ""
                                        }`}
                                        style={{
                                          cursor: profileTitle
                                            ? "pointer"
                                            : "default",
                                        }}
                                        onClick={(e) => {
                                          if (profileTitle) {
                                            setIsGenerating(true);
                                            handleSummarySuggestion(e);
                                          }
                                        }}
                                      >
                                        <span
                                          className={`badge text-primary inner hstack gap-2 p-2 align-items-center animated-border `}
                                          id="suggest-description"
                                          style={{
                                            backgroundColor: profileTitle
                                              ? "#ffe9f7"
                                              : "#ededed",
                                          }}
                                        >
                                          <i className="fs-16 ri-magic-line text-warning"></i>
                                          <span className="fs-12">
                                            Suggest a profile summary using AI*
                                          </span>
                                        </span>
                                      </span>
                                    ) : (
                                      <span
                                        className="badge text-primary inner hstack gap-2 p-2 align-items-center animated-border"
                                        style={{ backgroundColor: "#ffe9f7" }}
                                      >
                                        <span className="fs-12">
                                          Generating{" "}
                                        </span>{" "}
                                        <Spinner
                                          color="primary"
                                          style={{
                                            width: "0.7rem",
                                            height: "0.7rem",
                                          }}
                                          type="grow"
                                        ></Spinner>
                                        <Spinner
                                          color="primary"
                                          style={{
                                            width: "0.6rem",
                                            height: "0.6rem",
                                          }}
                                          type="grow"
                                        ></Spinner>
                                        <Spinner
                                          color="primary"
                                          style={{
                                            width: "0.4rem",
                                            height: "0.4rem",
                                          }}
                                          type="grow"
                                        ></Spinner>
                                      </span>
                                    )}
                                  </div>

                                  <div className="character-count">
                                    {characterCount}
                                  </div>
                                </div>
                                <div
                                  className="snow-editor mt-2"
                                  style={{ height: 300 }}
                                >
                                  {/* <div ref={quillRef} /> */}
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={profileSummary}
                                    onChange={handleSummaryChange}
                                  />
                                </div>

                                {profileSummaryError && (
                                  <div className="invalid-feedback">
                                    {profileSummaryError}
                                  </div>
                                )}
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-soft-danger"
                                  onClick={t_Profession}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-soft-success"
                                  // disabled={isLoading ? true : false}
                                  onClick={save_Profession}
                                >
                                  Update
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Collapse>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <Row className="">
                      <Col>
                        <CardHeader>
                          <Nav
                            pills
                            className="nav nav-pills animation-nav nav-justified gap-2"
                          >
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: availabilityTab === "1",
                                })}
                                onClick={() => {
                                  availabilityToggle("1");
                                }}
                              >
                                Preferred Availability
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: availabilityTab === "2",
                                })}
                                onClick={() => {
                                  availabilityToggle("2");
                                }}
                              >
                                Volunteering Availability
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </CardHeader>
                        <CardBody>
                          <TabContent
                            activeTab={availabilityTab}
                            className="d-flex justify-content-center"
                          >
                            <TabPane tabId="1" id="home2">
                              <div
                                className="d-flex mb-2"
                                style={{ gap: "10.5rem" }}
                              >
                                <div className="hstack">
                                  <i className="text-d-primary fs-18 me-1 ri-calendar-check-fill"></i>
                                  <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                                    Preferred Availability
                                  </h4>
                                </div>
                                <i
                                  className="ri-edit-2-line text-primary fs-25"
                                  style={{ cursor: "pointer" }}
                                  onClick={t_Availability}
                                />
                              </div>
                              <Collapse
                                isOpen={!showAvailability}
                                id="collapse_availability"
                              >
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isMondayAvailable} onChange={() => setIsMondayAvailable(!isMondayAvailable)} /> */}
                                      <Label className=" weight700">
                                        Monday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      mondayFromTime === "available_anytime" ||
                                      mondayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isMondayAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={mondayFromTime || "default"}
                                        onChange={(e) => {
                                          setMondayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            isMondayAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isMondayAvailable &&
                                    mondayFromTime !== undefined &&
                                    mondayFromTime !== "unavailable" &&
                                    mondayFromTime !== "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming " +
                                                (!isMondayAvailable
                                                  ? "text-muted"
                                                  : "")
                                              }
                                              value={mondayToTime || "default"}
                                              onChange={(e) => {
                                                setMondayToTime(e.target.value);
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  isMondayAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Tuesday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      tuesdayFromTime === "available_anytime" ||
                                      tuesdayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isTueAvailable ? "text-muted" : "")
                                        }
                                        value={tuesdayFromTime || "default"}
                                        onChange={(e) => {
                                          //   setTuesdayFromTime(e.target.value);
                                          //   if (e.target.value === "unavailable") {
                                          //     setIsTueAvailable(false);
                                          //   }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isTueAvailable &&
                                    tuesdayFromTime !== "unavailable" &&
                                    tuesdayFromTime !== "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={tuesdayToTime || "default"}
                                              onChange={(e) => {
                                                // setTuesdayToTime(e.target.value);
                                                // if (e.target.value === "unavailable") {
                                                //   setIsTueAvailable(false);
                                                // }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isWedAvailable} onChange={() => setIsWedAvailable(!isWedAvailable)} /> */}
                                      <Label className="">Wednesday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      wednesdayFromTime ===
                                        "available_anytime" ||
                                      wednesdayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isWedAvailable ? "text-muted" : "")
                                        }
                                        value={wednesdayFromTime || "default"}
                                        onChange={(e) => {
                                          setWednesdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsWedAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isWedAvailable &&
                                    wednesdayFromTime !== "unavailable" &&
                                    wednesdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                wednesdayToTime || "default"
                                              }
                                              onChange={(e) => {
                                                setWednesdayToTime(
                                                  e.target.value
                                                );
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  setIsWedAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isThuAvailable} onChange={() => setIsThuAvailable(!isThuAvailable)} /> */}
                                      <Label className="">Thursday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      thursdayFromTime ===
                                        "available_anytime" ||
                                      thursdayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isThuAvailable ? "text-muted" : "")
                                        }
                                        value={thursdayFromTime || "default"}
                                        onChange={(e) => {
                                          setThursdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsThuAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isThuAvailable &&
                                    thursdayFromTime !== "unavailable" &&
                                    thursdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                thursdayToTime || "default"
                                              }
                                              onChange={(e) => {
                                                setThursdayToTime(
                                                  e.target.value
                                                );
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  setIsThuAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isFriAvailable} onChange={() => setIsFriAvailable(!isFriAvailable)} /> */}
                                      <Label className="">Friday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      fridayFromTime === "available_anytime" ||
                                      fridayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isFriAvailable ? "text-muted" : "")
                                        }
                                        value={fridayFromTime || "default"}
                                        onChange={(e) => {
                                          setFridayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsFriAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isFriAvailable &&
                                    fridayFromTime !== "unavailable" &&
                                    fridayFromTime !== "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={fridayToTime || "default"}
                                              onChange={(e) => {
                                                setFridayToTime(e.target.value);
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  setIsFriAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isSatAvailable} onChange={() => setIsSatAvailable(!isSatAvailable)} /> */}
                                      <Label className="">Saturday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      saturdayFromTime ===
                                        "available_anytime" ||
                                      saturdayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isSatAvailable ? "text-muted" : "")
                                        }
                                        value={saturdayFromTime || "default"}
                                        onChange={(e) => {
                                          setSaturdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsSatAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>

                                  {isSatAvailable &&
                                    saturdayFromTime !== "unavailable" &&
                                    saturdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                saturdayToTime || "default"
                                              }
                                              onChange={(e) => {
                                                setSaturdayToTime(
                                                  e.target.value
                                                );
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  setIsSatAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check  m-2">
                                      {/* <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked checked={isSunAvailable} onChange={() => setIsSunAvailable(!isSunAvailable)} /> */}
                                      <Label className="">Sunday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    lg={3}
                                    style={
                                      sundayFromTime === "available_anytime" ||
                                      sundayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isSunAvailable ? "text-muted" : "")
                                        }
                                        value={sundayFromTime || "default"}
                                        onChange={(e) => {
                                          setSundayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsSunAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>

                                  {isSunAvailable &&
                                    sundayFromTime !== "unavailable" &&
                                    sundayFromTime !== "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4} lg={3}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={sundayToTime || "default"}
                                              onChange={(e) => {
                                                setSundayToTime(e.target.value);
                                                if (
                                                  e.target.value ===
                                                  "unavailable"
                                                ) {
                                                  setIsSunAvailable(false);
                                                }
                                              }}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                              </Collapse>

                              <Collapse
                                isOpen={showAvailability}
                                id="collapse_availability"
                              >
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="mondaycheck"
                                        // defaultChecked
                                        checked={isMondayAvailable}
                                        onChange={() => {
                                          if (!isMondayAvailable) {
                                            setMondayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setMondayFromTime("unavailable");
                                          }
                                          setIsMondayAvailable(
                                            !isMondayAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="mondaycheck"
                                      >
                                        Monday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={mondayFromTime || "default"}
                                        onChange={(e) => {
                                          setMondayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsMondayAvailable(false);
                                          }
                                        }}
                                        // disabled={!isMondayAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isMondayAvailable &&
                                  mondayFromTime !== "unavailable" &&
                                  mondayFromTime !== "available_anytime" ? (
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <select
                                          className="form-select"
                                          value={mondayToTime || "default"}
                                          onChange={(e) => {
                                            setMondayToTime(e.target.value);
                                            if (
                                              e.target.value === "unavailable"
                                            ) {
                                              setIsMondayAvailable(false);
                                            }
                                          }}
                                        >
                                          <option value="default" disabled>
                                            Select a time
                                          </option>
                                          {timeOptions
                                            .filter(
                                              (option) =>
                                                option.value > mondayFromTime &&
                                                option.value !==
                                                  "unavailable" &&
                                                option.value !==
                                                  "available_anytime"
                                            )
                                            .map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </Col>
                                  ) : (
                                    <Col md={4}></Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck2"
                                        // defaultChecked
                                        checked={isTueAvailable}
                                        onChange={() => {
                                          if (!isTueAvailable) {
                                            setTuesdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setTuesdayFromTime("unavailable");
                                          }
                                          setIsTueAvailable(!isTueAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck2"
                                      >
                                        Tuesday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={tuesdayFromTime || "default"}
                                        onChange={(e) => {
                                          setTuesdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsTueAvailable(false);
                                          }
                                        }}
                                        // disabled={!isTueAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isTueAvailable &&
                                    tuesdayFromTime !== "unavailable" &&
                                    tuesdayFromTime !== "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={tuesdayToTime || "default"}
                                            onChange={(e) => {
                                              setTuesdayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsTueAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    tuesdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck3"
                                        // defaultChecked
                                        checked={isWedAvailable}
                                        onChange={() => {
                                          if (!isWedAvailable) {
                                            setWednesdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setWednesdayFromTime("unavailable");
                                          }
                                          setIsWedAvailable(!isWedAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck3"
                                      >
                                        Wednesday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={wednesdayFromTime || "default"}
                                        onChange={(e) => {
                                          setWednesdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsWedAvailable(false);
                                          }
                                        }}
                                        // disabled={!isWedAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isWedAvailable &&
                                    wednesdayFromTime !== "unavailable" &&
                                    wednesdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={wednesdayToTime || "default"}
                                            onChange={(e) => {
                                              setWednesdayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsWedAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    wednesdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck4"
                                        // defaultChecked
                                        checked={isThuAvailable}
                                        onChange={() => {
                                          if (!isThuAvailable) {
                                            setThursdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setThursdayFromTime("unavailable");
                                          }
                                          setIsThuAvailable(!isThuAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck4"
                                      >
                                        Thursday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={thursdayFromTime || "default"}
                                        onChange={(e) => {
                                          setThursdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsThuAvailable(false);
                                          }
                                        }}
                                        // disabled={!isThuAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isThuAvailable &&
                                    thursdayFromTime !== "unavailable" &&
                                    thursdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={thursdayToTime || "default"}
                                            onChange={(e) => {
                                              setThursdayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsThuAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    thursdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        // id="formCheck5"
                                        defaultChecked
                                        checked={isFriAvailable}
                                        onChange={() => {
                                          if (!isFriAvailable) {
                                            setFridayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setFridayFromTime("unavailable");
                                          }
                                          setIsFriAvailable(!isFriAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck5"
                                      >
                                        Friday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={fridayFromTime || "default"}
                                        onChange={(e) => {
                                          setFridayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsFriAvailable(false);
                                          }
                                        }}
                                        // disabled={!isFriAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isFriAvailable &&
                                    fridayFromTime !== "unavailable" &&
                                    fridayFromTime !== "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={fridayToTime || "default"}
                                            onChange={(e) => {
                                              setFridayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsFriAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    fridayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck6"
                                        // defaultChecked
                                        checked={isSatAvailable}
                                        onChange={() => {
                                          if (!isSatAvailable) {
                                            setSaturdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setSaturdayFromTime("unavailable");
                                          }
                                          setIsSatAvailable(!isSatAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck6"
                                      >
                                        Saturday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={saturdayFromTime || "default"}
                                        onChange={(e) => {
                                          setSaturdayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsSatAvailable(false);
                                          }
                                        }}
                                        // disabled={!isSatAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isSatAvailable &&
                                    saturdayFromTime !== "unavailable" &&
                                    saturdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={saturdayToTime || "default"}
                                            onChange={(e) => {
                                              setSaturdayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsSatAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    saturdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck7"
                                        // defaultChecked
                                        checked={isSunAvailable}
                                        onChange={() => {
                                          if (!isSunAvailable) {
                                            setSundayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setSundayFromTime("unavailable");
                                          }
                                          setIsSunAvailable(!isSunAvailable);
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck7"
                                      >
                                        Sunday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={sundayFromTime || "default"}
                                        onChange={(e) => {
                                          setSundayFromTime(e.target.value);
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsSunAvailable(false);
                                          }
                                        }}
                                        // disabled={!isSunAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isSunAvailable &&
                                    sundayFromTime !== "unavailable" &&
                                    sundayFromTime !== "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={sundayToTime || "default"}
                                            onChange={(e) => {
                                              setSundayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsSunAvailable(false);
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    sundayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                {showToast && (
                                  <div
                                    className="toast"
                                    role="alert"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                  >
                                    <div className="toast-body">
                                      Please select both "From Time" and "To
                                      Time" for all selected days.
                                    </div>
                                  </div>
                                )}
                                <Row>
                                  <Col lg={12}>
                                    <div className="hstack gap-4 mt-2 justify-content-start">
                                      <button
                                        type="button"
                                        className="btn btn-soft-danger"
                                        onClick={t_Availability}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-soft-success"
                                        onClick={save_Availability}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                            </TabPane>

                            <TabPane tabId="2" id="profile2">
                              <div
                                className="d-flex mb-2"
                                style={{ gap: "10rem" }}
                              >
                                <div className="hstack">
                                  <i className="text-d-primary fs-18 me-1 ri-calendar-check-fill"></i>
                                  <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                                    Volunteering Availability
                                  </h4>
                                </div>
                                <i
                                  className="ri-edit-2-line text-primary fs-25"
                                  style={{ cursor: "pointer" }}
                                  onClick={t_VolunteerAvailability}
                                />
                              </div>
                              <Collapse
                                isOpen={!showVolunteerAvailability}
                                id="collapse_volunteer_availability"
                              >
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className=" weight700">
                                        Monday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerMondayFromTime ===
                                        "available_anytime" ||
                                      volunteerMondayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerMondayAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerMondayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerMondayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            isVolunteerMondayAvailable(false);
                                          }
                                        }}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerMondayAvailable &&
                                    volunteerMondayFromTime !== undefined &&
                                    volunteerMondayFromTime !== "unavailable" &&
                                    volunteerMondayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming " +
                                                (!isVolunteerMondayAvailable
                                                  ? "text-muted"
                                                  : "")
                                              }
                                              value={
                                                volunteerMondayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Tuesday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerTuesdayFromTime ===
                                        "available_anytime" ||
                                      volunteerTuesdayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerTueAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerTuesdayFromTime || "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isTueAvailable &&
                                    volunteerTuesdayFromTime !==
                                      "unavailable" &&
                                    volunteerTuesdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerTuesdayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Wednesday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerWednesdayFromTime ===
                                        "available_anytime" ||
                                      volunteerWednesdayFromTime ===
                                        "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerWedAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerWednesdayFromTime ||
                                          "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isWedAvailable &&
                                    volunteerWednesdayFromTime !==
                                      "unavailable" &&
                                    volunteerWednesdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerWednesdayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Thursday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerThursdayFromTime ===
                                        "available_anytime" ||
                                      volunteerThursdayFromTime ===
                                        "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerThuAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerThursdayFromTime || "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isThuAvailable &&
                                    volunteerThursdayFromTime !==
                                      "unavailable" &&
                                    volunteerThursdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerThursdayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Friday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerFridayFromTime ===
                                        "available_anytime" ||
                                      volunteerFridayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerFriAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerFridayFromTime || "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isFriAvailable &&
                                    volunteerFridayFromTime !== "unavailable" &&
                                    volunteerFridayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerFridayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Saturday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerSaturdayFromTime ===
                                        "available_anytime" ||
                                      volunteerSaturdayFromTime ===
                                        "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerSatAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerSaturdayFromTime || "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>

                                  {isSatAvailable &&
                                    volunteerSaturdayFromTime !==
                                      "unavailable" &&
                                    volunteerSaturdayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerSaturdayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check  m-2">
                                      <Label className="">Sunday</Label>
                                    </div>
                                  </Col>
                                  <Col
                                    md={4}
                                    style={
                                      volunteerSundayFromTime ===
                                        "available_anytime" ||
                                      volunteerSundayFromTime === "unavailable"
                                        ? { width: "45%" }
                                        : null
                                    }
                                  >
                                    <div>
                                      <select
                                        className={
                                          "form-control showtiming " +
                                          (!isVolunteerSunAvailable
                                            ? "text-muted"
                                            : "")
                                        }
                                        value={
                                          volunteerSundayFromTime || "default"
                                        }
                                        onChange={(e) => {}}
                                        disabled
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>

                                  {isSunAvailable &&
                                    volunteerSundayFromTime !== "unavailable" &&
                                    volunteerSundayFromTime !==
                                      "available_anytime" && (
                                      <>
                                        <Col
                                          md={1}
                                          style={{
                                            width: "fit-content",
                                            padding: "0",
                                          }}
                                        >
                                          <div className=" pt-2 text-center">
                                            <p>To</p>
                                          </div>
                                        </Col>
                                        <Col md={4}>
                                          <div>
                                            <select
                                              className={
                                                "form-control showtiming "
                                              }
                                              value={
                                                volunteerSundayToTime ||
                                                "default"
                                              }
                                              onChange={(e) => {}}
                                              disabled
                                            >
                                              <option value="default" disabled>
                                                Select a time
                                              </option>
                                              {timeOptions.map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                      </>
                                    )}
                                </Row>
                              </Collapse>

                              <Collapse
                                isOpen={showVolunteerAvailability}
                                id="collapse_volunteer_availability"
                              >
                                <Row>
                                  <Col md={3} lg={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="mondaycheck"
                                        // defaultChecked
                                        checked={isVolunteerMondayAvailable}
                                        onChange={() => {
                                          if (!isVolunteerMondayAvailable) {
                                            setVolunteerMondayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerMondayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerMondayAvailable(
                                            !isVolunteerMondayAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="mondaycheck"
                                      >
                                        Monday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4} lg={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerMondayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerMondayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsMondayAvailable(false);
                                          }
                                        }}
                                        // disabled={!isMondayAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerMondayAvailable &&
                                    volunteerMondayFromTime !== "unavailable" &&
                                    volunteerMondayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4} lg={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerMondayToTime || "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerMondayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerMondayAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerMondayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck2"
                                        // defaultChecked
                                        checked={isVolunteerTueAvailable}
                                        onChange={() => {
                                          if (!isVolunteerTueAvailable) {
                                            setVolunteerTuesdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerTuesdayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerTueAvailable(
                                            !isVolunteerTueAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck2"
                                      >
                                        Tuesday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerTuesdayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerTuesdayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerTueAvailable(false);
                                          }
                                        }}
                                        // disabled={!isVolunteerTueAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerTueAvailable &&
                                    volunteerTuesdayFromTime !==
                                      "unavailable" &&
                                    volunteerTuesdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerTuesdayToTime ||
                                              "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerTuesdayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerTueAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerTuesdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck3"
                                        // defaultChecked
                                        checked={isVolunteerWedAvailable}
                                        onChange={() => {
                                          if (!isVolunteerWedAvailable) {
                                            setVolunteerWednesdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerWednesdayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerWedAvailable(
                                            !isVolunteerWedAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck3"
                                      >
                                        Wednesday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerWednesdayFromTime ||
                                          "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerWednesdayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerWedAvailable(false);
                                          }
                                        }}
                                        // disabled={!isVolunteerWedAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerWedAvailable &&
                                    volunteerWednesdayFromTime !==
                                      "unavailable" &&
                                    volunteerWednesdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerWednesdayToTime ||
                                              "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerWednesdayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerWedAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerWednesdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck4"
                                        // defaultChecked
                                        checked={isVolunteerThuAvailable}
                                        onChange={() => {
                                          if (!isVolunteerThuAvailable) {
                                            setVolunteerThursdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerThursdayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerThuAvailable(
                                            !isVolunteerThuAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck4"
                                      >
                                        Thursday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerThursdayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerThursdayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerThuAvailable(false);
                                          }
                                        }}
                                        // disabled={!isVolunteerThuAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerThuAvailable &&
                                    volunteerThursdayFromTime !==
                                      "unavailable" &&
                                    volunteerThursdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerThursdayToTime ||
                                              "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerThursdayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerThuAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerThursdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        // id="formCheck5"
                                        defaultChecked
                                        checked={isVolunteerFriAvailable}
                                        onChange={() => {
                                          if (!isVolunteerFriAvailable) {
                                            setVolunteerFridayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerFridayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerFriAvailable(
                                            !isVolunteerFriAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck5"
                                      >
                                        Friday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerFridayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerFridayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerFriAvailable(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerFriAvailable &&
                                    volunteerFridayFromTime !== "unavailable" &&
                                    volunteerFridayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerFridayToTime || "default"
                                            }
                                            onChange={(e) => {
                                              setFridayToTime(e.target.value);
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerFriAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerFridayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck6"
                                        // defaultChecked
                                        checked={isVolunteerSatAvailable}
                                        onChange={() => {
                                          if (!isVolunteerSatAvailable) {
                                            setVolunteerSaturdayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerSaturdayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerSatAvailable(
                                            !isVolunteerSatAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck6"
                                      >
                                        Saturday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerSaturdayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerSaturdayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerSatAvailable(false);
                                          }
                                        }}
                                        // disabled={!isVolunteerSatAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerSatAvailable &&
                                    volunteerSaturdayFromTime !==
                                      "unavailable" &&
                                    volunteerSaturdayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerSaturdayToTime ||
                                              "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerSaturdayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerSatAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerSaturdayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="form-check mb-3 m-2">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck7"
                                        // defaultChecked
                                        checked={isVolunteerSunAvailable}
                                        onChange={() => {
                                          if (!isVolunteerSunAvailable) {
                                            setVolunteerSundayFromTime(
                                              "available_anytime"
                                            );
                                          } else {
                                            setVolunteerSundayFromTime(
                                              "unavailable"
                                            );
                                          }
                                          setIsVolunteerSunAvailable(
                                            !isVolunteerSunAvailable
                                          );
                                        }}
                                      />
                                      <Label
                                        className="form-check-label"
                                        for="formCheck7"
                                      >
                                        Sunday
                                      </Label>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <select
                                        className="form-select"
                                        value={
                                          volunteerSundayFromTime || "default"
                                        }
                                        onChange={(e) => {
                                          setVolunteerSundayFromTime(
                                            e.target.value
                                          );
                                          if (
                                            e.target.value === "unavailable"
                                          ) {
                                            setIsVolunteerSunAvailable(false);
                                          }
                                        }}
                                        // disabled={!isVolunteerSunAvailable}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  {isVolunteerSunAvailable &&
                                    volunteerSundayFromTime !== "unavailable" &&
                                    volunteerSundayFromTime !==
                                      "available_anytime" && (
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <select
                                            className="form-select"
                                            value={
                                              volunteerSundayToTime || "default"
                                            }
                                            onChange={(e) => {
                                              setVolunteerSundayToTime(
                                                e.target.value
                                              );
                                              if (
                                                e.target.value === "unavailable"
                                              ) {
                                                setIsVolunteerSunAvailable(
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <option value="default" disabled>
                                              Select a time
                                            </option>
                                            {timeOptions
                                              .filter(
                                                (option) =>
                                                  option.value >
                                                    volunteerSundayFromTime &&
                                                  option.value !==
                                                    "unavailable" &&
                                                  option.value !==
                                                    "available_anytime"
                                              )
                                              .map((option) => (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                {showToast && (
                                  <div
                                    className="toast"
                                    role="alert"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                  >
                                    <div className="toast-body">
                                      Please select both "From Time" and "To
                                      Time" for all selected days.
                                    </div>
                                  </div>
                                )}
                                <Row>
                                  <Col lg={12}>
                                    <div className="hstack gap-4 mt-2 justify-content-start">
                                      <button
                                        type="button"
                                        className="btn btn-soft-danger"
                                        onClick={t_VolunteerAvailability}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-soft-success"
                                        onClick={save_Volunteering_Availability}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>

                  <Collapse isOpen={!showhistory} id="collapseemployee">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center pb-2 pt-2 justify-content-between">
                        <div className="hstack">
                          <i className="text-d-primary fs-18 me-1 ri-briefcase-4-fill"></i>
                          <h4 className="text-d-primary card-title flex-grow-1 mb-0">
                            Employment History
                          </h4>
                        </div>
                        <div className="hstack gap-3">
                          <i
                            className=" ri-add-line text-primary fs-25"
                            style={{ cursor: "pointer" }}
                            onClick={t_addhistory}
                          />
                          <i
                            className="ri-edit-2-line text-success me-1"
                            style={{
                              cursor: "pointer",
                              fontSize: 20,
                            }}
                            onClick={handleEmployeeEditClick}
                          />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="profile-timeline">
                          <div></div>
                          <div
                            className="accordion accordion-flush"
                            id="todayExample"
                          >
                            {userhistory?.map((item, index) => {
                              return (
                                <>
                                  {!item.isEditing && (
                                    <div
                                      key={index}
                                      className="accordion-item border-0"
                                    >
                                      <div className="accordion-header">
                                        <div
                                          className="accordion-item p-2 shadow-none"
                                          id="headingOne"
                                        >
                                          <div className="d-flex">
                                            <div className="flex-shrink-0">
                                              <img
                                                src={workHistory}
                                                alt=""
                                                className="avatar-xs rounded-circle"
                                              />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                              <div className="hstack gap-4 justify-content-between">
                                                <h5 className="mb-1">
                                                  {item.role},{" "}
                                                  {item.company_name}
                                                </h5>
                                                <div className="hstack gap-2">
                                                  {isEmployeeEditClicked && (
                                                    <>
                                                      <i
                                                        className="ri-edit-2-line text-success me-1"
                                                        style={{
                                                          cursor: "pointer",
                                                          fontSize: 18,
                                                        }}
                                                        onClick={() =>
                                                          t_history(item.id)
                                                        }
                                                      />
                                                      <i
                                                        className="bx bx-trash text-danger"
                                                        style={{
                                                          cursor: "pointer",
                                                          fontSize: 18,
                                                        }}
                                                        onClick={() =>
                                                          deletehistory(item)
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                              <p
                                                style={{ fontWeight: "normal" }}
                                                className="text-muted"
                                              >
                                                {item.location}
                                              </p>
                                              <p>
                                                {formatDate(item.start_date)} -{" "}
                                                {item.is_current == 1 ? (
                                                  "Present"
                                                ) : (
                                                  <>
                                                    {formatDate(item.end_date)}{" "}
                                                  </>
                                                )}
                                              </p>
                                              <p
                                                className="text-dark description-container"
                                                style={{ lineHeight: "1.5rem" }}
                                                dangerouslySetInnerHTML={{
                                                  __html: DOMPurify.sanitize(
                                                    item.description || ""
                                                  ),
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {item.isEditing && (
                                    <div className="mb-2 pb-2" key={index}>
                                      <div className="" key={index}>
                                        <h4 className="mb-2">
                                          Employment {index + 1}
                                        </h4>
                                        <div className="justify-content-between align-items-center mb-2">
                                          <div className="education-details text-muted">
                                            <Row>
                                              <Col xs={4}>
                                                <Label
                                                  htmlFor="Role"
                                                  className="form-label"
                                                >
                                                  Role
                                                </Label>
                                              </Col>
                                              <Col xs={4}>
                                                <Label
                                                  htmlFor="Role"
                                                  className="form-label"
                                                >
                                                  Company Name
                                                </Label>
                                              </Col>{" "}
                                              <Col xs={4}>
                                                <Label
                                                  htmlFor="Role"
                                                  className="form-label"
                                                >
                                                  Location
                                                </Label>
                                              </Col>
                                            </Row>
                                            <Row className="">
                                              <Col xs={4} className="mb-3">
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  id="editInstitution"
                                                  placeholder="Enter your  Role"
                                                  value={item.role}
                                                  onChange={(e) => {
                                                    const updateddata = [
                                                      ...userhistory,
                                                    ];
                                                    const taskIndex =
                                                      updateddata.findIndex(
                                                        (task) =>
                                                          task.id === item.id
                                                      );
                                                    const enteredValue =
                                                      e.target.value;
                                                    updateddata[
                                                      taskIndex
                                                    ].role = enteredValue;
                                                    setUserHistory(updateddata);
                                                  }}
                                                />
                                              </Col>
                                              <Col xs={4} className="mb-3">
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  id="editDegree"
                                                  placeholder="Enter your Company Name"
                                                  value={item.company_name}
                                                  onChange={(e) => {
                                                    const updateddata = [
                                                      ...userhistory,
                                                    ];
                                                    const taskIndex =
                                                      updateddata.findIndex(
                                                        (task) =>
                                                          task.id === item.id
                                                      );
                                                    const enteredValue =
                                                      e.target.value;
                                                    updateddata[
                                                      taskIndex
                                                    ].company_name =
                                                      enteredValue;
                                                    setUserHistory(updateddata);
                                                  }}
                                                />
                                              </Col>
                                              <Col xs={4}>
                                                <div className="mb-3">
                                                  <GoogleAutocomplete
                                                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                                    value={item.location}
                                                    id="employeelocation"
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      setEditemployeeValue(
                                                        value
                                                      );

                                                      const updatedData =
                                                        userhistory.map(
                                                          (task) =>
                                                            task.id === item.id
                                                              ? {
                                                                  ...task,
                                                                  location:
                                                                    e.target
                                                                      .value,
                                                                }
                                                              : task
                                                        );
                                                      setUserHistory(
                                                        updatedData
                                                      );
                                                    }}
                                                    onPlaceSelected={(place) =>
                                                      edithistorylocation(
                                                        place,
                                                        item
                                                      )
                                                    }
                                                    options={{
                                                      types: ["geocode"],
                                                    }}
                                                    style={{
                                                      border: "1px solid #ccc",
                                                      padding: "10px",
                                                      borderRadius: "5px",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col xs={6} className="mb-3">
                                                <Label
                                                  htmlFor="editStartDate"
                                                  className="form-label"
                                                >
                                                  Start Date
                                                </Label>
                                                <Flatpickr
                                                  className="form-control"
                                                  value={item.start_date} // Pass the date string directly
                                                  options={{
                                                    dateFormat: "Y-m-d", // Specify the format of the input date string
                                                    // altInput: true,
                                                    altFormat: "d-m-Y", // Set the desired format for display
                                                  }}
                                                  onChange={(selectedDates) => {
                                                    const updateddata = [
                                                      ...userhistory,
                                                    ];
                                                    const taskIndex =
                                                      updateddata.findIndex(
                                                        (task) =>
                                                          task.id === item.id
                                                      );
                                                    const enteredValue = moment(
                                                      selectedDates[0]
                                                    ).format("YYYY-MM-DD");
                                                    updateddata[
                                                      taskIndex
                                                    ].start_date = enteredValue;
                                                    setUserHistory(updateddata);
                                                  }}
                                                />
                                              </Col>
                                              <Col xs={6} className="mb-3">
                                                <Label
                                                  htmlFor="editEndDate"
                                                  className="form-label"
                                                >
                                                  End Date
                                                </Label>
                                                <Flatpickr
                                                  className={`form-control ${item.is_current}`}
                                                  value={item.end_date} // Pass the date string directly
                                                  options={{
                                                    dateFormat: "Y-m-d",
                                                    altFormat: "d-m-Y",
                                                    maxDate: new Date(),
                                                  }}
                                                  onChange={(selectedDates) => {
                                                    const updatedData = [
                                                      ...userhistory,
                                                    ];
                                                    const taskIndex =
                                                      updatedData.findIndex(
                                                        (task) =>
                                                          task.id === item.id
                                                      );
                                                    const enteredValue = moment(
                                                      selectedDates[0]
                                                    ).format("YYYY-MM-DD");
                                                    updatedData[
                                                      taskIndex
                                                    ].end_date = enteredValue;
                                                    setUserHistory(updatedData);
                                                  }}
                                                  disabled={
                                                    item.is_current === 1
                                                  }
                                                />
                                              </Col>
                                              <Col xs={2} className="ms-auto">
                                                <Label
                                                  htmlFor="editcheck"
                                                  className="form-label"
                                                >
                                                  Is Current
                                                </Label>
                                                <input
                                                  type="checkbox"
                                                  id="editcheck"
                                                  className="ms-2"
                                                  checked={item.is_current}
                                                  onChange={(e) => {
                                                    const updatedData = [
                                                      ...userhistory,
                                                    ];
                                                    const taskIndex =
                                                      updatedData.findIndex(
                                                        (task) =>
                                                          task.id === item.id
                                                      );
                                                    const isChecked = e.target
                                                      .checked
                                                      ? 1
                                                      : 0;
                                                    updatedData[
                                                      taskIndex
                                                    ].is_current = isChecked;
                                                    setUserHistory(updatedData);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            <Row
                                              className="d-flex justify-space-between"
                                              style={{ alignItems: "end" }}
                                            >
                                              <Col xs={12} className="">
                                                <Label
                                                  htmlFor="editAchievement"
                                                  className="form-label"
                                                >
                                                  Description
                                                </Label>
                                                <div
                                                  className="snow-editor"
                                                  style={{ height: 300 }}
                                                >
                                                  <ReactQuill
                                                    theme="snow"
                                                    modules={modules}
                                                    className="description-container"
                                                    formats={formats}
                                                    placeholder="Enter Description"
                                                    value={item.description}
                                                    onChange={(value) => {
                                                      const updateddata = [
                                                        ...userhistory,
                                                      ];
                                                      const taskIndex =
                                                        updateddata.findIndex(
                                                          (task) =>
                                                            task.id === item.id
                                                        );
                                                      const enteredValue =
                                                        value;
                                                      updateddata[
                                                        taskIndex
                                                      ].description =
                                                        enteredValue;
                                                      setUserHistory(
                                                        updateddata
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </Col>

                                              <Col xs={12} className="">
                                                <div className="hstack gap-2 justify-content-end mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-soft-danger"
                                                    onClick={() =>
                                                      t_history(item.id, true)
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-soft-success"
                                                    onClick={() =>
                                                      edithistory(item)
                                                    }
                                                  >
                                                    Update
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={showhistory} id="collapseemployee">
                    <Card className="mb-2">
                      <CardHeader className="d-flex justify-content-between">
                        <h4 className="text-secondary">Employment</h4>
                      </CardHeader>
                      <CardBody>
                        <Form className="">
                          <div className="justify-content-between align-items-center mb-2">
                            <div className="education-details text-muted">
                              <Row>
                                <Col xs={4}>
                                  <Label htmlFor="Role" className="form-label">
                                    Role
                                  </Label>
                                </Col>
                                <Col xs={4}>
                                  <Label htmlFor="Role" className="form-label">
                                    Company Name
                                  </Label>
                                </Col>
                                <Col xs={4}>
                                  <Label htmlFor="Role" className="form-label">
                                    Location
                                  </Label>
                                </Col>
                              </Row>
                              <Row className="">
                                <Col xs={4} className="mb-3">
                                  <Input
                                    type="text"
                                    value={datahistory.role}
                                    className="form-control"
                                    id="Role"
                                    placeholder="Enter your Role"
                                    onChange={(e) =>
                                      sethistory({
                                        ...datahistory,
                                        role: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={4} className="mb-3">
                                  <Input
                                    type="text"
                                    value={datahistory.company_name}
                                    className="form-control"
                                    id="editcompany"
                                    placeholder="Enter your company name"
                                    onChange={(e) =>
                                      sethistory({
                                        ...datahistory,
                                        company_name: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={4} className="mb-3">
                                  <GoogleAutocomplete
                                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                    value={employeelocation}
                                    id="employeeadd"
                                    onChange={(e) =>
                                      setemployeelocation(e.target.value)
                                    }
                                    onPlaceSelected={addemployeelocation}
                                    options={{
                                      types: ["geocode"],
                                    }}
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      width: "100%",
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editempstart"
                                    className="form-label"
                                  >
                                    Start Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={datahistory.start_date}
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) =>
                                      sethistory({
                                        ...datahistory,
                                        start_date: selectedDates[0],
                                      })
                                    }
                                  />
                                </Col>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editempend"
                                    className="form-label"
                                  >
                                    End Date
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control " + datahistory.checkbox
                                    }
                                    value={datahistory.end_date}
                                    options={{
                                      dateFormat: "Y-m-d",
                                      altFormat: "d-m-Y",
                                      maxDate: new Date(),
                                    }}
                                    onChange={(selectedDates) =>
                                      sethistory({
                                        ...datahistory,
                                        end_date: selectedDates[0],
                                      })
                                    }
                                    disabled={datahistory.checkbox === 1} // Disable if checkbox is checked
                                  />
                                </Col>
                                <Col xs={2} className="ms-auto">
                                  <Label
                                    htmlFor="editcheck"
                                    className="form-label "
                                  >
                                    Is Current
                                  </Label>
                                  <input
                                    type="checkbox"
                                    className="ms-2"
                                    id="editcheck"
                                    checked={datahistory.checkbox === 1}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      sethistory({
                                        ...datahistory,
                                        checkbox: isChecked ? 1 : 0,
                                        end_date: isChecked
                                          ? ""
                                          : datahistory.end_date, // Reset end_date if checkbox is checked
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                className="d-flex justify-space-between"
                                style={{ alignItems: "end" }}
                              >
                                <Col xs={12} className="">
                                  <Label
                                    htmlFor="editdesc"
                                    className="form-label"
                                  >
                                    Description
                                  </Label>
                                  <div
                                    className="snow-editor"
                                    style={{ height: 300 }}
                                  >
                                    {/* <div ref={quillRef} /> */}
                                    <ReactQuill
                                      theme="snow"
                                      modules={modules}
                                      formats={formats}
                                      placeholder="Enter Description"
                                      value={datahistory.description}
                                      onChange={(value) => {
                                        sethistory({
                                          ...datahistory,
                                          description: value,
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>

                                <Col lg={12}>
                                  <div className="hstack gap-2 justify-content-end mt-2">
                                    <button
                                      type="button"
                                      className="btn btn-soft-danger"
                                      onClick={t_addhistory}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-soft-success"
                                      onClick={() => addhistory(datahistory)}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={!showEducation} id="collapseeducation">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center text-secondary pt-2 pb-2 justify-content-between">
                        <div className="hstack">
                          <i className="bx bxs-graduation fs-18 text-d-primary me-1"></i>
                          <h4 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                            Education
                          </h4>
                        </div>
                        <div className="hstack gap-3">
                          <i
                            className=" ri-add-line text-primary fs-25"
                            style={{ cursor: "pointer" }}
                            onClick={() => t_AddEducation()}
                          />
                          <i
                            className="ri-edit-2-line text-success me-1"
                            style={{
                              cursor: "pointer",
                              fontSize: 20,
                            }}
                            onClick={handleEducationEditClick}
                          />
                        </div>
                      </CardHeader>
                      <CardBody>
                        {userEducation?.map((item, index) => {
                          return (
                            <>
                              {!item.isEditing && (
                                <div
                                  key={index}
                                  className="education-container mb-3"
                                >
                                  <div className="d-flex justify-content-between">
                                    <h6>{item.institution_name}</h6>
                                    <div className="hstack gap-2">
                                      {isEducationEditClicked && (
                                        <>
                                          <i
                                            className="ri-edit-2-line text-success me-1"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() => t_Education(item.id)}
                                          />
                                          <i
                                            className="bx bx-trash text-danger"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() =>
                                              deleteEducation(item.id)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span> {item.degree}</span>
                                      {item.degree &&
                                        item.field_of_study &&
                                        ","}
                                      <span className="text-muted">
                                        {" "}
                                        {item.field_of_study}
                                      </span>
                                      <p>{item.achievements}</p>
                                    </div>
                                    <span className="text-gray">
                                      {new Date(item.start_date).getFullYear()}{" "}
                                      - {new Date(item.end_date).getFullYear()}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {item.isEditing && (
                                <div className="mb-2 pb-2" key={index}>
                                  <div className="" key={index}>
                                    <h4 className="mb-2">
                                      Education {index + 1}
                                    </h4>
                                    <div className="justify-content-between align-items-center mb-2">
                                      <div className="education-details text-muted">
                                        <Row className="">
                                          <Col xs={4} className="mb-3">
                                            <Label
                                              htmlFor="editInstitution"
                                              className="form-label"
                                            >
                                              Institution
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="editInstitution"
                                              placeholder="Enter your Institution name"
                                              value={item.institution_name}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[
                                                  taskIndex
                                                ].institution_name =
                                                  enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            />
                                          </Col>
                                          <Col xs={4} className="mb-3">
                                            <Label
                                              htmlFor="editDegree"
                                              className="form-label"
                                            >
                                              Degree
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="editDegree"
                                              placeholder="Enter your Degree"
                                              value={item.degree}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[taskIndex].degree =
                                                  enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            />
                                          </Col>
                                          <Col xs={4} className="mb-3">
                                            <Label
                                              htmlFor="editField"
                                              className="form-label"
                                            >
                                              Field of study
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="editField"
                                              placeholder="Enter your Field of study"
                                              value={item.field_of_study}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[
                                                  taskIndex
                                                ].field_of_study = enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editStartDate"
                                              className="form-label"
                                            >
                                              Start Date
                                            </Label>
                                            <Flatpickr
                                              className="form-control"
                                              value={item.start_date} // Pass the date string directly
                                              options={{
                                                dateFormat: "Y-m-d", // Specify the format of the input date string
                                                altInput: true,
                                                altFormat: "d-m-Y", // Set the desired format for display
                                              }}
                                              onChange={(selectedDates) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue = moment(
                                                  selectedDates[0]
                                                ).format("YYYY-MM-DD");
                                                updateddata[
                                                  taskIndex
                                                ].start_date = enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            />
                                          </Col>
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editEndDate"
                                              className="form-label"
                                            >
                                              End Date
                                            </Label>
                                            <Flatpickr
                                              className="form-control"
                                              value={item.end_date} // Pass the date string directly
                                              options={{
                                                dateFormat: "Y-m-d", // Specify the format of the input date string
                                                altInput: true,
                                                altFormat: "d-m-Y", // Set the desired format for display
                                              }}
                                              onChange={(selectedDates) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue = moment(
                                                  selectedDates[0]
                                                ).format("YYYY-MM-DD");
                                                updateddata[
                                                  taskIndex
                                                ].end_date = enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row
                                          className="d-flex justify-space-between"
                                          style={{ alignItems: "end" }}
                                        >
                                          <Col xs={12} className="">
                                            <Label
                                              htmlFor="editAchievement"
                                              className="form-label"
                                            >
                                              Achievements
                                            </Label>
                                            <textarea
                                              type="text"
                                              className="form-control"
                                              id="editAchievement"
                                              rows={2}
                                              placeholder="Enter your achievements"
                                              value={item.achievements}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userEducation,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[
                                                  taskIndex
                                                ].achievements = enteredValue;
                                                setUserEducation(updateddata);
                                              }}
                                            ></textarea>
                                          </Col>
                                          <Col xs={12} className="mt-3">
                                            <div className="hstack gap-2 justify-content-end">
                                              <button
                                                type="button"
                                                className="btn btn-soft-danger"
                                                onClick={() =>
                                                  t_Education(item.id, true)
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-soft-success"
                                                onClick={() =>
                                                  Edit_Education(item)
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {userEducation.length != index + 1 && <hr />}
                            </>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={showEducation} id="collapseeducation">
                    <Card className="mb-2">
                      <CardHeader className="d-flex justify-content-between">
                        <h4>Add Education</h4>
                      </CardHeader>
                      <CardBody>
                        <Form
                          className="needs-validation"
                          onSubmit={(e) => {
                            e.preventDefault();
                            Add_Education(dataEdu);
                            return false();
                          }}
                        >
                          <div className="justify-content-between align-items-center mb-2">
                            <div className="education-details text-muted">
                              <Row>
                                <Col xs={4} className="mb-3">
                                  <Label
                                    htmlFor="editInstitution"
                                    className="form-label"
                                  >
                                    Institution Name
                                  </Label>
                                  <Input
                                    type="text"
                                    value={dataEdu.institution_name}
                                    className={"form-control"}
                                    id="editInstitution"
                                    placeholder="Enter your Institution name"
                                    onChange={(e) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        institution_name: e.target.value,
                                      }));
                                      // setDataEdu(updateddata);
                                    }}
                                  />
                                </Col>
                                <Col xs={4} className="mb-3">
                                  <Label
                                    htmlFor="editDegree"
                                    className="form-label"
                                  >
                                    Degree
                                  </Label>
                                  <Input
                                    type="text"
                                    value={dataEdu.degree_name}
                                    className="form-control"
                                    id="editDegree"
                                    required
                                    placeholder="Enter your Degree"
                                    onChange={(e) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        degree_name: e.target.value,
                                      }));
                                    }}
                                  />
                                </Col>
                                <Col xs={4} className="mb-3">
                                  <Label
                                    htmlFor="editField"
                                    className="form-label"
                                  >
                                    Field of study
                                  </Label>
                                  <Input
                                    type="text"
                                    value={dataEdu.field_of_study}
                                    className="form-control"
                                    id="editField"
                                    placeholder="Enter your Field of study"
                                    onChange={(e) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        field_of_study: e.target.value,
                                      }));
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editStartDate"
                                    className="form-label"
                                  >
                                    Start Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={dataEdu.start_date}
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altInput: true,
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        start_date: moment(
                                          selectedDates[0]
                                        ).format("YYYY-MM-DD"),
                                      }));
                                    }}
                                  />
                                </Col>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editEndDate"
                                    className="form-label"
                                  >
                                    End Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={dataEdu.end_date}
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altInput: true,
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        end_date: moment(
                                          selectedDates[0]
                                        ).format("YYYY-MM-DD"),
                                      }));
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                className="d-flex justify-space-between"
                                style={{ alignItems: "end" }}
                              >
                                <Col xs={12} className="">
                                  <Label
                                    htmlFor="editAchievement"
                                    className="form-label"
                                  >
                                    Achievements
                                  </Label>
                                  <textarea
                                    type="text"
                                    value={dataEdu.achievements}
                                    className="form-control"
                                    id="editAchievement"
                                    rows={2}
                                    placeholder="Enter your achievements"
                                    onChange={(e) => {
                                      setDataEdu((prevDataObj) => ({
                                        ...prevDataObj,
                                        achievements: e.target.value,
                                      }));
                                    }}
                                  ></textarea>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="hstack mt-3 gap-2 justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-soft-danger"
                                      onClick={() => t_AddEducation()}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-soft-success"
                                      onClick={() => Add_Education(dataEdu)}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse
                    isOpen={!showCertification}
                    id="collapsecertification"
                  >
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center pt-2 pb-2 justify-content-between">
                        <div className="hstack">
                          <i className="text-d-primary me-1 fs-18 ri-medal-fill"></i>
                          <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                            Certifications
                          </h5>
                        </div>
                        <div className="hstack gap-3">
                          <i
                            className=" ri-add-line text-primary fs-25"
                            style={{ cursor: "pointer" }}
                            onClick={() => t_AddCertification()}
                          />
                          <i
                            className="ri-edit-2-line text-success me-1"
                            style={{
                              cursor: "pointer",
                              fontSize: 20,
                            }}
                            onClick={handleCertificateEditClick}
                          />
                        </div>
                      </CardHeader>
                      <CardBody>
                        {userCertificates?.map((item, index) => {
                          return (
                            <>
                              {!item.isEditing && (
                                <div
                                  key={index}
                                  className="certificate-container mb-3"
                                >
                                  <div className="hstack justify-content-between">
                                    <h6>{item.certification_name}</h6>
                                    <div className="hstack gap-2">
                                      {isCertificateEditClicked && (
                                        <>
                                          <i
                                            className="ri-edit-2-line text-success me-1"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() =>
                                              t_Certification(item.id)
                                            }
                                          />
                                          <i
                                            className="bx bx-trash text-danger"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: 18,
                                            }}
                                            onClick={() =>
                                              deleteCertification(item.id)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-muted mt-1">
                                    {item.issuing_authority}
                                  </div>
                                  <div className="d-flex  justify-content-between">
                                    <div className="">
                                      <span className="text-success">
                                        Issued on:{" "}
                                      </span>
                                      <span className="text-gray">
                                        {item.date_issued
                                          ? moment(item.date_issued).format(
                                              "MM-DD-YYYY"
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                    <div className="hstack">
                                      <span className="text-danger me-1">
                                        Expiry:{" "}
                                      </span>{" "}
                                      <span
                                        className="text-gray text-center"
                                        style={{ width: "5rem" }}
                                      >
                                        {item.expiration_date
                                          ? moment(item.expiration_date).format(
                                              "MM-DD-YYYY"
                                            )
                                          : "--"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {item.isEditing && (
                                <div className="mb-2 pb-2" key={index}>
                                  <div className="" key={index}>
                                    <h4 className="mb-2">
                                      Certificate {index + 1}
                                    </h4>
                                    <div className="justify-content-between align-items-center mb-2">
                                      <div className="education-details text-muted">
                                        <Row className="">
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editCertification"
                                              className="form-label"
                                            >
                                              Certification Name
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="editCertification"
                                              placeholder="Enter your Certification name"
                                              value={item.certification_name}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userCertificates,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[
                                                  taskIndex
                                                ].certification_name =
                                                  enteredValue;
                                                setUserCertificates(
                                                  updateddata
                                                );
                                              }}
                                            />
                                          </Col>
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editIssuing"
                                              className="form-label"
                                            >
                                              Issuing Authority
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="editIssuing"
                                              placeholder="Enter issuing authority"
                                              value={item.issuing_authority}
                                              onChange={(e) => {
                                                const updateddata = [
                                                  ...userCertificates,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  e.target.value;
                                                updateddata[
                                                  taskIndex
                                                ].issuing_authority =
                                                  enteredValue;
                                                setUserCertificates(
                                                  updateddata
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editissuedate"
                                              className="form-label"
                                            >
                                              Date Issued
                                            </Label>
                                            <Flatpickr
                                              className="form-control"
                                              value={item.date_issued} // Pass the date string directly
                                              options={{
                                                dateFormat: "Y-m-d", // Specify the format of the input date string
                                                altInput: true,
                                                altFormat: "d-m-Y", // Set the desired format for display
                                              }}
                                              onChange={(selectedDates) => {
                                                const updateddata = [
                                                  ...userCertificates,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  selectedDates[0]
                                                    ? moment(
                                                        selectedDates[0]
                                                      ).format("YYYY-MM-DD")
                                                    : null;
                                                updateddata[
                                                  taskIndex
                                                ].date_issued = enteredValue;
                                                setUserCertificates(
                                                  updateddata
                                                );
                                              }}
                                            />
                                          </Col>
                                          <Col xs={6} className="mb-3">
                                            <Label
                                              htmlFor="editexpire"
                                              className="form-label"
                                            >
                                              End Date
                                            </Label>
                                            <Flatpickr
                                              className="form-control"
                                              value={item.expiration_date} // Pass the date string directly
                                              options={{
                                                dateFormat: "Y-m-d", // Specify the format of the input date string
                                                altInput: true,
                                                altFormat: "d-m-Y", // Set the desired format for display
                                              }}
                                              onChange={(selectedDates) => {
                                                const updateddata = [
                                                  ...userCertificates,
                                                ];
                                                const taskIndex =
                                                  updateddata.findIndex(
                                                    (task) =>
                                                      task.id === item.id
                                                  );
                                                const enteredValue =
                                                  selectedDates[0]
                                                    ? moment(
                                                        selectedDates[0]
                                                      ).format("YYYY-MM-DD")
                                                    : null;
                                                updateddata[
                                                  taskIndex
                                                ].expiration_date =
                                                  enteredValue;
                                                setUserCertificates(
                                                  updateddata
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={12} className="">
                                            <div className="hstack gap-2 justify-content-end">
                                              <button
                                                type="button"
                                                className="btn btn-soft-danger"
                                                onClick={() =>
                                                  t_Certification(item.id, true)
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-soft-success"
                                                onClick={() =>
                                                  Edit_Certification(item)
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {userCertificates.length != index + 1 && <hr />}
                            </>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </Collapse>
                  <Collapse
                    isOpen={showCertification}
                    id="collapsecertification"
                  >
                    <Card className="mb-2">
                      <CardHeader className="d-flex justify-content-between">
                        <h4>Add Certification</h4>
                      </CardHeader>
                      <CardBody>
                        <Form
                          className="mb-2 pb-2 needs-validation"
                          onSubmit={(e) => {
                            e.preventDefault();
                            Add_Certification(dataEdu);
                            return false();
                          }}
                        >
                          <div className="justify-content-between align-items-center mb-2">
                            <div className="education-details text-muted">
                              <Row className="">
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editCertification"
                                    className="form-label"
                                  >
                                    Certification Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="editCertification"
                                    placeholder="Enter your Certification name"
                                    value={dataCert.certification_name}
                                    onChange={(e) => {
                                      setDataCert((prevDataObj) => ({
                                        ...prevDataObj,
                                        certification_name: e.target.value,
                                      }));
                                    }}
                                  />
                                </Col>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editIssuing"
                                    className="form-label"
                                  >
                                    Issuing Authority
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="editIssuing"
                                    placeholder="Enter issuing authority"
                                    value={dataCert.issuing_authority}
                                    onChange={(e) => {
                                      setDataCert((prevDataObj) => ({
                                        ...prevDataObj,
                                        issuing_authority: e.target.value,
                                      }));
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editissuedate"
                                    className="form-label"
                                  >
                                    Date Issued
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={dataCert.date_issued} // Pass the date string directly
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altInput: true,
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) => {
                                      setDataCert((prevDataObj) => ({
                                        ...prevDataObj,
                                        date_issued: moment(
                                          selectedDates[0]
                                        ).format("YYYY-MM-DD"),
                                      }));
                                    }}
                                  />
                                </Col>
                                <Col xs={6} className="mb-3">
                                  <Label
                                    htmlFor="editexpire"
                                    className="form-label"
                                  >
                                    End Date
                                  </Label>
                                  <Flatpickr
                                    className="form-control"
                                    value={dataCert.expiration_date} // Pass the date string directly
                                    options={{
                                      dateFormat: "Y-m-d", // Specify the format of the input date string
                                      altInput: true,
                                      altFormat: "d-m-Y", // Set the desired format for display
                                    }}
                                    onChange={(selectedDates) => {
                                      setDataCert((prevDataObj) => ({
                                        ...prevDataObj,
                                        expiration_date: moment(
                                          selectedDates[0]
                                        ).format("YYYY-MM-DD"),
                                      }));
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} className="">
                                  <div className="hstack gap-2 justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-soft-danger"
                                      onClick={() => t_AddCertification()}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-soft-success"
                                      onClick={() =>
                                        Add_Certification(dataCert)
                                      }
                                    >
                                      Add
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <Collapse isOpen={!showportfolio} id="collapseportfolio">
                    <Card className="mb-2">
                      <CardHeader className="d-flex align-items-center pt-2 pb-2 justify-content-between">
                        <div className="hstack">
                          <i className="text-d-primary me-1 fs-18 bx bxs-collection"></i>
                          <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                            Portfolio
                          </h5>
                        </div>
                        <div className="hstack gap-3">
                          <i
                            className=" ri-add-line text-primary fs-25"
                            onClick={() => {
                              t_addportfolio();
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <i
                            className="ri-edit-2-line text-success fs-20 "
                            onClick={handlePortfolioEditClick}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <>
                          <Swiper
                            className="project-swiper"
                            slidesPerView={3}
                            spaceBetween={20}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}
                          >
                            <div className="swiper-wrapper">
                              {userPortfolio?.map((item, index) => (
                                <React.Fragment key={index}>
                                  {isPortfolioEdit === null && (
                                    <SwiperSlide key={index}>
                                      <Card
                                        className="profile-project-card shadow-none profile-project-success mb-0 card-animate"
                                        style={{
                                          height: "170px",
                                          overflow: "hidden scroll",
                                        }}
                                      >
                                        <CardBody className="p-2">
                                          <div className="hstack justify-content-between">
                                            <h5 className="fs-14 mb-1">
                                              {item.name}
                                            </h5>
                                            <div className="hstack gap-2">
                                              {isPortfolioEditClicked && (
                                                <>
                                                  <i
                                                    className="ri-edit-line text-success fs-18 me-1"
                                                    onClick={(e) => {
                                                      t_Portfolio(
                                                        item.id,
                                                        false
                                                      );
                                                    }}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <i
                                                    className="ri-delete-bin-line text-danger fs-18"
                                                    onClick={() => {
                                                      deletePortfolio(item.id);
                                                    }}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>

                                          <div className="avatar-md mt-2">
                                            {item.image_file_name ? (
                                              <img
                                                src={`${s3portfolioUrl}${item.image_file_name}`}
                                                alt="user-img"
                                                className="rounded  image-fluid"
                                              />
                                            ) : (
                                              <img
                                                src={portdummy}
                                                alt="port-img"
                                                className="rounded  image-fluid"
                                              />
                                            )}
                                          </div>
                                          <h6 className="text-muted fw-normal mt-2">
                                            {item.description}
                                          </h6>
                                        </CardBody>
                                      </Card>
                                    </SwiperSlide>
                                  )}
                                  {item.id == isPortfolioEdit && (
                                    <>
                                      <div className="justify-content-between align-items-center mb-2 col-12">
                                        <div className="education-details text-muted">
                                          <Row className="">
                                            <Col xs={6} className="mb-3">
                                              <Label
                                                htmlFor="editName"
                                                className="form-label"
                                              >
                                                Title
                                              </Label>{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                              <Input
                                                type="text"
                                                className="form-control"
                                                id="editName"
                                                placeholder="Enter portfolio title"
                                                value={item.name}
                                                onChange={(e) => {
                                                  const updateddata = [
                                                    ...userPortfolio,
                                                  ];
                                                  const taskIndex =
                                                    updateddata.findIndex(
                                                      (task) =>
                                                        task.id === item.id
                                                    );
                                                  const enteredValue =
                                                    e.target.value;
                                                  updateddata[taskIndex].name =
                                                    enteredValue;
                                                  setUserPortfolio(updateddata);
                                                }}
                                              />
                                            </Col>
                                            <Col xs={6}>
                                              <div className="mb-3">
                                                <Label
                                                  htmlFor="choices-single-default"
                                                  className="form-label"
                                                >
                                                  Upload Image
                                                </Label>
                                                <Row className="mb-0"></Row>
                                                {item.imageurl ||
                                                item.image_file_name ? (
                                                  <button
                                                    className="btn btn-primary change-button mt-2 me-3"
                                                    onClick={(e) =>
                                                      handlePortfolioEditButtonClick(
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Change
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="btn btn-info mt-2 me-3"
                                                    onClick={(e) =>
                                                      handlePortfolioEditButtonClick(
                                                        e
                                                      )
                                                    }
                                                  >
                                                    Select
                                                  </button>
                                                )}
                                                {(item.image_file_name ||
                                                  item.imageurl) && (
                                                  <button
                                                    className="btn btn-danger mt-2"
                                                    onClick={() =>
                                                      handlePortImageremove(
                                                        item.id
                                                      )
                                                    }
                                                  >
                                                    Remove
                                                  </button>
                                                )}

                                                <input
                                                  className={`${
                                                    logoError
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                  type="file"
                                                  style={{ display: "none" }}
                                                  ref={fileEditInputRef}
                                                  accept=".jpeg, .jpg, .png"
                                                  onChange={(event) => {
                                                    onSelectFilePortfolio(
                                                      event,
                                                      item.id
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs={6} className="mb-3">
                                              <Label
                                                htmlFor="editdesc"
                                                className="form-label"
                                              >
                                                Descriptions
                                              </Label>{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                              <textarea
                                                type="text"
                                                value={item.description}
                                                className="form-control"
                                                id="editdesc"
                                                rows={2}
                                                placeholder="Enter description"
                                                onChange={(e) => {
                                                  const updateddata = [
                                                    ...userPortfolio,
                                                  ];
                                                  const taskIndex =
                                                    updateddata.findIndex(
                                                      (task) =>
                                                        task.id === item.id
                                                    );
                                                  const enteredValue =
                                                    e.target.value;
                                                  updateddata[
                                                    taskIndex
                                                  ].description = enteredValue;
                                                  setUserPortfolio(updateddata);
                                                }}
                                              ></textarea>
                                            </Col>
                                            <Col md={6}>
                                              <div className="img-container">
                                                <>
                                                  {errorPortEdit && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        fontSize: "90%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {errorPortEdit}
                                                    </p>
                                                  )}
                                                  {/* showing selectes image */}
                                                  {console.log(
                                                    item.imageurl,
                                                    "item.imageurl1223"
                                                  )}

                                                  {item.imageurl && (
                                                    <div
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <ReactCrop
                                                        crop={cropPortfolio}
                                                        onChange={(newCrop) =>
                                                          setCropPortfolio(
                                                            newCrop
                                                          )
                                                        }
                                                        onComplete={(newCrop) =>
                                                          updateCanvasPreviewPortfolio(
                                                            newCrop,
                                                            item.id
                                                          )
                                                        }
                                                        keepSelection
                                                        aspect={ASPECT_RATIO_P}
                                                        minWidth={
                                                          MIN_DIMENSION_P
                                                        }
                                                      >
                                                        {console.log(
                                                          item.imageurl,
                                                          "item.imageurl123"
                                                        )}
                                                        <img
                                                          ref={imgRefPortEdit}
                                                          src={item.imageurl}
                                                          alt="Upload"
                                                          style={{
                                                            maxHeight: "60%",
                                                          }}
                                                          onLoad={(e) =>
                                                            onImageLoadPortfolio(
                                                              e,
                                                              item.id
                                                            )
                                                          }
                                                        />
                                                      </ReactCrop>
                                                    </div>
                                                  )}
                                                  {cropPortfolio && (
                                                    <canvas
                                                      ref={
                                                        previewCanvasRefPortEdit
                                                      }
                                                      className="mt-4"
                                                      style={{
                                                        display: "none",
                                                        border:
                                                          "1px solid black",
                                                        objectFit: "contain",
                                                        width: 150,
                                                        height: 150,
                                                      }}
                                                    />
                                                  )}

                                                  {/* showing stored image */}
                                                  {item.image_file_name &&
                                                    item.imageurl == null && (
                                                      <img
                                                        className="img-thumbnail avatar-lg"
                                                        alt=""
                                                        src={`${s3portfolioUrl}${item.image_file_name}`}
                                                      />
                                                    )}
                                                </>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs={12} className="">
                                              <div className="hstack gap-2 justify-content-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-success"
                                                  onClick={() =>
                                                    Edit_Portfolio(item)
                                                  }
                                                >
                                                  Update
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-danger"
                                                  onClick={(e) =>
                                                    t_Portfolio(item.id, true)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </Swiper>
                        </>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse isOpen={showportfolio} id="collapseportfolio">
                    <Card className="mb-2">
                      <CardHeader className="d-flex justify-content-between">
                        <h3>Add Portfolio</h3>
                      </CardHeader>
                      <CardBody>
                        <div className="justify-content-between align-items-center mb-2">
                          <div className="education-details text-muted">
                            <Row className="">
                              <Col xs={6} className="mb-3">
                                <Label
                                  htmlFor="editName"
                                  className="form-label"
                                >
                                  Name
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="editName"
                                  placeholder="Enter your name"
                                  value={dataPort.name}
                                  onChange={(e) => {
                                    setDataPort((prevDataObj) => ({
                                      ...prevDataObj,
                                      name: e.target.value,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col xs={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="choices-single-default"
                                    className="form-label"
                                  >
                                    Upload Image
                                  </Label>{" "}
                                  <span className="text-danger">*</span>
                                  <Row className="mb-0"></Row>
                                  {isPortImageSelected ? (
                                    <div className="mt-2">
                                      <button
                                        className="btn btn-primary change-button"
                                        onClick={(e) =>
                                          handlePortfolioButtonClick(e)
                                        }
                                      >
                                        Change
                                      </button>
                                      <button
                                        className="btn btn-danger me-3"
                                        onClick={() => handlePortImageremove()}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className="btn btn-info mt-2"
                                      onClick={(e) =>
                                        handlePortfolioButtonClick(e)
                                      }
                                    >
                                      Select
                                    </button>
                                  )}
                                  <input
                                    className={`${
                                      logoError ? "is-invalid" : ""
                                    }`}
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    accept=".jpeg, .jpg, .png"
                                    onChange={(event) => {
                                      onSelectFilePortfolio(event);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} className="mb-3">
                                <Label
                                  htmlFor="editdesc"
                                  className="form-label"
                                >
                                  Descriptions
                                </Label>{" "}
                                <span className="text-danger">*</span>
                                <textarea
                                  type="text"
                                  value={dataPort.description}
                                  className="form-control"
                                  id="editdesc"
                                  rows={2}
                                  placeholder="Enter description"
                                  onChange={(e) => {
                                    setDataPort((prevDataObj) => ({
                                      ...prevDataObj,
                                      description: e.target.value,
                                    }));
                                  }}
                                ></textarea>
                              </Col>
                              <Col md={6}>
                                <div className="img-container">
                                  {errorPortfolio && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "90%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {errorPortfolio}
                                    </p>
                                  )}

                                  {imgSrcPortfolio && (
                                    <div style={{ textAlign: "center" }}>
                                      <ReactCrop
                                        crop={cropPortfolio}
                                        onChange={(newCrop) =>
                                          setCropPortfolio(newCrop)
                                        }
                                        onComplete={(newCrop) =>
                                          updateCanvasPreviewPortfolio(newCrop)
                                        }
                                        keepSelection
                                        aspect={ASPECT_RATIO_P}
                                        minWidth={MIN_DIMENSION_P}
                                      >
                                        <img
                                          // className="avatar-lg"
                                          ref={imgRefPortfolio}
                                          src={imgSrcPortfolio}
                                          alt="Upload"
                                          style={{ maxHeight: "60%" }}
                                          onLoad={(e) =>
                                            onImageLoadPortfolio(e)
                                          }
                                        />
                                      </ReactCrop>
                                    </div>
                                  )}
                                  {cropPortfolio && (
                                    <canvas
                                      ref={previewCanvasRefPortfolio}
                                      className="mt-4"
                                      style={{
                                        display: "none",
                                        border: "1px solid black",
                                        objectFit: "contain",
                                        width: 150,
                                        height: 150,
                                      }}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} className="">
                                <div className="hstack gap-2 justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-soft-danger"
                                    onClick={() => t_addportfolio()}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-soft-success"
                                    onClick={() => Add_Portfolio(dataPort)}
                                  >
                                    Add
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Card className="mb-2">
                    <CardHeader className="d-flex align-items-center pt-2 pb-2 justify-content-between">
                      <div className="hstack">
                        <i className="text-d-primary me-1 fs-18 bx bxs-star-half"></i>
                        <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                          Employee Reviews
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {review && review.length > 0 ? (
                        <div
                          style={{
                            maxHeight: "250px", // Adjust this height to fit two reviews
                            overflowY: "auto",
                          }}
                          className="scrollable-div"
                        >
                          {review?.map((reviewItem, index) => (
                            <Card
                              key={index}
                              className="border border-dashed mb-3"
                              style={{ boxShadow: "0px 2px 5px #bdafdc" }}
                            >
                              <CardBody>
                                <Row>
                                  <Col
                                    md={1}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <div className="avatar-title bg-white">
                                      {reviewItem.business_profile ? (
                                        <img
                                          src={`${reviewItem.business_profile}`}
                                          alt="business-img"
                                          className="avatar-sm rounded"
                                        />
                                      ) : (
                                        <img
                                          src={industryDummy}
                                          alt="business-img"
                                          className="avatar-sm rounded"
                                        />
                                      )}
                                    </div>
                                  </Col>
                                  <Col md={11}>
                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                      <label className="mb-0 fs-15">
                                        <b>{reviewItem.business_owner_name}</b>
                                      </label>
                                      <div className="d-flex align-items-center">
                                        <p className="mb-0 me-2 fs-10 fst-italic">
                                          {getRelativeTime(
                                            reviewItem.review_date
                                          )}
                                        </p>
                                        <div className="star-size">
                                          <Rating
                                            readonly
                                            initialRating={reviewItem.ratings}
                                            emptySymbol="mdi mdi-star-outline text-muted custom-star"
                                            fullSymbol="mdi mdi-star text-warning custom-star"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="fs-11 text-d-primary">
                                      <i>
                                        <b>{reviewItem.business_name}</b>
                                      </i>
                                    </label>
                                    <ReadMore text={reviewItem.reviews} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          ))}
                        </div>
                      ) : (
                        <div className="text-left mt-0">
                          <span
                            className="fs-12"
                            style={{ fontStyle: "italic" }}
                          >
                            No Data Found
                          </span>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col xl={12}>
                  <div className="profile-foreground position-relative mx-n4 mt-n4">
                    <div className="profile-wid-bg">
                      <img src={profileBg} alt="" className="profile-wid-img" />
                    </div>
                  </div>

                  <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                    <Row className="g-4">
                      <div className="col-auto ">
                        <div className="avatar-lg">
                          <img
                            src={userDummy}
                            alt="user-img"
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                      </div>

                      <Col className="py-2">
                        <div>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder col-2 bg-light"></span>
                          </h3>
                          <div className="text-gray d-flex mt-3 align-items-center placeholder-glow">
                            <span className="placeholder col-4 bg-light"></span>
                          </div>
                          <div className="text-gray d-flex mt-3 align-items-center placeholder-glow">
                            <span className="placeholder col-3 bg-light"></span>
                          </div>
                        </div>
                        <div className="hstack gap-2 mt-2 placeholder-glow">
                          <span className="mt-2 placeholder col-1 bg-light"></span>
                          <span className="mt-2 placeholder col-1 bg-light"></span>
                        </div>
                      </Col>

                      <Col
                        xs={12}
                        className="col-lg-auto order-last order-lg-0 "
                      >
                        <Row className=" text-center d-flex flex-column justify-content-between h-100">
                          <Col lg={6} xs={4} className="align-self-end"></Col>
                          <Col lg={6} xs={4}>
                            {/* Star rating icon (assuming rating is out of 5) */}
                            <div className="d-flex placeholder-glow justify-content-start">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <i
                                  className="ri-star-s-fill "
                                  key={star}
                                  style={{
                                    fontSize: "20px",
                                    color:
                                      star <= userRating
                                        ? "yellow !important"
                                        : "gray",
                                    marginRight: "2px",
                                    animation:
                                      "placeholder-glow 2s ease-in-out infinite",
                                  }}
                                ></i>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                {/* Left side - column layout */}
                <Col lg={4} style={{ paddingRight: 0 }}>
                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d-primary fs-18 me-1 bx bxs-wrench"></i>
                      <h5 className="mb-0 text-d-primary">Preferences</h5>
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-column ">
                        <h6>Industries:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-7"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Preferred Work Type:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-9"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Hourly rate:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Monthly Salary:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Available Start Date:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-6"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Current Availability:</h6>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-6"></span>
                        </h5>
                      </div>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="ri-pin-distance-fill fs-18 text-d-primary me-1"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Preferred Work Locations
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className="placeholder-glow d-flex justify-content-between">
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-3"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-7"></span>
                      </h5>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="text-d-primary fs-16 me-1 bx bxs-file"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Verifications
                      </h5>
                    </CardHeader>

                    <CardBody>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-4"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-8"></span>
                      </h5>
                    </CardBody>
                  </Card>

                  {/* <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="text-d-primary fs-16 me-1 bx bxs-file"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Reset Password
                      </h5>
                    </CardHeader>

                    <CardBody>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Password</h6>
                        <h5 className=" placeholder-glow mt-1">
                          <span className="placeholder col-8"></span>
                        </h5>
                      </div>
                      <div className="d-flex flex-column mt-2 ">
                        <h6>Confirm Password</h6>
                        <h5 className=" placeholder-glow mt-1">
                          <span className="placeholder col-8"></span>
                        </h5>
                      </div>
                    </CardBody>
                  </Card> */}
                </Col>

                {/* Right side - One column taking full width */}
                <Col lg={8}>
                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d-primary fs-18 me-1 bx bxs-user"></i>
                      <h5 className="mb-0 text-d-primary">Profile</h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className="placeholder-glow ">
                        <span className="placeholder col-2"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-5"></span>
                      </h5>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d-primary fs-18 me-1 ri-calendar-check-fill"></i>
                      <h5 className=" text-d-primary">
                        Preferred Availability
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                    </CardBody>
                  </Card>
                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d-primary fs-18 me-1 ri-calendar-check-fill"></i>
                      <h5 className=" text-d-primary">
                        Preferred Availability
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-3"></span>{" "}
                      </h4>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d-primary fs-18 me-1 ri-briefcase-4-fill"></i>
                      <h5 className=" text-d-primary">Employment History</h5>
                    </CardHeader>
                    <CardBody>
                      <h3 className=" placeholder-glow ">
                        <span className="placeholder col-3"></span>
                      </h3>
                      <h4 className=" placeholder-glow mt-2">
                        <span className="placeholder col-5"></span>
                      </h4>
                      <h4 className=" placeholder-glow mt-1">
                        <span className="placeholder col-9"></span>
                      </h4>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="bx bxs-graduation fs-18 text-d-primary me-1"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Education
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className="placeholder-glow d-flex justify-content-between">
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-3"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-7"></span>
                      </h5>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="text-d-primary me-1 fs-18 ri-medal-fill"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Certifications
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className=" placeholder-glow ">
                        <span className="placeholder col-6"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-5"></span>
                      </h5>
                      <h5 className=" placeholder-glow mt-1">
                        <span className="placeholder col-9"></span>
                      </h5>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className=" text-d-primary fs-18 me-1  bx bxs-collection"></i>
                      <h5 className=" text-d-primary">Portfolio</h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className=" placeholder-glow ">
                        <span className="placeholder col-6"></span>
                      </h4>
                      <h4 className=" placeholder-glow mt-2">
                        <span className="placeholder col-5"></span>
                      </h4>
                    </CardBody>
                  </Card>

                  <Card className="mb-2">
                    <CardHeader className="d-flex justify-content-end align-items-center">
                      <i className="text-d-primary me-1 fs-18 ri-medal-fill"></i>
                      <h5 className=" text-d-primary card-title flex-grow-1 mb-0 ">
                        Employee Reviews
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h4 className=" placeholder-glow ">
                        <span className="placeholder col-6"></span>
                      </h4>
                      <h5 className=" placeholder-glow mt-2">
                        <span className="placeholder col-5"></span>
                      </h5>
                      <h5 className=" placeholder-glow mt-1">
                        <span className="placeholder col-9"></span>
                      </h5>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <ToastContainer />
          {/* Vertically Centered */}
          <Modal
            isOpen={profileModal}
            toggle={() => {
              profile_modal();
            }}
            centered
          >
            <ModalHeader>Profile Photo</ModalHeader>
            <div className="modal-body text-center p-5">
              <div>
                {userProfileImg ? (
                  <>
                    <img
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="Company Logo 1"
                      src={croppedImgSrc ? croppedImgSrc : profleImageUrl}
                    />
                    {/* <img
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="Company Logo 1"
                      src={avatarUrl.current}
                    /> */}
                    {/* {croppedImgSrc && (
        <img
          src={croppedImgSrc}
          alt="Company Logo 1"
          className="rounded-circle avatar-xl img-thumbnail user-profile-image"
        />
      )} */}
                  </>
                ) : (
                  <>
                    {/* {console.log(croppedImgSrc, "croppedImgSrc123")} */}

                    {croppedImgSrc ? (
                      <img
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="Company Logo 2"
                        src={croppedImgSrc}
                      />
                    ) : (
                      <img
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="Company Logo 3"
                        src={userDummy}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <ModalFooter className="pb-1 border-top dotted">
              <div className="w-100 hstack justify-content-between">
                <div>
                  <label
                    htmlFor="profile-img-input"
                    className="vstack cursor-pointer add-photo"
                  >
                    <i className="mdi mdi-camera-plus-outline fs-18 m-auto"></i>
                    Add photo
                  </label>
                  <Input
                    id="profile-img-input"
                    type="file"
                    onChange={onSelectFile}
                    accept=".jpeg, .jpg, .png"
                    className="profile-img-file-input"
                    style={{ display: "none" }}
                  />
                </div>
                <div>
                  <span
                    className="vstack cursor-pointer delete-photo"
                    onClick={handleRemoveImage}
                  >
                    <i className="mdi mdi-trash-can-outline fs-18 m-auto"></i>
                    Delete
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {error && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "90%",
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </p>
                )}
                {imgSrc && (
                  <div style={{ textAlign: "center" }}>
                    <ReactCrop
                      crop={crop}
                      onChange={(newCrop) => setCrop(newCrop)}
                      onComplete={(newCrop) => updateCanvasPreview(newCrop)}
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={imgRef}
                        src={imgSrc}
                        alt="Upload"
                        style={{ maxHeight: "60%" }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>
                )}
                {crop && (
                  <canvas
                    ref={previewCanvasRef}
                    className="mt-4"
                    style={{
                      display: "none",
                      border: "1px solid black",
                      objectFit: "contain",
                      width: 150,
                      height: 150,
                    }}
                  />
                )}
              </div>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyProfile;
