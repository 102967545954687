import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { get_cookie } from "../helpers/get_cookie";
import WorkForceSetup from "../pages/Authentication/WorkForceSetup";
//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";

const Index = () => {
  const [profileStatus, setProfileStatus] = useState(null);

  // Function to fetch and update profile status from cookie
  const updateProfileStatusFromCookie = () => {
    const authUser = JSON.parse(get_cookie("workauthUser"));
    const updatedProfileStatus = authUser?.profile_setup_completed;
    setProfileStatus(updatedProfileStatus);
  };
  console.log(profileStatus, " profile status");
  useEffect(() => {
    // Update profile status when component mounts
    updateProfileStatusFromCookie();

    // Set up interval to periodically update profile status
    const interval = setInterval(() => {
      updateProfileStatusFromCookie();
    }, 1000); // Adjust interval as needed

    // Clean up interval to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
              
                profileStatus == 0 ? (
                  <AuthProtected path="/workforce-setup">
                    {console.log("status 0")}
                    <VerticalLayout>
                      {route.path === "/workforce-setup" ? (
                        <WorkForceSetup />
                      ) : (
                        <Navigate to="/workforce-setup" replace />
                      )}
                    </VerticalLayout>
                  </AuthProtected>
                ) : (
                  <AuthProtected path={route.path}>
                       {console.log("status 1", profileStatus)}
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </AuthProtected>
                )
               
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
